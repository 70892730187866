import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
  TextField,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectConcedeStatus, selectDisputeError } from '../features/challenge-dispute/ChallengeDisputeSlice';
import { MutationStatusCode } from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalTitle: {
      padding: theme.spacing(2, 0),
    },
    dialogContextTitle: {
      color: 'black',
      marginBottom: 0,
    },
    infoSections: {
      margin: 0,
      padding: theme.spacing(1.5, 3),
    },
    buttonHouse: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2, 3, 3),
    },
    backButton: {
      marginRight: theme.spacing(2),
    },
    reasonValid: {
      display: 'none',
    },
    reasonInvalidError: {
      padding: '8px 24px',
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    interiorPadding: {
      padding: theme.spacing(1.5, 2),
    },
  }),
);

interface ConcedeDisputeProps {
  explanation: string;
  handleExplanationChange: (explanation: string) => void;
  back: () => void;
  concede: () => void;
  postConcede: () => void;
  concedeDisputeError?: Error;
}

const ConcedeDispute: React.FC<ConcedeDisputeProps> = props => {
  const classes = useStyles();
  const { explanation, handleExplanationChange, back, concede, postConcede, concedeDisputeError } = props;
  const [errorState, setErrorState] = useState({ error: false, class: classes.reasonValid, errorMessage: '' });
  const [concedeClicked, setConcedeClicked] = useState(false);
  const concedeStatus = useSelector(selectConcedeStatus);
  const disputeError = useSelector(selectDisputeError);

  useEffect(() => {
    if (concedeStatus !== undefined && concedeStatus !== MutationStatusCode.Error) {
      postConcede();
    }
  }, [concedeStatus]);

  useEffect(() => {
    if (disputeError !== undefined || concedeDisputeError !== undefined) {
      setErrorState({
        error: false,
        class: classes.reasonInvalidError,
        errorMessage: 'There was a problem processing your request. Please try again.',
      });
      setConcedeClicked(false);
    }
  }, [disputeError, concedeDisputeError]);

  const clearError = () => {
    setErrorState({ error: false, class: classes.reasonValid, errorMessage: '' });
  };

  const handleConcedeClick = () => {
    clearError();
    if (explanation.trim() !== '') {
      setConcedeClicked(true);
      concede();
      return;
    }
    setErrorState({ error: true, class: classes.reasonInvalidError, errorMessage: 'This field is required' });
  };

  return (
    <Box data-cy="concede-modal">
      <Box className={classes.infoSections}>
        <DialogTitle className={classes.modalTitle}>
          <Typography variant="h6" id="modal-title-concede">
            Concede this dispute?
          </Typography>
        </DialogTitle>
        <DialogContentText className={classes.dialogContextTitle} id="modal-description-concede">
          Payment will be credited back to the payer. Once a dispute has been conceded, it cannot be challenged.
        </DialogContentText>
      </Box>
      {errorState.errorMessage && (
        <DialogContentText className={errorState.class} data-cy="concede-error-message">
          {errorState.errorMessage}
        </DialogContentText>
      )}
      <DialogContent>
        <TextField
          disabled={concedeClicked}
          multiline
          required
          fullWidth
          rows={7}
          value={explanation}
          onChange={event => handleExplanationChange(event.target.value)}
          error={errorState.error}
          variant="outlined"
          inputProps={{ 'aria-label': 'concede explanation' }}
          placeholder="Explanation *"
          data-cy="concede-explanation-input"
          InputProps={{
            classes: {
              multiline: classes.interiorPadding,
            },
          }}
        />
      </DialogContent>
      <DialogActions className={classes.buttonHouse}>
        <Button onClick={back} className={classes.backButton} color="primary" data-cy="back-concede">
          Back
        </Button>
        <Button
          onClick={handleConcedeClick}
          disabled={concedeClicked}
          type="submit"
          variant="contained"
          color="primary"
          data-cy="submit-concede"
        >
          Concede
        </Button>
      </DialogActions>
    </Box>
  );
};
export default ConcedeDispute;
