import { Box, Chip, Container, Link, Typography, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { AccountRequirements, TenantAccount } from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hyperlinktext: {
      color: theme.palette.error.dark,
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    inline: {
      display: 'inline-flex',
      paddingBottom: 5,
      fontSize: '17px',
    },
    genericErrorInline: {
      display: 'inline-flex',
      paddingBottom: 5,
      fontSize: '17px',
      width: '100%',
      justifyContent: 'center',
    },
    warningChip: {
      marginRight: 10,
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.light,
      minWidth: '2rem',
      fontSize: '17px',
    },
    labelFix: {
      overflow: 'visible',
    },
    issueText: {
      lineHeight: '32px',
      fontWeight: 500,
    },
    warningBanner: {
      maxWidth: '100vw',
      zIndex: 1,
      position: 'relative',
      minHeight: theme.spacing(6),
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      boxShadow: '0px 3px 6px #00000012',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      padding: theme.spacing(1, 1, 0, 1),
      textAlign: 'center',
    },
  }),
);

interface AdditionalVerificationIssuesBannerProps {
  accountRequirements?: AccountRequirements;
  handleSettingsClick: () => void;
  handleUploadClick: () => void;
  tenantAccount?: TenantAccount;
  setHasAdditionalBanner: (hasBanner: boolean) => void;
  hasKycIssue: boolean;
  isAccountOwner: boolean;
  userEmail: string;
  handleReonboard: () => void;
  isPendingAccount: boolean;
  hasAdditionalBanner: boolean;
}

const AdditionalVerificationIssuesBanner: React.FC<AdditionalVerificationIssuesBannerProps> = (
  props: AdditionalVerificationIssuesBannerProps,
): JSX.Element | null => {
  const classes = useStyles();
  const {
    accountRequirements,
    tenantAccount,
    setHasAdditionalBanner,
    hasKycIssue,
    isAccountOwner,
    userEmail,
    handleReonboard,
    isPendingAccount,
    hasAdditionalBanner,
  } = props;
  const genericErrors = tenantAccount?.errors;

  const countIssues = () => {
    let issueCount = 0;
    let docsNeededCount = 0;

    if (accountRequirements) {
      if (accountRequirements.pastDue && accountRequirements.pastDue?.length > 0) {
        accountRequirements.pastDue.forEach(item => {
          if (!accountRequirements.pendingVerification?.includes(item)) {
            if (item.includes('payout')) {
              issueCount += 1;
            } else if (item.includes('verification')) {
              docsNeededCount += 1;
            }
          }
        });
      }
      if (accountRequirements.currentlyDue && accountRequirements.currentlyDue?.length > 0) {
        accountRequirements.currentlyDue.forEach(item => {
          if (!accountRequirements.pastDue?.includes(item) && !accountRequirements.pendingVerification?.includes(item)) {
            if (item.includes('payout')) {
              issueCount += 1;
            } else if (item.includes('verification')) {
              docsNeededCount += 1;
            }
          }
        });
      }
    }
    return {
      issueCount,
      docsNeededCount,
    };
  };
  const calculateDaysLeft = (deadline: string) => {
    const dueDate = new Date(deadline);
    const today = new Date();
    const difference = dueDate.valueOf() - today.valueOf();
    if (difference < 0) {
      return '0 days';
    }
    const days = Math.floor(difference / 86400000);
    return days === 1 ? '1 day' : `${days} days`;
  };

  const makeIssuesBanner = (): JSX.Element | undefined => {
    let numDocumentsNeeded = 0;
    let numPersonaIssues = 0;
    let daysLeft = '';
    let timeLeft = '. This information is overdue. You must update this information or your account may be disabled';

    const { issueCount, docsNeededCount } = countIssues();
    numDocumentsNeeded = docsNeededCount;
    numPersonaIssues = issueCount;

    if (
      !isPendingAccount &&
      (numDocumentsNeeded > 0 || numPersonaIssues > 0 || (genericErrors && genericErrors.length > 0) || hasKycIssue)
    ) {
      if (accountRequirements?.currentDeadline && accountRequirements.pastDue?.length === 0) {
        daysLeft = calculateDaysLeft(accountRequirements.currentDeadline);
        timeLeft = `. ${daysLeft} remaining to finish setting up your account`;
      }
      const ret = (
        <Container>
          {numPersonaIssues > 0 && (
            <Box className={classes.inline}>
              {numPersonaIssues > 1 && (
                <Chip classes={{ root: classes.warningChip, label: classes.labelFix }} label={numPersonaIssues} />
              )}
              <Typography className={classes.issueText}>
                {'Please enter missing account information in your '}
                <Link underline={'hover'} className={classes.hyperlinktext} href="#" onClick={props.handleSettingsClick}>
                  account settings
                </Link>
                {timeLeft}
              </Typography>
            </Box>
          )}
          {numDocumentsNeeded > 0 && !hasKycIssue && (
            <Box className={classes.inline}>
              {numDocumentsNeeded > 1 && (
                <Chip classes={{ root: classes.warningChip, label: classes.labelFix }} label={numDocumentsNeeded} />
              )}
              <Typography className={classes.issueText}>
                {'Missing Verifications.  We are unable to verify your account based on the information supplied, please '}
                <Link underline={'hover'} className={classes.hyperlinktext} href="#" onClick={props.handleUploadClick}>
                  upload additional documentation.
                </Link>
              </Typography>
            </Box>
          )}
          {genericErrors &&
            genericErrors.map(str => (
              <Box className={classes.genericErrorInline} key={genericErrors.indexOf(str)}>
                <Typography className={classes.issueText}>{str}</Typography>
              </Box>
            ))}
          {hasKycIssue && isAccountOwner && (
            <Box className={classes.inline}>
              <Typography className={classes.issueText}>
                {'Your account could not be verified with the information provided. '}
                <Link underline={'hover'} className={classes.hyperlinktext} onClick={handleReonboard}>
                  Click here to enter new verification information.
                </Link>
              </Typography>
            </Box>
          )}
          {hasKycIssue && !isAccountOwner && (
            <Box className={classes.inline}>
              <Typography className={classes.issueText}>
                {'The account could not be verified with the information provided. '}
                <Link
                  underline={'hover'}
                  className={classes.hyperlinktext}
                  target="blank"
                  href={`mailto:${userEmail}?subject=Please restart the Aptean Pay Merchant Verification Process`}
                >
                  {`Click here to ask ${userEmail} to restart verification process.`}
                </Link>
              </Typography>
            </Box>
          )}
        </Container>
      );
      return ret;
    }
    return undefined;
  };
  const bannerContents = makeIssuesBanner();

  if (bannerContents !== undefined) {
    if (!hasAdditionalBanner) setHasAdditionalBanner(true);
    return (
      <Box className={classes.warningBanner} id="additional-error-banner">
        {bannerContents}
      </Box>
    );
  }

  if (!isPendingAccount && hasAdditionalBanner) setHasAdditionalBanner(false);
  return null;
};

export default AdditionalVerificationIssuesBanner;
