import { Dispute, Payment, PaymentRequest } from '../gql-types.generated';
import { Box, Dialog, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import DisputeInformation from './DisputeInformation';
import LoadingMask from './LoadingMask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerBox: {
      paddingBottom: theme.spacing(4),
    },
  }),
);

interface DisputeDetailsProps {
  disputedRecord: Dispute;
  open: boolean;
  close: () => void;
  concede: () => void;
  isFetchingRecord: boolean;
  handlePaymentClicked: (paymentId?: string | null) => void;
  openPaymentModalFromDisputeDetails: () => void;
  paymentById?: Payment;
  paymentByIdError?: Error;
  handleRequestClicked: (paymentRequestId?: string | null) => void;
  openRequestModalFromDisputeDetails: () => void;
  paymentRequestById?: PaymentRequest;
  paymentRequestByIdError?: Error;
  clearLoading?: () => void;
  isCreditMemoEnabled: boolean;
  isUserAdmin: boolean;
}

const DisputeDetails: React.FC<DisputeDetailsProps> = props => {
  const classes = useStyles();
  const {
    disputedRecord,
    open,
    close,
    concede,
    isFetchingRecord,
    handlePaymentClicked,
    openPaymentModalFromDisputeDetails,
    paymentById,
    paymentByIdError,
    handleRequestClicked,
    openRequestModalFromDisputeDetails,
    paymentRequestById,
    paymentRequestByIdError,
    clearLoading,
    isCreditMemoEnabled,
    isUserAdmin,
  } = props;

  let disputeState = null;
  switch (disputedRecord.status) {
    case 'CONCEDED_AWAITING_PAYFAC': {
      disputeState = 'CONCEDED';
      break;
    }
    case 'CHALLENGE_AWAITING_PAYFAC': {
      disputeState = 'CHALLENGED';
      break;
    }
    case 'AWAITING_MERCHANT_RESPONSE': {
      disputeState = 'DISPUTED';
      break;
    }
    case 'PENDING_PAYFAC_REVIEW': {
      disputeState = 'CHALLENGED';
      break;
    }
    case 'AWAITING_CHARGEBACK_DECISION': {
      disputeState = 'CHALLENGED';
      break;
    }
    case 'RESOLVED': {
      if (disputedRecord.concedeMessage && disputedRecord.concedeMessage !== '') {
        disputeState = 'CONCEDED';
        break;
      }
      disputeState = 'CHALLENGED';
      break;
    }
    default: {
      disputeState = 'UNKNOWN';
    }
  }

  useEffect(() => {
    if (clearLoading) clearLoading();
  }, []);

  useEffect(() => {
    if (paymentById || paymentByIdError) {
      openPaymentModalFromDisputeDetails();
    }
  }, [paymentById, paymentByIdError]);

  useEffect(() => {
    if (paymentRequestById || paymentRequestByIdError) {
      openRequestModalFromDisputeDetails();
    }
  }, [paymentRequestById, paymentRequestByIdError]);

  return (
    <Dialog
      aria-label={'dispute details dialog'}
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          // Go straight through to onClose if the dialog is finding a way to close without it being backdrop click.
          // For most workflows this dialog is shown/hidden rather than closed.
          close();
        }
      }}
      disableEscapeKeyDown={isFetchingRecord}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className={classes.outerBox}>
        <LoadingMask loading={isFetchingRecord} />
        <DisputeInformation
          disputedRecord={disputedRecord}
          disputeState={disputeState}
          handlePaymentView={handlePaymentClicked}
          handleRequestView={handleRequestClicked}
          isDialog={true}
          closeDialog={close}
          concede={concede}
          isCreditMemoEnabled={isCreditMemoEnabled}
          isUserAdmin={isUserAdmin}
        />
      </Box>
    </Dialog>
  );
};

export default DisputeDetails;
