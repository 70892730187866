/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Dispute, MutationStatusCode } from '../../gql-types.generated';

interface SliceState {
  disputeError?: Error;
  concedeExplanation: string;
  challengeExplanation: string;
  supportingDocType: string;
  disputedRecord: Dispute | undefined;
  concedeStatus: MutationStatusCode | undefined;
  challengeStatus: MutationStatusCode | undefined;
  concedeInProgress: boolean;
  concedeDisputeError?: Error;
}

const initialState: SliceState = {
  disputeError: undefined,
  concedeExplanation: '',
  challengeExplanation: '',
  supportingDocType: '',
  disputedRecord: undefined,
  concedeStatus: undefined,
  challengeStatus: undefined,
  concedeInProgress: false,
};

export const slice = createSlice({
  name: 'challengeDispute',
  initialState,
  reducers: {
    fetchDisputeError: (state, action: PayloadAction<Error>) => {
      state.disputeError = action.payload;
    },
    clearDisputeError: state => {
      state.disputeError = undefined;
    },
    captureConcedeReasons: (state, action: PayloadAction<string>) => {
      state.concedeExplanation = action.payload;
    },
    captureChallengeReasons: (state, action: PayloadAction<string>) => {
      state.challengeExplanation = action.payload;
    },
    captureSupportingDocType: (state, action: PayloadAction<string>) => {
      state.supportingDocType = action.payload;
    },
    captureDisputedRecord: (state, action: PayloadAction<Dispute | undefined>) => {
      state.disputedRecord = action.payload;
    },
    captureConcedeStatus: (state, action: PayloadAction<MutationStatusCode>) => {
      state.concedeStatus = action.payload;
    },
    clearConcedeStatus: state => {
      state.concedeStatus = undefined;
    },
    captureChallengeStatus: (state, action: PayloadAction<MutationStatusCode>) => {
      state.challengeStatus = action.payload;
    },
    clearChallengeStatus: state => {
      state.challengeStatus = undefined;
    },
    captureConcedeInProgress: (state, action: PayloadAction<boolean>) => {
      state.concedeInProgress = action.payload;
    },
    fetchConcedeDisputeError: (state, action: PayloadAction<Error | undefined>) => {
      state.concedeDisputeError = action.payload;
    },
  },
});

export const selectDisputeError = (state: RootState): Error | undefined => state.challengeDispute.disputeError;
export const selectConcedeExplanation = (state: RootState): string => state.challengeDispute.concedeExplanation;
export const selectChallengeExplanation = (state: RootState): string => state.challengeDispute.challengeExplanation;
export const selectSupportingDocType = (state: RootState): string => state.challengeDispute.supportingDocType;
export const selectDisputedRecord = (state: RootState): Dispute | undefined => state.challengeDispute.disputedRecord;
export const selectConcedeStatus = (state: RootState): MutationStatusCode | undefined => state.challengeDispute.concedeStatus;
export const selectChallengeStatus = (state: RootState): MutationStatusCode | undefined => state.challengeDispute.challengeStatus;
export const selectConcedeInProgress = (state: RootState): boolean => state.challengeDispute.concedeInProgress;
export const selectConcedeDisputeError = (state: RootState): Error | undefined => state.challengeDispute.concedeDisputeError;

export const {
  fetchDisputeError,
  clearDisputeError,
  captureConcedeReasons,
  captureChallengeReasons,
  captureSupportingDocType,
  captureDisputedRecord,
  captureConcedeStatus,
  clearConcedeStatus,
  captureChallengeStatus,
  clearChallengeStatus,
  captureConcedeInProgress,
  fetchConcedeDisputeError,
} = slice.actions;

export default slice.reducer;
