import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning';
import DisputeInformation from '../../components/DisputeInformation';
import SubmitChallengeDispute from '../../components/SubmitChallengeDispute';
import {
  selectDefaultCurrency,
  selectPayFacIds,
  selectSelectedTenantId,
  selectTenantAccount,
  selectViewerUser,
} from '../app/AppSlice';
import {
  captureChallengeExplanation,
  captureSelectedDispute,
  captureDocumentType,
  upsertChallengeDispute,
} from './ChallengeDisputeActions';
import {
  selectChallengeExplanation,
  selectSupportingDocType,
  selectDisputedRecord,
  selectChallengeStatus,
  selectDisputeError,
  clearDisputeError,
} from './ChallengeDisputeSlice';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { createWePayDocument } from '../../util/WePay';
import {
  AppRole,
  MutationStatusCode,
  OrderDirection,
  Payment,
  PaymentOrder,
  PaymentOrderField,
  PaymentRequest,
  PaymentRequestOrder,
  PaymentRequestOrderField,
} from '../../gql-types.generated';
import { fetchPaymentById, fetchPaymentRequestById } from '../home/HomeActions';
import {
  capturePaymentById,
  capturePaymentByIdError,
  capturePaymentRequestById,
  capturePaymentRequestByIdError,
  selectPaymentById,
  selectPaymentByIdError,
  selectPaymentRequestById,
  selectPaymentRequestByIdError,
} from '../home/HomeSlice';
import PaymentDetails from '../../components/PaymentDetails';
import PaymentRequestDetails from '../../components/PaymentRequestDetails';
import LoadingMask from '../../components/LoadingMask';
import { RoutePath } from '../../util/Routes';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2, 0),
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'start',
      padding: theme.spacing(3, 4, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 3, 1),
      },
    },
    headerSubtitle: {
      letterSpacing: 1.88,
      fontSize: 12,
      fontWeight: 500,
    },
    headerTitle: {
      fontWeight: 500,
    },
    errorFree: {
      display: 'none',
    },
    errorPresent: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 24px',
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    errorIcon: {
      marginRight: theme.spacing(1),
    },
    cancelDialog: {
      padding: theme.spacing(2.5),
    },
    dialogTitle: {
      padding: '0',
      fontSize: '24px',
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },
    dialogText: {
      fontSize: '17px',
      paddingBottom: theme.spacing(5),
    },
    dialogButtonHouse: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      marginRight: theme.spacing(2),
    },
  }),
);

// TODO: Remove this when we have gql types for these
// Found at https://dev.wepay.com/docs/basic-integration/manage-payment-operations#challenge-a-dispute
export const documentationTypes = [
  { type: 'cancelation_request', display: 'Cancelation request' },
  { type: 'contract', display: 'Contract' },
  { type: 'correspondence', display: 'Correspondence' },
  { type: 'item_description', display: 'Item description' },
  { type: 'invoice', display: 'Invoice' },
  { type: 'ip_logins', display: 'IP logins' },
  { type: 'proof_of_credit', display: 'Proof of credit' },
  { type: 'return_policy', display: 'Return policy' },
  { type: 'refund_policy', display: 'Refund policy' },
  { type: 'signed_contract', display: 'Signed contract' },
  { type: 'tracking', display: 'Tracking' },
  { type: 'written_rebuttal', display: 'Written rebuttal' },
];
export const disputeStatusDisplay = [
  { name: 'CONCEDED_AWAITING_PAYFAC', display: 'Conceded (Processing)' },
  { name: 'CHALLENGE_AWAITING_PAYFAC', display: 'Challenged (Processing)' },
  { name: 'AWAITING_MERCHANT_RESPONSE', display: 'Action Needed' },
  { name: 'PENDING_PAYFAC_REVIEW', display: 'Pending Review' },
  { name: 'AWAITING_CHARGEBACK_DECISION', display: 'Awaiting Decision' },
  { name: 'RESOLVED', display: 'Settled' },
  { name: 'UNKNOWN', display: 'Unknown' },
];
export const disputeReasonDisplay = [
  { name: 'FRAUD', display: 'Fraud' },
  { name: 'RECOGNITION', display: 'Recognition' },
  { name: 'PROCESSING_ERROR', display: 'Processing Error' },
  { name: 'SERVICES_NOT_PROVIDED', display: 'Services not provided' },
  { name: 'NOT_AS_DESCRIBED', display: 'Not as described' },
  { name: 'INQUIRY', display: 'Inquiry' },
  { name: 'UNKNOWN', display: 'Unknown' },
];

export interface SupportingDoc {
  docType: string;
  file: File;
  token: string;
}
interface WePayTokenResponse {
  id: string;
  resource: string;
  path?: string;
  type: string;
}
const fileMaxSize = 10000000;

const ChallengeDispute: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const documentType = useSelector(selectSupportingDocType);
  const disputedRecord = useSelector(selectDisputedRecord);
  const challengeExplanation = useSelector(selectChallengeExplanation);
  const challengeStatus = useSelector(selectChallengeStatus);
  const disputeError = useSelector(selectDisputeError);
  const defaultCurrency = useSelector(selectDefaultCurrency);
  const payFac = useSelector(selectPayFacIds);
  const paymentById = useSelector(selectPaymentById);
  const paymentByIdError = useSelector(selectPaymentByIdError);
  const paymentRequestById = useSelector(selectPaymentRequestById);
  const paymentRequestByIdError = useSelector(selectPaymentRequestByIdError);
  const tenantAccount = useSelector(selectTenantAccount);
  const selectedTenantId = useSelector(selectSelectedTenantId);

  const user = useSelector(selectViewerUser);
  const isUserReader = user?.relationship?.role === AppRole.Reader;
  const isUserAdmin = user?.relationship?.role === AppRole.Admin;

  const accountId = payFac?.find(id => {
    return id.resourceType === 'accounts';
  })?.resourceId;

  const [submitClicked, setSubmitClicked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorState, setErrorState] = useState({ errorMessage: '', errorClass: classes.errorFree });
  const [isExplanationInvalid, setExplanationInvalid] = useState(false);
  const [areDocumentsInvalid, setDocumentsInvalid] = useState(false);
  const [supportingDocuments, setSupportingDocuments] = useState<SupportingDoc[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [disputePaymentIdClicked, setDisputePaymentIdClicked] = useState(false);
  const [disputeReferenceClicked, setDisputeReferenceClicked] = useState(false);
  const [paymentModalRecord, setPaymentModalRecord] = useState<Payment | undefined>(undefined);
  const [paymentDetailsDialogOpen, setPaymentDetailsDialogOpen] = useState(false);
  const [isInvoiceDetailClicked, setIsInvoiceDetailClicked] = useState(false);
  const [requestModalRecord, setRequestModalRecord] = useState<PaymentRequest | undefined>(undefined);
  const [requestDetailsDialogOpen, setRequestDetailsDialogOpen] = useState(false);
  const [isHistoryDetailClicked, setIsHistoryDetailClicked] = useState(false);

  const [paymentOrder] = useState<PaymentOrder>({
    direction: OrderDirection.Desc,
    field: PaymentOrderField.Timestamp,
  });
  const [paymentRequestOrder] = useState<PaymentRequestOrder>({
    direction: OrderDirection.Desc,
    field: PaymentRequestOrderField.Timestamp,
  });

  // For adding files to supportingDocuments, checking their validity, uploading them to WePay
  // See attachFile, wePayDocumentCallback, and handleFileChange
  const inputFiles = [] as File[];
  let inputFilesIndex = 0;
  let inputFileLength = 0;
  const docsCopy = [...supportingDocuments];

  const handlePaymentView = (paymentByIdRecord: Payment) => {
    setPaymentModalRecord(paymentByIdRecord);
    setPaymentDetailsDialogOpen(true);
    dispatch(capturePaymentById(undefined));
  };

  const handleRequestView = (paymentRequestByIdRecord: PaymentRequest) => {
    setRequestModalRecord(paymentRequestByIdRecord);
    setRequestDetailsDialogOpen(true);
    dispatch(capturePaymentRequestById(undefined));
  };

  useEffect(() => {
    if (challengeStatus !== undefined && challengeStatus !== MutationStatusCode.Error) {
      history.push('/');
      dispatch(clearDisputeError());
    }
  }, [challengeStatus]);

  useEffect(() => {
    if (disputeError !== undefined) {
      setErrorState({
        errorMessage: 'There was a problem processing your request. Please try again.',
        errorClass: classes.errorPresent,
      });
      setSubmitClicked(false);
    }
  }, [disputeError]);

  useEffect(() => {
    if (disputePaymentIdClicked && paymentById) {
      const paymentByIdRecord = paymentById;
      handlePaymentView(paymentByIdRecord);
    }
    setDisputePaymentIdClicked(false);
  }, [paymentById, paymentByIdError]);

  useEffect(() => {
    if (disputeReferenceClicked && paymentRequestById) {
      const paymentRequestByIdRecord = paymentRequestById;
      handleRequestView(paymentRequestByIdRecord);
    }
    setDisputeReferenceClicked(false);
  }, [paymentRequestById, paymentRequestByIdError]);

  // Redirect if there's no disputed record, like if the user goes straight to the /challenge-dispute web address
  if (!disputedRecord) {
    history.push('/');
    return <></>;
  }

  useEffect(() => {
    if (disputedRecord && selectedTenantId && disputedRecord.owner?.tenantId !== selectedTenantId) {
      history.push(RoutePath.Disputes);
    }
  }, [selectedTenantId]);

  const clearError = () => {
    setErrorState({ errorMessage: '', errorClass: classes.errorFree });
    setExplanationInvalid(false);
    setDocumentsInvalid(false);
  };

  const handleSelectDocumentChange = (value: string) => {
    dispatch(captureDocumentType(value));
  };

  const handleChallengeExplanationInput = (explanation: string) => {
    dispatch(captureChallengeExplanation(explanation));
  };

  const handleRemoveClick = (index: number, token: string) => {
    if (supportingDocuments && supportingDocuments[0]) {
      const docs = [...supportingDocuments];
      if (docs[index].token === token) {
        docs.splice(index, 1);
        setSupportingDocuments(docs);
      }
    }
  };

  // Functions for cancel, modal confirmation buttons, and submit
  const handleCancel = () => {
    setDialogOpen(true);
  };
  const handleDialogCancel = () => {
    setDialogOpen(false);
  };
  const handleLeave = () => {
    dispatch(captureDocumentType(''));
    dispatch(captureChallengeExplanation(''));
    dispatch(captureSelectedDispute(undefined));
  };
  const handleSubmit = () => {
    clearError();
    if (challengeExplanation.trim() !== '') {
      if (supportingDocuments.length === 0) {
        setErrorState({ errorMessage: 'You must provide supporting documents', errorClass: classes.errorPresent });
        setDocumentsInvalid(true);
      } else {
        const documents = [] as string[];
        supportingDocuments.forEach(doc => {
          documents.push(doc.token);
        });
        if (disputedRecord && disputedRecord.id) {
          setSubmitClicked(true);
          dispatch(upsertChallengeDispute(disputedRecord.id, challengeExplanation, documents));
        }
      }
    } else {
      setErrorState({
        errorMessage: 'You must provide a written explanation for the challenge',
        errorClass: classes.errorPresent,
      });
      setExplanationInvalid(true);
    }
  };

  // Uploading files to wePay, receiving the response
  // Adds the response, the original file, and the display for the file's documentType to the supportingDocuments
  const wePayDocumentCallback = (response: WePayTokenResponse) => {
    console.log(response);
    if (response && response.id) {
      // TODO: replace this when we have the real types
      const docTypeDisplay = documentationTypes.find(item => {
        return item.type === documentType;
      })?.display;
      const fileObject = {
        docType: docTypeDisplay,
        file: inputFiles[inputFilesIndex],
        token: response.id,
      };
      docsCopy.push(fileObject as SupportingDoc);
      inputFilesIndex += 1;
      if (inputFilesIndex === inputFileLength) {
        setSupportingDocuments(docsCopy);
        handleSelectDocumentChange('');
        setShowLoading(false);
      }
    } else {
      setErrorState({
        errorMessage: `File: ${inputFiles[inputFilesIndex].name} could not be uploaded due to a 400 error`,
        errorClass: classes.errorPresent,
      });
      setShowLoading(false);
    }
  };
  const attachFile = (potentialFile: File) => {
    if (!createWePayDocument) {
      console.log('WePay not initialized.  Can not submit');
      setErrorState({
        errorMessage: 'Files currently cannot be submitted',
        errorClass: classes.errorPresent,
      });
      return false;
    }
    if (supportingDocuments.length === 5) {
      setErrorState({
        errorMessage: 'You may not upload more than 5 documents',
        errorClass: classes.errorPresent,
      });
      return false;
    }
    if (documentType !== '') {
      const filePresent = supportingDocuments.find(item => {
        return (
          item.file.type === potentialFile.type &&
          item.file.name === potentialFile.name &&
          item.file.size === potentialFile.size &&
          item.file.lastModified === potentialFile.lastModified
        );
      });
      if (filePresent) {
        setErrorState({
          errorMessage: `You may not upload file ${potentialFile.name} twice`,
          errorClass: classes.errorPresent,
        });
        return false;
      }
      inputFiles.push(potentialFile);
      const body = {
        type: documentType,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        account_id: accountId,
        file: potentialFile,
      };
      const headers = {};
      createWePayDocument(body, headers, wePayDocumentCallback);
      return true;
    }
    return false;
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target) {
      clearError();
      setShowLoading(true);
      const input = event.target;
      if (input.files && input.files[input.files.length - 1]) {
        inputFileLength = input.files.length;
        for (let inputCount = 0; inputCount < input.files.length; inputCount += 1) {
          const currFile = input.files[inputCount];
          if (currFile.size <= fileMaxSize) {
            if (!attachFile(currFile)) {
              setShowLoading(false);
              break;
            }
          }
        }
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handlePaymentClicked = (paymentId?: string | null) => {
    if (paymentId) {
      setDisputePaymentIdClicked(true);
      dispatch(fetchPaymentById(paymentOrder, paymentId));
    }
  };

  const handlePaymentDetailsClose = () => {
    setPaymentDetailsDialogOpen(false);
    setPaymentModalRecord(undefined);
  };

  const invoiceDetailClicked = (paymentRequestId: string) => {
    setIsInvoiceDetailClicked(true);
    dispatch(fetchPaymentRequestById(paymentRequestOrder, paymentRequestId));
  };

  const openModalFromPaymentRequestInvoice = () => {
    if (paymentRequestById || paymentRequestByIdError) {
      if (paymentRequestById) {
        const paymentRequestByIdRecord = paymentRequestById;

        if (isInvoiceDetailClicked) {
          handlePaymentDetailsClose();
          handleRequestView(paymentRequestByIdRecord);
        }
        dispatch(capturePaymentRequestById(undefined));
      }
      if (paymentRequestByIdError) {
        dispatch(capturePaymentRequestByIdError(undefined));
      }
      if (isInvoiceDetailClicked) setIsInvoiceDetailClicked(false);
    }
  };

  const handleRequestClicked = (paymentRequestId?: string | null) => {
    if (paymentRequestId) {
      setDisputeReferenceClicked(true);
      dispatch(fetchPaymentRequestById(paymentRequestOrder, paymentRequestId));
    }
  };

  const handleRequestDetailsClose = () => {
    setRequestDetailsDialogOpen(false);
    setRequestModalRecord(undefined);
  };

  const historyDetailClicked = (paymentId: string) => {
    setIsHistoryDetailClicked(true);
    dispatch(fetchPaymentById(paymentOrder, paymentId));
  };

  const openModalFromPaymentHistory = () => {
    if (paymentById || paymentByIdError) {
      if (paymentById) {
        const paymentByIdRecord = paymentById;

        if (isHistoryDetailClicked) {
          handleRequestDetailsClose();
          handlePaymentView(paymentByIdRecord);
        }
        dispatch(capturePaymentById(undefined));
      }
      if (paymentByIdError) {
        dispatch(capturePaymentByIdError(undefined));
      }
      if (isHistoryDetailClicked) setIsHistoryDetailClicked(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="ai:viewId" content="challenge-dispute"></meta>
        <meta name="ai:viewDescription" content="Aptean Pay Merchant Portal - Challenge Dispute"></meta>
        <title>Aptean Pay Merchant Portal - Challenge Dispute</title>
      </Helmet>
      <PaymentDetails
        open={paymentDetailsDialogOpen}
        onClose={handlePaymentDetailsClose}
        paymentRecord={paymentModalRecord}
        defaultCurrency={defaultCurrency}
        invoiceDetailClicked={invoiceDetailClicked}
        openModalFromPaymentRequestInvoice={openModalFromPaymentRequestInvoice}
        paymentRequestById={paymentRequestById}
        paymentRequestByIdError={paymentRequestByIdError}
        isFetchingPaymentRequestById={isInvoiceDetailClicked}
        isChallengeDispute={true}
        isCreditMemoEnabled={!!tenantAccount?.settings?.features?.creditMemos?.enabled}
        canCreateRefund={false}
        isUserReader={isUserReader}
      />
      <PaymentRequestDetails
        open={requestDetailsDialogOpen}
        onClose={handleRequestDetailsClose}
        paymentRecord={requestModalRecord}
        defaultCurrency={defaultCurrency}
        remindPressed={false}
        savePressed={false}
        historyDetailClicked={historyDetailClicked}
        openModalFromPaymentHistory={openModalFromPaymentHistory}
        paymentById={paymentById}
        paymentByIdError={paymentByIdError}
        isFetchingPaymentById={isHistoryDetailClicked}
        isChallengeDispute={true}
        isCreditMemoEnabled={!!tenantAccount?.settings?.features?.creditMemos?.enabled}
        isCloseOrCompleteWaiting={false}
        canUpdatePaymentRequest={false}
      />
      <Container maxWidth={false}>
        <Dialog
          aria-label={'leave page dialog'}
          open={dialogOpen}
          disableEscapeKeyDown
          fullWidth={true}
          maxWidth={'xs'}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box className={classes.cancelDialog} data-cy="cancel-dialog">
            <DialogTitle className={classes.dialogTitle} id="modal-title">
              Leave this page?
            </DialogTitle>
            <DialogContentText color="textPrimary" className={classes.dialogText} id="modal-description">
              Your documentation and explanation will be cleared.
            </DialogContentText>
            <DialogActions className={classes.dialogButtonHouse}>
              <Button
                onClick={handleDialogCancel}
                className={classes.cancelButton}
                variant="text"
                color="primary"
                data-cy="dialog-cancel-button"
              >
                Cancel
              </Button>
              <Button
                component={NavLink}
                to="/"
                onClick={handleLeave}
                variant="contained"
                color="primary"
                data-cy="dialog-leave-button"
              >
                Leave
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={7} lg={6}>
            <Paper data-cy="challenge-dispute-box">
              <Box className={classes.header} data-cy="challenge-dispute-header">
                <Typography className={classes.headerSubtitle}>CHALLENGE A DISPUTE</Typography>
                <Typography variant="title" className={classes.headerTitle}>
                  Upload acceptable documents and provide a written explanation
                </Typography>
              </Box>
              {errorState.errorMessage && (
                <Typography className={errorState.errorClass} data-cy="challenge-dispute-error">
                  <WarningIcon color="error" className={classes.errorIcon} />
                  {errorState.errorMessage}
                </Typography>
              )}
              <SubmitChallengeDispute
                supportingDocuments={supportingDocuments}
                documentType={documentType}
                documentsError={areDocumentsInvalid}
                handleSelectChange={handleSelectDocumentChange}
                explanation={challengeExplanation}
                explanationError={isExplanationInvalid}
                handleTextfieldChange={handleChallengeExplanationInput}
                submitClicked={submitClicked}
                cancel={handleCancel}
                submit={handleSubmit}
                handleFileChange={handleFileChange}
                handleRemoveClick={handleRemoveClick}
                showMask={showLoading}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={5} lg={6}>
            <Paper className={classes.paper}>
              <LoadingMask loading={disputePaymentIdClicked || disputeReferenceClicked} />
              <DisputeInformation
                disputedRecord={disputedRecord}
                disputeState={'DISPUTED'}
                isDialog={false}
                handlePaymentView={handlePaymentClicked}
                handleRequestView={handleRequestClicked}
                isCreditMemoEnabled={!!tenantAccount?.settings?.features?.creditMemos?.enabled}
                isUserAdmin={isUserAdmin}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default ChallengeDispute;
