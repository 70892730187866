/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CountryCode } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
  tenantAccountCountry: CountryCode;
  merchantRefundPolicy?: string;
  legalEntityToken?: string;
  merchantTosAgreementSignerName?: string;
  error?: Error;
  merchantName?: string;
  merchantDescription?: string;
  merchantStatement?: string;
  supportEmail?: string;
}

const initialState: SliceState = {
  tenantAccountCountry: CountryCode.Us,
  merchantRefundPolicy: undefined,
  legalEntityToken: undefined,
  merchantTosAgreementSignerName: undefined,
  error: undefined,
  merchantName: '',
  merchantDescription: '',
  merchantStatement: '',
  supportEmail: undefined,
};

export const slice = createSlice({
  name: 'merchantOnboarding',
  initialState,
  reducers: {
    captureCountry: (state, action: PayloadAction<CountryCode>) => {
      state.tenantAccountCountry = action.payload;
    },
    captureRefundPolicy: (state, action: PayloadAction<string>) => {
      state.merchantRefundPolicy = action.payload;
    },
    captureToken: (state, action: PayloadAction<string>) => {
      state.legalEntityToken = action.payload;
    },
    captureTOSSignerName: (state, action: PayloadAction<string>) => {
      state.merchantTosAgreementSignerName = action.payload;
    },
    persistMerchantOnboardingDataError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    captureMerchantName: (state, action: PayloadAction<string>) => {
      state.merchantName = action.payload;
    },
    captureMerchantDescription: (state, action: PayloadAction<string>) => {
      state.merchantDescription = action.payload;
    },
    captureMerchantStatement: (state, action: PayloadAction<string>) => {
      state.merchantStatement = action.payload;
    },
    captureSupportEmail: (state, action: PayloadAction<string>) => {
      state.supportEmail = action.payload;
    },
  },
});

export const selectTenantAccountCountry = (state: RootState): CountryCode => state.merchantOnboarding.tenantAccountCountry;
export const selectLegalEntityToken = (state: RootState): string | undefined => state.merchantOnboarding.legalEntityToken;
export const selectMerchantRefundPolicy = (state: RootState): string | undefined => state.merchantOnboarding.merchantRefundPolicy;
export const selectMerchantOnboardingError = (state: RootState): Error | undefined => state.merchantOnboarding.error;
export const selectTOSSignerName = (state: RootState): string | undefined => state.merchantOnboarding.merchantTosAgreementSignerName;
export const selectMerchantName = (state: RootState): string | undefined => state.merchantOnboarding.merchantName;
export const selectMerchantDescription = (state: RootState): string | undefined => state.merchantOnboarding.merchantDescription;
export const selectMerchantStatement = (state: RootState): string | undefined => state.merchantOnboarding.merchantStatement;
export const selectSupportEmail = (state: RootState): string | undefined => state.merchantOnboarding.supportEmail;

export const {
  captureCountry,
  captureRefundPolicy,
  captureToken,
  captureTOSSignerName,
  persistMerchantOnboardingDataError,
  captureMerchantName,
  captureMerchantDescription,
  captureMerchantStatement,
  captureSupportEmail,
} = slice.actions;

export default slice.reducer;
