import { Box, Divider, Grid, Paper, Theme, Typography, Dialog, SelectChangeEvent } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccountStatus from '../../components/AccountStatus';
import BusinessInformation from '../../components/BusinessInformation';
import BusinessOwners from '../../components/BusinessOwners';
import PayoutSettings from '../../components/PayoutSettings';
import { IntegrationApiError } from '../../custom-types/IntegrationApiError';
import { WePayTokenResponse } from '../../custom-types/WePayTokenResponse';
import { AccountRequirements, CapabilityStatus, CurrencyType, PayoutInterval } from '../../gql-types.generated';
import { createWePayToken } from '../../util/WePay';
import {
  selectDefaultCurrency,
  selectFeeSchedule,
  selectTenantAccount,
  selectNetworkBusy,
  selectViewerUser,
  selectTenantId,
  selectTenantSupportedCapabilities,
} from '../app/AppSlice';
import { selectAccountRequirements } from '../home/HomeSlice';
import { fetchUserList } from '../user-management/UserManagementActions';
import { selectBusinessOwners, selectPrimaryAccountHolder } from '../user-management/UserManagementSlice';
import {
  fetchPayoutSettings,
  uploadLogo,
  upsertBusinessUrl,
  upsertBusinessName,
  upsertPayerOptions,
  upsertPayoutFrequency,
  upsertPayoutSettings,
  upsertRefundPolicy,
  upsertStatementDescription,
  upsertSupportEmail,
} from './AccountManagementActions';
import {
  captureActiveAccountNumber,
  captureActiveAccountType,
  captureActiveInstitutionNumber,
  captureActiveRoutingTransitNumber,
  captureInvalidAccountNumber,
  captureInvalidInstitutionNumber,
  captureInvalidRoutingTransitNumber,
  captureModifyFrequencyVisible,
  captureModifyPayoutVisible,
  capturePayoutSettingsTokenErrorMessage,
  captureAddingPayoutMethod,
  fetchError,
  selectActiveAccountNumber,
  selectActiveAccountType,
  selectActiveInstitutionNumber,
  selectActiveRoutingTransitNumber,
  selectInvalidAccountNumber,
  selectInvalidInstitutionNumber,
  selectInvalidRoutingTransitNumber,
  selectModifyFrequencyVisible,
  selectModifyPayoutVisible,
  selectPayoutSettings,
  selectPayoutSettingsError,
  selectPayoutSettingsStatusSuccess,
  selectPayoutSettingsTokenError,
  selectAddingPayoutMethod,
} from './AccountManagementSlice';
import ConfirmFrequency from '../../components/ConfirmFrequency';
import PayerOptions from '../../components/PayerOptions';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      flex: 1,
      height: '100%',
      minHeight: 350,
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'start',
      padding: theme.spacing(2.5),
    },
    headerWarning: {
      color: theme.palette.error.main,
      display: 'flex',
      alignItems: 'flex-end',
    },
    headerWarningIcon: {
      marginRight: theme.spacing(2),
    },
    grid: {
      padding: theme.spacing(3),
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      border: `1px solid ${theme.palette.uxGrey.border}`,
      borderRadius: 10,
      minHeight: 100,
      padding: theme.spacing(3, 2),
      marginBottom: theme.spacing(3),
    },
  }),
);

export interface PayoutMethodData {
  invalidRoutingTransitNumber: boolean;
  invalidAccountNumber: boolean;
  invalidInstitutionNumber: boolean;
  error: Error;
  tokenError: Error;
  accountNumber: string | undefined;
  routingTransitNumber: string | undefined;
  institutionNumber: string | undefined;
  accountType: string;
  statusSuccess: boolean;
}
const AccountManagement: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const viewerUser = useSelector(selectViewerUser);
  const payoutSettings = useSelector(selectPayoutSettings);
  const tenantAccount = useSelector(selectTenantAccount);
  const defaultCurrency = useSelector(selectDefaultCurrency);
  const feeSchedule = useSelector(selectFeeSchedule);
  const accountRequirements = useSelector(selectAccountRequirements);
  const networkBusy = useSelector(selectNetworkBusy);

  const businessOwnersList = useSelector(selectBusinessOwners);
  const primaryAccountHolder = useSelector(selectPrimaryAccountHolder);
  const isAddingPayoutMethod = useSelector(selectAddingPayoutMethod);
  const tenantId = useSelector(selectTenantId);

  // Obtain the proper data to be passed along to the components within the feature.
  const payoutMethodData = {
    statusSuccess: useSelector(selectPayoutSettingsStatusSuccess),
    accountNumber: useSelector(selectActiveAccountNumber),
    institutionNumber: useSelector(selectActiveInstitutionNumber),
    routingTransitNumber: useSelector(selectActiveRoutingTransitNumber),
    accountType: useSelector(selectActiveAccountType),
    invalidRoutingTransitNumber: useSelector(selectInvalidRoutingTransitNumber),
    invalidAccountNumber: useSelector(selectInvalidAccountNumber),
    invalidInstitutionNumber: useSelector(selectInvalidInstitutionNumber),
    tokenError: useSelector(selectPayoutSettingsTokenError),
    error: useSelector(selectPayoutSettingsError),
  } as PayoutMethodData;
  const modifyPayoutVisible = useSelector(selectModifyPayoutVisible);
  const modifyFrequencyVisible = useSelector(selectModifyFrequencyVisible);
  const tenantSupportedCapabilities = useSelector(selectTenantSupportedCapabilities);
  const payoutsEnabled = tenantAccount?.capabilities?.accountPayouts === CapabilityStatus.Active;
  const paymentsDisabled =
    (tenantAccount?.settings?.supportedCapabilities.refundPolicy && !tenantAccount.settings.cardPayments?.refundPolicy) ||
    (tenantAccount?.settings?.supportedCapabilities.website && !tenantAccount.businessProfile?.url);
  const [frequencyDialogOpen, setFrequencyDialogOpen] = useState(false);

  const clearErrors = () => {
    dispatch(fetchError(undefined));
    dispatch(capturePayoutSettingsTokenErrorMessage(''));
    dispatch(captureInvalidAccountNumber(false));
    dispatch(captureInvalidInstitutionNumber(false));
    dispatch(captureInvalidRoutingTransitNumber(false));
  };
  const clearActiveData = () => {
    dispatch(captureActiveAccountNumber(''));
    dispatch(captureActiveRoutingTransitNumber(''));
    dispatch(captureActiveInstitutionNumber(''));
    dispatch(captureActiveAccountType('checking'));
  };

  useEffect(() => {
    clearErrors();
    clearActiveData();
    // Close and reset current editing
    dispatch(captureModifyPayoutVisible(false));
    dispatch(fetchPayoutSettings());
    // Fetch the users to display business owner information.
    console.log('[0] retrieving user list for business owner info');
    dispatch(fetchUserList());
  }, [tenantAccount]);

  useEffect(() => {
    if (payoutMethodData.statusSuccess && !payoutMethodData.tokenError && !payoutMethodData.error) {
      clearErrors();
      // Close and reset current editing
      dispatch(captureModifyPayoutVisible(false));
      dispatch(fetchPayoutSettings());
    }
  }, [payoutMethodData.tokenError, payoutMethodData.error, payoutMethodData.statusSuccess]);
  const editFrequency = () => {
    // Close and reset current editing
    dispatch(captureModifyFrequencyVisible(true));
  };
  const closeFrequency = () => {
    // Close and reset current editing
    dispatch(captureModifyFrequencyVisible(false));
  };
  const saveFrequency = (interval: PayoutInterval) => {
    // Dispatch the upsert.
    dispatch(upsertPayoutFrequency(interval));
    dispatch(captureModifyFrequencyVisible(false));
  };
  const closePayoutMethod = () => {
    clearErrors();
    clearActiveData();
    // Close and reset current editing
    dispatch(captureModifyPayoutVisible(false));
  };
  const editPayoutMethod = () => {
    // Add new.
    clearErrors();
    clearActiveData();
    dispatch(captureModifyPayoutVisible(true));
  };
  const addPayoutMethod = () => {
    // Add new.
    clearErrors();
    clearActiveData();
    dispatch(captureModifyPayoutVisible(true));
  };
  const isPayoutMethodValid = () => {
    return !!(
      payoutMethodData.accountNumber &&
      payoutMethodData.routingTransitNumber &&
      payoutMethodData.accountType &&
      (defaultCurrency === CurrencyType.Usd || (defaultCurrency === CurrencyType.Cad && payoutMethodData.institutionNumber))
    );
  };
  const handleAccountTypeChange = (event: SelectChangeEvent<string>) => {
    dispatch(captureActiveAccountType(event.target.value));
  };
  const handleAccountNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(captureActiveAccountNumber(event.target.value));
  };
  const handleInstitutionNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(captureActiveInstitutionNumber(event.target.value));
  };
  const handleRoutingTransitNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(captureActiveRoutingTransitNumber(event.target.value));
  };
  const handleNewRefundPolicy = (refundPolicy: string) => {
    dispatch(upsertRefundPolicy(refundPolicy));
  };
  const handleNewLogo = (logo: File, highResLogo?: File) => {
    dispatch(uploadLogo(logo, highResLogo));
  };
  const handleNewStatementDescription = (statementDescription: string) => {
    dispatch(upsertStatementDescription(statementDescription));
  };
  const handleNewBusinessUrl = (businessUrl: string) => {
    dispatch(upsertBusinessUrl(businessUrl));
  };
  const handleNewSupportEmail = (supportEmail: string) => {
    dispatch(upsertSupportEmail(supportEmail));
  };
  const handleSetAddingPayoutMethod = (isAdding: boolean) => {
    dispatch(captureAddingPayoutMethod(isAdding));
  };
  const handleNewBusinessName = (businessName: string) => {
    dispatch(upsertBusinessName(businessName));
  };
  const handleTokenError = (integrationApiError: IntegrationApiError) => {
    const { details, message } = integrationApiError;
    if (details) {
      // Handle the details of the error.
      details.forEach(detail => {
        const targetField = detail.target[detail.target.length - 1];
        if (targetField === 'routing_number' || targetField === 'transit_number') {
          dispatch(captureInvalidRoutingTransitNumber(true));
        } else if (targetField === 'account_number') {
          dispatch(captureInvalidAccountNumber(true));
        } else if (targetField === 'institution_number') {
          dispatch(captureInvalidInstitutionNumber(true));
        }
      });
    }
    dispatch(capturePayoutSettingsTokenErrorMessage(message));
  };
  const submitPayoutMethod = (interval?: PayoutInterval) => {
    clearErrors();
    if (isPayoutMethodValid()) {
      if (!createWePayToken) {
        console.log('WePay not initialized. Can not submit payout method.');
        return;
      }
      // Tokenize request for create/edit/delete payout method.
      // https://dev.wepay.com/docs/basic-integration/platform-setup#create-a-token
      // Create payout method token info
      // https://dev.wepay.com/docs/basic-integration/payout-merchants?keyword=%3A%20%22payout_methods#create-a-payout-method
      // Testing:
      // "account_number": "12345678", // Use any 3-17 digit bank account number for testing.
      // "routing_number": "021000021", // Testing US routing number
      // payout_bank_us
      // payout_bank_ca
      // Based on default currency - tokenize the correct payout method.
      let payoutType = '';
      let payoutObject;
      if (defaultCurrency === CurrencyType.Usd) {
        payoutType = 'payout_bank_us';
        payoutObject = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          account_number: payoutMethodData.accountNumber,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          routing_number: payoutMethodData.routingTransitNumber,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          account_type: payoutMethodData.accountType,
        };
      } else if (defaultCurrency === CurrencyType.Cad) {
        payoutType = 'payout_bank_ca';
        payoutObject = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          account_number: payoutMethodData.accountNumber,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          transit_number: payoutMethodData.routingTransitNumber,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          account_type: payoutMethodData.accountType,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          institution_number: payoutMethodData.institutionNumber,
        };
      } else {
        dispatch(
          fetchError({
            message: `Unable to determine an allowed payout type based on the default currency: "${defaultCurrency}"`,
          } as Error),
        );
        return;
      }
      createWePayToken(
        {
          resource: 'payout_methods',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          payout_methods: {
            type: payoutType,
            [payoutType]: payoutObject,
          },
        },
        {},
        (response: WePayTokenResponse) => {
          if (response && response.error_code) {
            const { error_code: errorCode, error_message: errorMessage, details } = response;
            const integrationApiError: IntegrationApiError = {
              code: errorCode,
              message: errorMessage as string,
              details: details?.map(d => {
                return { target: d.target, targetType: d.target_type, reasonCode: d.reason_code, message: d.message };
              }),
            };
            handleTokenError(integrationApiError);
            return;
          }
          const { id: token } = response;

          if (!token) {
            const integrationApiError: IntegrationApiError = {
              code: 'TOKEN_ERROR',
              message: 'Token not present.',
            };
            handleTokenError(integrationApiError);
          }
          // If no errors, dispatch mutation to the backend with the token.
          // Dispatch gql and await to close.
          dispatch(upsertPayoutSettings(token as string, interval));
          dispatch(captureAddingPayoutMethod(false));
        },
      );
    }
  };

  const handleDailyFrequencyModal = () => {
    setFrequencyDialogOpen(true);
  };
  const cancelDailyFrequencyModal = () => {
    setFrequencyDialogOpen(false);
  };
  const handleConfirmDailyFrequency = () => {
    saveFrequency(PayoutInterval.Daily as PayoutInterval);
    setFrequencyDialogOpen(false);
  };

  const handleUpdatePartialReason = (partialReasonRequired: boolean) => {
    dispatch(upsertPayerOptions(partialReasonRequired));
  };

  const findRequiredSettings = (accountRequirements: AccountRequirements | undefined): string[] => {
    if (!accountRequirements) {
      return [];
    }
    const { currentlyDue, pastDue, pendingVerification } = accountRequirements;
    const needed = [] as string[];
    pastDue?.forEach(item => {
      if (!item.includes('verification') && !pendingVerification?.includes(item)) {
        needed.push(item);
      }
    });
    currentlyDue?.forEach(item => {
      if (!item.includes('verification') && !pendingVerification?.includes(item) && !needed.includes(item)) {
        needed.push(item);
      }
    });
    return needed;
  };
  const requiredSettings = findRequiredSettings(accountRequirements);
  return (
    <Paper className={classes.root} role="region" aria-label="Account Settings">
      <Helmet>
        <meta name="ai:viewId" content="account-management"></meta>
        <meta name="ai:viewDescription" content="Aptean Pay Merchant Portal - Account Management"></meta>
        <title>Aptean Pay Merchant Portal - Account Management</title>
      </Helmet>
      <Dialog
        aria-label={'payment frequency dialog'}
        open={frequencyDialogOpen}
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ConfirmFrequency cancel={cancelDailyFrequencyModal} confirm={handleConfirmDailyFrequency} />
      </Dialog>
      <Box>
        <Box className={classes.header}>
          <Typography variant="title">Account Settings</Typography>
          {(paymentsDisabled || requiredSettings.length > 0) && (
            <Typography variant="body1" className={classes.headerWarning}>
              <WarningIcon className={classes.headerWarningIcon} /> Please add all required data indicated in red to receive payments
            </Typography>
          )}
        </Box>
        <Divider />
        <Grid item className={classes.grid} container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box className={classes.item}>
              <BusinessInformation
                tenantAccount={tenantAccount}
                handleNewRefundPolicy={handleNewRefundPolicy}
                handleNewStatementDescription={handleNewStatementDescription}
                feeSchedule={feeSchedule}
                networkBusy={networkBusy}
                handleNewLogo={handleNewLogo}
                handleNewBusinessUrl={handleNewBusinessUrl}
                handleNewSupportEmail={handleNewSupportEmail}
                viewerAppRole={viewerUser?.relationship?.role}
                tenantId={tenantId}
                handleNewBusinessName={handleNewBusinessName}
                supportedCapabilities={tenantSupportedCapabilities}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            {!!tenantSupportedCapabilities?.payouts && (
              <Box className={classes.item}>
                <PayoutSettings
                  defaultCurrency={defaultCurrency}
                  payoutsEnabled={payoutsEnabled}
                  viewerAppRole={viewerUser?.relationship?.role}
                  modifyPayoutVisible={modifyPayoutVisible}
                  modifyFrequencyVisible={modifyFrequencyVisible}
                  submitPayoutMethod={submitPayoutMethod}
                  payoutSettings={payoutSettings}
                  payoutMethodData={payoutMethodData}
                  isPayoutMethodValid={isPayoutMethodValid}
                  handleAddPayoutMethod={addPayoutMethod}
                  handleClosePayoutMethod={closePayoutMethod}
                  handleCloseFrequency={closeFrequency}
                  handleEditPayoutMethod={editPayoutMethod}
                  handleEditFrequency={editFrequency}
                  handleSaveFrequency={saveFrequency}
                  handleDailyFrequency={handleDailyFrequencyModal}
                  handleAccountTypeChange={handleAccountTypeChange}
                  handleInstitutionNumberChange={handleInstitutionNumberChange}
                  handleRoutingTransitNumberChange={handleRoutingTransitNumberChange}
                  handleAccountNumberChange={handleAccountNumberChange}
                  requiredSettings={requiredSettings}
                  isAddingPayoutMethod={isAddingPayoutMethod}
                  handleSetAddingPayoutMethod={handleSetAddingPayoutMethod}
                />
              </Box>
            )}
            {tenantAccount?.settings?.features?.paymentRequests?.partialPayment && (
              <Box className={classes.item}>
                <PayerOptions
                  tenantAccount={tenantAccount}
                  updatingPayerOptions={networkBusy}
                  handleUpdatePartialReason={handleUpdatePartialReason}
                  viewerAppRole={viewerUser?.relationship?.role}
                />
              </Box>
            )}
            {!!tenantSupportedCapabilities?.accountStatus && (
              <Box className={classes.item}>
                <AccountStatus
                  tenantAccount={tenantAccount}
                  primaryAccountHolder={primaryAccountHolder}
                  additionalReps={businessOwnersList}
                />
              </Box>
            )}
            {!!tenantSupportedCapabilities?.businessOwners && businessOwnersList.length > 0 && (
              <Box className={classes.item}>
                <BusinessOwners additionalReps={businessOwnersList} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AccountManagement;
