import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import {
  captureConcedeReasons,
  captureChallengeReasons,
  captureSupportingDocType,
  captureDisputedRecord,
  fetchDisputeError,
  captureConcedeStatus,
  captureChallengeStatus,
  captureConcedeInProgress,
  fetchConcedeDisputeError,
} from './ChallengeDisputeSlice';
import { Dispute, MutationStatusCode } from '../../gql-types.generated';
import { mutationConcedeDispute } from '../../gql/MutationConcedeDispute';
import { mutationChallengeDispute } from '../../gql/MutationChallengeDispute';

export const captureConcedeExplanation =
  (explanation: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureConcedeReasons(explanation));

export const captureChallengeExplanation =
  (explanation: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureChallengeReasons(explanation));

export const captureDocumentType =
  (value: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureSupportingDocType(value));

export const captureSelectedDispute =
  (record: Dispute | undefined): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureDisputedRecord(record));

export const upsertConcedeDispute =
  (disputeId: string, explanation: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(fetchConcedeDisputeError(undefined));
      dispatch(captureConcedeInProgress(true));
      const concede = await mutationConcedeDispute(gqlClient, disputeId, explanation);
      if (concede) {
        dispatch(captureConcedeStatus(concede));
        if (concede === MutationStatusCode.Error) {
          dispatch(fetchDisputeError({ message: 'Concede upsert error' } as Error));
        } else {
          // Clearing the dispute and concede data
          dispatch(captureConcedeExplanation(''));
          dispatch(captureSelectedDispute(undefined));
        }
      }
      if (!concede) {
        throw new Error('[ChallengeDisputeActions] Concede dispute not sent');
      }
      dispatch(captureConcedeInProgress(false));
    } catch (e) {
      dispatch(fetchConcedeDisputeError(e));
      dispatch(captureConcedeInProgress(false));
      console.error(e);
    }
  };

export const upsertChallengeDispute =
  (disputeId: string, explanation: string, documents: string[]): AppThunk =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const challenge = await mutationChallengeDispute(gqlClient, disputeId, explanation, documents);
      if (challenge) {
        dispatch(captureChallengeStatus(challenge));
        if (challenge === MutationStatusCode.Error) {
          dispatch(fetchDisputeError({ message: 'Challenge upsert error' } as Error));
        } else {
          // Clearing the dispute and challenge data
          dispatch(captureDocumentType(''));
          dispatch(captureChallengeExplanation(''));
          dispatch(captureSelectedDispute(undefined));
        }
      }
      if (!challenge) {
        throw new Error('[ChallengeDisputeActions] Challenge dispute not sent');
      }
    } catch (e) {
      console.error(e);
    }
  };
