import { MenuItem } from '@mui/material';

import { CountryCode } from '../gql-types.generated';

const getCorrespondingVerificationMenuItems = (legalEntityCountryCode: string, isMerchant: boolean) => {
  if (isMerchant) {
    // entity
    if (legalEntityCountryCode === CountryCode.Us) {
      // US Entity
      return [
        <MenuItem value={'evidence_of_corporate_registration_1'} key={1}>
          Articles of Incorporation / Certificate of Incorporation
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_2'} key={2}>
          Articles of Organization / Certificate of Organization
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_3'} key={3}>
          DBA Registration/Assumed Name Certificate
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_4'} key={4}>
          Partnership Agreement (LLP, LP)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_5'} key={5}>
          Trust Agreement and Amendment
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_6'} key={6}>
          Articles of Amendment/Restated (Designation/Merger)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_7'} key={7}>
          IRS 501(c) (3) determination letter
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_8'} key={8}>
          Business License (Sole Proprietorship)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_9'} key={9}>
          Certificate of Association
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_10'} key={10}>
          Certificate of Authority
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_11'} key={11}>
          Certificate of Domestication/Certificate of -Corporate Domestication (Delaware only)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_12'} key={12}>
          Certificate of Existence
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_13'} key={13}>
          Certificate of Formation (for US only, Limited Liability Company (LLC))
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_14'} key={14}>
          Certificate of Formation (Texas)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_15'} key={15}>
          Certificate of Good Standing (Current)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_16'} key={16}>
          Certificate of Incorporation
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_17'} key={17}>
          Certificate of Limited Partnership (LP)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_18'} key={18}>
          Certificate of Organization
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_19'} key={19}>
          Certificate of Trust
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_20'} key={20}>
          Exemption (tax) Letter (by State or IRS)
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_21'} key={24}>
          Schedule Q Form 5300
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_22'} key={25}>
          IRS Confirmation of TIN
        </MenuItem>,
        <MenuItem value={'current_utility_bill'} key={21}>
          Utility bill bearing the name and address used for opening the account including: Electric, Gas, Water, Phone, Cable, Alarm,
          HOA.
        </MenuItem>,
        <MenuItem value={'current_bank_statement'} key={22}>
          Statements including: Bank Statements, Credit Card Statements, Property Tax Bill, HUD Statement, Mortgage, HOA.
        </MenuItem>,
        <MenuItem value={'current_lease_contract'} key={23}>
          Rental/Lease contract for current residence bearing the name and address used for the opening account
        </MenuItem>,
      ];
    }
    if (legalEntityCountryCode === CountryCode.Ca) {
      // CA Entity
      return [
        <MenuItem value={'evidence_of_corporate_registration_1'} key={26}>
          Certificate of Status / Certificate of Good Standing / Certificate of Compliance / Certificate of Existence
        </MenuItem>,
        <MenuItem value={'evidence_of_corporate_registration_2'} key={27}>
          Articles of Incorporation / Articles of Association
        </MenuItem>,
        <MenuItem value={'current_utility_bill'} key={28}>
          Utility bill bearing the name and address used for opening the account including: Electric, Gas, Water, Phone, Cable, Alarm,
          HOA.
        </MenuItem>,
        <MenuItem value={'current_bank_statement'} key={29}>
          Statements including: Bank Statements, Credit Card Statements, Property Tax Bill, HUD Statement, Mortgage, HOA.
        </MenuItem>,
        <MenuItem value={'current_lease_contract'} key={30}>
          Rental/Lease contract for current residence bearing the name and address used for the opening account
        </MenuItem>,
      ];
    }
  } else {
    // user
    if (legalEntityCountryCode === CountryCode.Us) {
      // US User
      return [
        <MenuItem value={'drivers_license'} key={31}>
          Driver’s License with photo
        </MenuItem>,
        <MenuItem value={'other_government_issued_photo_id_1'} key={32}>
          State or Government Issued ID with photo
        </MenuItem>,
        <MenuItem value={'other_government_issued_photo_id_2'} key={34}>
          US Military/Veteran&apos;s ID with photo
        </MenuItem>,
        <MenuItem value={'other_government_issued_photo_id_3'} key={35}>
          Tribal or Bureau of Indian Affairs with photo
        </MenuItem>,
        <MenuItem value={'passport_1'} key={33}>
          Passport or Passport Card with photo
        </MenuItem>,
        <MenuItem value={'passport_2'} key={37}>
          Foreign Passport (non-US citizen, Beneficial Owner Only)
        </MenuItem>,
        <MenuItem value={'permanent_resident_card'} key={36}>
          Permanent Resident Card with photo (also known as the Green Card or Resident Alien)
        </MenuItem>,
        <MenuItem value={'birth_certificate'} key={38}>
          US Birth Certificate
        </MenuItem>,
        <MenuItem value={'social_security_card'} key={39}>
          Social Security Card
        </MenuItem>,
        <MenuItem value={'current_utility_bill'} key={40}>
          Utility bill bearing the name and address used for opening the account including: Electric, Gas, Water, Phone, Cable, Alarm,
          HOA.
        </MenuItem>,
        <MenuItem value={'current_bank_statement'} key={41}>
          Statements including: Bank Statements, Credit Card Statements, Property Tax Bill, HUD Statement, Mortgage, HOA.
        </MenuItem>,
        <MenuItem value={'current_lease_contract'} key={42}>
          Rental/Lease contract for current residence bearing the name and address used for the opening account.
        </MenuItem>,
        <MenuItem value={'certificate_of_citizenship'} key={43}>
          Certificate of Citizenship
        </MenuItem>,
        <MenuItem value={'certificate_of_naturalization'} key={44}>
          Certificate of Naturalization
        </MenuItem>,
        <MenuItem value={'certified_copy_of_court_order'} key={45}>
          Certified Copy of Court Order (must contain full name and date of birth)
        </MenuItem>,
        <MenuItem value={'employment_authorization_card'} key={46}>
          Employment Authorization Card (with photo) issued by a U.S. federal government agency.
        </MenuItem>,
        <MenuItem value={'benefits_card'} key={47}>
          Benefits (e.g. welfare services) card with photo
        </MenuItem>,
      ];
    }
    if (legalEntityCountryCode === CountryCode.Ca) {
      // CA User
      return [
        <MenuItem value={'drivers_license'} key={48}>
          Driver’s License with photo
        </MenuItem>,
        <MenuItem value={'passport'} key={49}>
          Passport with photo
        </MenuItem>,
        <MenuItem value={'certificate_of_citizenship'} key={50}>
          Certificate of Canadian Citizenship (paper document or card but not a commemorative issue)
        </MenuItem>,
        <MenuItem value={'permanent_resident_card'} key={51}>
          Permanent Resident Card with photo
        </MenuItem>,
        <MenuItem value={'benefits_card'} key={52}>
          Canadian Health Card
        </MenuItem>,
        <MenuItem value={'birth_certificate'} key={53}>
          Canada Birth Certificate
        </MenuItem>,
        <MenuItem value={'social_insurance_number_card'} key={54}>
          Social Insurance Number Card
        </MenuItem>,
        <MenuItem value={'current_utility_bill'} key={55}>
          Utility bill bearing the name and address used for opening the account including: Electric, Gas, Water, Phone, Cable, Alarm,
          HOA.
        </MenuItem>,
        <MenuItem value={'current_bank_statement'} key={56}>
          Statements including: Bank Statements, Credit Card Statements, Property Tax Bill, HUD Statement, Mortgage, HOA.
        </MenuItem>,
        <MenuItem value={'current_lease_contract'} key={57}>
          Rental/Lease contract for current residence bearing the name and address used for the opening account.
        </MenuItem>,
      ];
    }
  }
  return [];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAdditionalVerificationFileOptions = (legalEntityCountryCode: string | undefined, isMerchant: boolean | undefined) => {
  if (legalEntityCountryCode && isMerchant !== undefined) {
    const ret = [];
    ret.push(getCorrespondingVerificationMenuItems(legalEntityCountryCode, isMerchant));
    return ret;
  }

  return [];
};
