import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Dispute, DisputeResolutionType, DisputeStatus, Maybe, PaymentRequestAllocation } from '../gql-types.generated';
import React, { useState } from 'react';
import { disputeReasonDisplay } from '../features/challenge-dispute/ChallengeDispute';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import CloseIcon from '@mui/icons-material/Close';
import TabPanel from './TabPanel';
import { NavLink } from 'react-router-dom';
import { GridRowModel, GridColDef, GridRenderCellParams, DataGridPro } from '@mui/x-data-grid-pro';
import DownloadInvoices from './DownloadInvoices';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: 0,
    },
    disputed: {
      color: theme.palette.error.main,
    },
    infoSections: {
      margin: 0,
      padding: theme.spacing(1.5),
    },
    titleText: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    tooltip: {
      cursor: 'pointer',
      marginLeft: '0.3rem',
    },
    closeIcon: {
      color: theme.palette.uxGrey.main,
    },
    closeContainer: {
      textAlign: 'end',
    },
    challengeButton: {
      marginTop: theme.spacing(1.5),
    },
    selectedTab: {
      backgroundColor: theme.palette.uxBlue.activated,
    },
    tabRoot: {
      padding: theme.spacing(0, 6),
      minWidth: '50%',
    },
    tabsIndicator: {
      height: 0,
      width: 0,
    },
    tabsRoot: {
      borderBottom: `solid 3px ${theme.palette.primary.main}`,
    },
    cell: {
      color: theme.palette.uxGrey.main,
    },
    lastCellMd: {
      width: '40%',
    },
    lastCellMdCredit: {
      width: '20%',
    },
    disputeId: {
      padding: 0,
      margin: 0,
    },
    descriptionText: {
      wordWrap: 'break-word',
      wordBreak: 'break-word',
    },
    disputeIdTooltip: {
      cursor: 'pointer',
      paddingBottom: 0,
    },
    disputeIdContent: {
      fontWeight: 'bold',
      margin: 0,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    copyIcon: {
      fontSize: '1rem',
      marginLeft: 15,
    },
    communicationTable: {
      width: '100%',
    },
    actionButtonsContainer: {
      paddingLeft: 32,
      paddingTop: theme.spacing(1),
    },
    contentSectionMd: {
      margin: 0,
      padding: theme.spacing(0.5, 1.5, 0, 2),
      marginRight: 48,
    },
    contentSectionSm: {
      margin: 0,
      padding: theme.spacing(0.5, 1.5, 0, 2),
      marginRight: 36,
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
      '& .MuiDataGrid-cell': {
        fontSize: '0.875rem',
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  }),
);

const disputeResolutionDisplay = [
  { name: 'WON', display: 'Won' },
  { name: 'LOST', display: 'Lost' },
  { name: 'PENDING', display: 'Pending' },
];

interface DisputeInformationProps {
  disputedRecord: Dispute;
  disputeState: string;
  handlePaymentView?: (paymentId?: string | null) => void;
  handleRequestView?: (paymentRequestId?: string | null) => void;
  isDialog: boolean;
  closeDialog?: () => void;
  concede?: () => void;
  isCreditMemoEnabled: boolean;
  isUserAdmin: boolean;
}

const DisputeInformation: React.FC<DisputeInformationProps> = props => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('lg'));
  const {
    disputedRecord,
    disputeState,
    handlePaymentView,
    isDialog,
    closeDialog,
    concede,
    handleRequestView,
    isCreditMemoEnabled,
    isUserAdmin,
  } = props;
  if (disputeState === 'UNKNOWN') {
    return <></>;
  }

  const [disputeReferenceCopied, setDisputeReferenceCopied] = useState(false);
  const [tabValue] = useState(0);

  const reason = disputeReasonDisplay.find(item => {
    return item.name === disputedRecord.reason;
  })?.display;
  const fullReason = `${reason}. ${disputedRecord.reasonMessage}`;

  const disputeResolution = disputeResolutionDisplay.find(item => {
    return item.name === disputedRecord.resolution;
  })?.display;

  const resolutionString = disputeResolution ? `${disputeResolution}` : 'Pending';
  const contentSectionClass = matchesSm ? classes.contentSectionSm : classes.contentSectionMd;

  const payerContact = () => {
    let payerEmail: string | undefined | null;
    if (disputedRecord.payment?.paymentRequestAllocation) {
      payerEmail = disputedRecord.payment.paymentRequestAllocation.find(record => record.paymentRequest?.communications[0]?.email)
        ?.paymentRequest?.communications[0]?.email;
    }
    return payerEmail || '-- --';
  };

  const calculateDaysLeft = (createdAt: string) => {
    const dueDate = new Date(createdAt);
    const today = new Date();
    const difference = today.valueOf() - dueDate.valueOf();
    if (difference < 0) {
      return '0 Days';
    }
    const days = Math.floor(difference / 86400000);
    if (days > 7) {
      return 'No Days';
    }
    const remaining = 7 - days;
    return remaining === 1 ? '1 Day' : `${remaining} Days`;
  };

  const canChallenge = (createdAt: string) => {
    const dueDate = new Date(createdAt);
    const today = new Date();
    const difference = today.valueOf() - dueDate.valueOf();
    if (difference < 0) {
      return true;
    }
    const days = Math.floor(difference / 86400000);
    return days > 7;
  };
  const paymentRequestAllocation = disputedRecord.payment?.paymentRequestAllocation;
  const getPaymentAllocationRows = () => {
    return paymentRequestAllocation?.map((row: Maybe<PaymentRequestAllocation>) => {
      const paymentRequest = row?.paymentRequest;
      const snapshot = paymentRequest?.payments?.find(data => data?.id === disputedRecord?.payment?.id);
      const node = snapshot;
      if (!node) {
        return {} as GridRowModel;
      }
      return {
        _raw: node,
        id: paymentRequest?.id,
        referenceNumber: paymentRequest?.referenceNumber?.trim(),
        invoiceLink: paymentRequest?.invoiceLink,
        invoiceLinks: paymentRequest?.invoiceLinks,
        paid: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: disputedRecord.payment?.currency || 'USD',
          currencyDisplay: 'symbol',
        }).format((snapshot?.amount || 0) / 100),
        creditApplied: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: disputedRecord.payment?.currency || 'USD',
          currencyDisplay: 'symbol',
        }).format((snapshot?.creditAmount || 0) / 100),
        total: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: disputedRecord.payment?.currency || 'USD',
          currencyDisplay: 'symbol',
        }).format(((snapshot?.amount || 0) + (snapshot?.creditAmount || 0)) / 100),
      } as GridRowModel;
    }) as GridRowModel[];
  };
  // Column titles for mapping and sorting
  const paymentColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'referenceNumber',
      sortable: false,
      headerName: 'Reference #',
      flex: 0.15,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        // eslint-disable-next-line no-underscore-dangle
        const { row } = params;
        return (
          <Link
            underline={'hover'}
            href="#"
            onClick={() => {
              if (handleRequestView) handleRequestView(row?.id);
            }}
            aria-label={`open payment request details${
              row?.referenceNumber ? ` reference ending with ${row?.referenceNumber.slice(-4)}` : ''
            }`}
          >
            {row?.referenceNumber && row?.referenceNumber?.length > 9 ? `***${row?.referenceNumber?.slice(-9)}` : row?.referenceNumber}
          </Link>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paid',
      sortable: false,
      headerName: 'Paid',
      hide: !isCreditMemoEnabled,
      headerAlign: 'right',
      align: 'right',
      flex: 0.05,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'creditApplied',
      sortable: false,
      headerName: 'Credit Applied',
      hide: !isCreditMemoEnabled,
      headerAlign: 'right',
      align: 'right',
      flex: 0.2,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'total',
      sortable: false,
      headerName: 'Total',
      headerAlign: 'right',
      align: 'right',
      flex: 0.2,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      sortable: false,
      field: 'action',
      headerName: 'Download Invoice',
      headerAlign: 'center',
      align: 'center',
      flex: 0.18,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <DownloadInvoices isIconButton={true} invoiceUrl={row?.invoiceLink} invoicesUrlList={row?.invoiceLinks} originTop={true} />
        );
      },
    },
  ];

  return (
    <Box className={classes.dialogContent} data-cy="dispute-information">
      <Box className={classes.infoSections}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="subtitle2" id="modal-title">
              PAYMENT DISPUTE
            </Typography>
            <Typography className={classes.titleText} id="modal-description">
              {disputeState === 'DISPUTED' && `${calculateDaysLeft(disputedRecord.createdAt)} Left To Challenge This Dispute`}
              {disputeState === 'CONCEDED' && 'You Conceded This Dispute'}
              {disputedRecord.status !== DisputeStatus.Resolved && disputeState === 'CHALLENGED' && 'Challenged Dispute'}
              {disputedRecord.status === DisputeStatus.Resolved &&
                disputeState === 'CHALLENGED' &&
                `This Dispute Was Resolved In ${disputedRecord.resolution === DisputeResolutionType.Won ? 'Your' : ''}${
                  disputedRecord.resolution === DisputeResolutionType.Lost ? "The Customer's" : ''
                } Favor`}
            </Typography>
            <Typography className={classes.disputeId}>
              <Tooltip
                className={classes.disputeIdTooltip}
                title={`${disputeReferenceCopied ? 'Copied' : 'Copy'} to clipboard`}
                placement="bottom-start"
              >
                <Link
                  underline={'hover'}
                  className={classes.disputeIdContent}
                  color="primary"
                  onClick={() => {
                    setDisputeReferenceCopied(true);
                    navigator.clipboard.writeText(disputedRecord.id || '');
                  }}
                  onMouseEnter={() => {
                    if (disputeReferenceCopied) setDisputeReferenceCopied(false);
                  }}
                  onFocus={() => {
                    if (disputeReferenceCopied) setDisputeReferenceCopied(false);
                  }}
                  href="#"
                  data-cy="dispute-id-copy"
                >
                  {disputedRecord.id}
                  <CopyIcon className={classes.copyIcon} />
                </Link>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.closeContainer}>
            {isDialog && closeDialog && (
              <IconButton onClick={closeDialog} aria-label="close" title="close" data-cy="close-dispute" size="large">
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box className={isDialog ? contentSectionClass : classes.infoSections}>
        <Grid container>
          <Grid item container xs={12} md={isDialog ? 10 : 12}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  Payment ID
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant={'body1'}>
                  {disputedRecord?.payment?.id ? (
                    <Link
                      underline={'hover'}
                      href="#"
                      onClick={() => {
                        if (handlePaymentView) handlePaymentView(disputedRecord.owner?.paymentId);
                      }}
                      aria-label={`open payment details id ending with ${disputedRecord?.payment?.id.slice(-4)}`}
                    >
                      ***{disputedRecord?.payment?.id.slice(-9)}
                    </Link>
                  ) : (
                    '-- --'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  Dispute Amount
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant={'body1'}>
                  {typeof disputedRecord.amount === 'number'
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: disputedRecord.currency || 'USD',
                        currencyDisplay: 'symbol',
                      }).format(disputedRecord.amount / 100)
                    : null}
                </Typography>
              </Grid>
              {disputedRecord?.payment?.description ? (
                <>
                  <Grid item xs={6} sm={4}>
                    <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <Typography className={classes.descriptionText}>{disputedRecord?.payment?.description}</Typography>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  Capture Type
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant={'body1'}>{disputedRecord?.payment?.immediateCapture ? 'Immediate' : 'Authorization'}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  Date Paid
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant={'body1'}>
                  {new Date(disputedRecord?.payment?.attemptTimestamp || disputedRecord?.payment?.createdAt).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  Payer Contact
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant={'body1'}>{payerContact()}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  {disputeState === 'DISPUTED' ? 'Chargeback Code' : 'Resolution'}
                </Typography>
              </Grid>
              {disputeState === 'CONCEDED' ? (
                <Grid item xs={6} sm={8}>
                  <Typography variant={'body1'}>{`Conceded: ${disputedRecord.concedeMessage}`}</Typography>
                </Grid>
              ) : (
                <Grid item xs={6} sm={8}>
                  {disputeState === 'DISPUTED' ? (
                    disputedRecord?.reasonDetails?.map((detail, index) => {
                      return (
                        <Typography key={`${detail.code}-${index}`} variant={'body1'}>
                          {detail.message}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography variant={'body1'}>{`Challenged: ${resolutionString}`}</Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  Dispute Reason
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant={'body1'}>{fullReason}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography color={theme.palette.uxGrey.main} variant={'body1'}>
                  Fees
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant={'body1'} className={classes.disputed}>
                  A $25 non-refundable fee has been applied to your account
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {isDialog && disputedRecord.status === DisputeStatus.AwaitingMerchantResponse && (
            <Grid item xs={12} md={2} className={matchesSm ? classes.actionButtonsContainer : ''}>
              <Button
                onClick={() => {
                  if (concede) concede();
                }}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={!isUserAdmin}
                data-cy="concede"
              >
                Concede
              </Button>
              <Button
                disabled={!isUserAdmin || canChallenge(disputedRecord.createdAt)}
                fullWidth
                component={NavLink}
                to="/challenge-dispute"
                variant="outlined"
                color="primary"
                aria-label="challenge dispute"
                data-cy="challenge"
                className={classes.challengeButton}
              >
                Challenge
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      {disputedRecord.payment?.paymentRequestAllocation && disputedRecord.payment?.paymentRequestAllocation.length > 0 && (
        <Box className={classes.infoSections}>
          <Paper elevation={0}>
            <Tabs
              indicatorColor={'primary'}
              textColor={'primary'}
              value={tabValue}
              classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
            >
              <Tab
                classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
                label="PAYMENT REQUESTS"
                id="payment-requests-tab"
                data-cy="payment-requests-tab"
              />
            </Tabs>
            <TabPanel value={tabValue} index={0} data-cy="payment-requests-panel">
              <DataGridPro
                rowHeight={58}
                aria-label="payment requests table"
                hideFooterPagination
                hideFooter
                hideFooterSelectedRowCount
                className={classes.dataGrid}
                rows={getPaymentAllocationRows()}
                columns={paymentColumns}
                autoHeight
              />
            </TabPanel>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default DisputeInformation;
