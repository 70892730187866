type Month = {
  label: string;
  number: number;
};

export const allMonths: Month[] = [
  { label: 'January', number: 0 },
  { label: 'February', number: 1 },
  { label: 'March', number: 2 },
  { label: 'April', number: 3 },
  { label: 'May', number: 4 },
  { label: 'June', number: 5 },
  { label: 'July', number: 6 },
  { label: 'August', number: 7 },
  { label: 'September', number: 8 },
  { label: 'October', number: 9 },
  { label: 'November', number: 10 },
  { label: 'December', number: 11 },
];
