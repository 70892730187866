import { Button, Grid, Tooltip, Theme, Typography, SelectChangeEvent } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, ChangeEvent } from 'react';
import {
  PayoutSettings as PayoutSettingsType,
  AppRole,
  BankAccountType,
  AccountPayoutsSettingsStatus,
  PayoutInterval,
} from '../gql-types.generated';
import EditIcon from '@mui/icons-material/Edit';
import GridItem from './GridItem';
import PayoutMethod from './PayoutMethod';
import { PayoutMethodData } from '../features/account-management/AccountManagement';
import PayoutFrequency from './PayoutFrequency';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    addButton: {
      marginTop: -5,
    },
    payoutItemValue: {
      marginTop: 1,
      marginLeft: 4,
    },
    payoutItemDelete: {
      marginTop: -5,
    },
    tooltip: {
      fontSize: '10pt',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
  }),
);

interface PayoutSettingsProps {
  defaultCurrency?: string | null;
  handleAddPayoutMethod: () => void;
  handleClosePayoutMethod: () => void;
  handleCloseFrequency: () => void;
  handleEditPayoutMethod: () => void;
  handleEditFrequency: () => void;
  handleDailyFrequency: () => void;
  handleSaveFrequency: (interval: PayoutInterval) => void;
  handleRoutingTransitNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleAccountNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleInstitutionNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleAccountTypeChange: (event: SelectChangeEvent<string>) => void;
  isPayoutMethodValid: () => boolean;
  modifyPayoutVisible: boolean | undefined;
  modifyFrequencyVisible: boolean | undefined;
  payoutsEnabled: boolean;
  payoutMethodData: PayoutMethodData;
  payoutSettings?: PayoutSettingsType;
  submitPayoutMethod: (interval?: PayoutInterval) => void;
  viewerAppRole?: AppRole;
  requiredSettings: string[];
  isAddingPayoutMethod: boolean;
  handleSetAddingPayoutMethod: (isAdding: boolean) => void;
}
const PayoutSettings: FC<PayoutSettingsProps> = props => {
  const classes = useStyles();
  const {
    defaultCurrency,
    viewerAppRole,
    submitPayoutMethod,
    payoutSettings,
    payoutMethodData,
    isPayoutMethodValid,
    handleAddPayoutMethod,
    handleEditPayoutMethod,
    handleEditFrequency,
    handleClosePayoutMethod,
    handleCloseFrequency,
    handleSaveFrequency,
    handleDailyFrequency,
    modifyFrequencyVisible,
    modifyPayoutVisible,
    handleRoutingTransitNumberChange,
    handleInstitutionNumberChange,
    handleAccountNumberChange,
    handleAccountTypeChange,
    requiredSettings,
    isAddingPayoutMethod,
    handleSetAddingPayoutMethod,
  } = props;
  const bankAccountLabel = 'Bank Account';
  const frequencyLabel = 'Frequency';
  const canUserEdit = viewerAppRole === AppRole.Admin;
  // No payout settings may mean the data isn't retrieved yet. Calculate no account boolean based on the account type returned.
  const hasNoAccounts = payoutSettings?.accountType === BankAccountType.Unknown;
  const isProcessing = payoutSettings?.status === AccountPayoutsSettingsStatus.Processing;
  const needsMethod = !!requiredSettings.find(item => item.includes('method'));
  const needsPeriod = !!requiredSettings.find(item => item.includes('period'));
  return (
    <Grid container className={classes.root} data-cy="payout-settings-section" role="region" aria-label="Payout Settings">
      <Typography className={classes.header} variant="subtitle">
        Payout Settings
      </Typography>
      {isProcessing && (
        <GridItem
          name={bankAccountLabel}
          alert={needsMethod}
          value={
            <Typography className={classes.payoutItemValue} variant="body1">
              Payout account processing
            </Typography>
          }
        />
      )}
      {!isProcessing &&
        payoutSettings &&
        payoutSettings.accountLastFour &&
        payoutSettings.accountType !== BankAccountType.Unknown &&
        !modifyPayoutVisible &&
        !modifyFrequencyVisible && (
          <GridItem
            key={payoutSettings.accountLastFour}
            name={bankAccountLabel}
            alert={needsMethod}
            value={
              <Grid container>
                <Grid item xs={9}>
                  <Typography variant="body1" className={classes.payoutItemValue}>
                    {payoutSettings.accountType} ending with {payoutSettings.accountLastFour}
                  </Typography>
                </Grid>
                <Grid item container xs={3} sm={3}>
                  <Grid item>
                    {canUserEdit && (
                      <Tooltip
                        title={<span className={classes.tooltip}>Edit</span>}
                        PopperProps={{
                          popperOptions: {
                            placement: 'right-end',
                          },
                        }}
                      >
                        <Button
                          className={classes.payoutItemDelete}
                          startIcon={<EditIcon />}
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() => handleEditPayoutMethod()}
                          aria-label="edit bank account"
                        >
                          EDIT
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        )}
      {!isProcessing &&
        !canUserEdit &&
        !modifyFrequencyVisible &&
        !modifyPayoutVisible &&
        (!payoutSettings || payoutSettings.accountType === BankAccountType.Unknown) && (
          <GridItem
            name={bankAccountLabel}
            alert={needsMethod}
            value={
              <Typography className={classes.payoutItemValue} variant="body1">
                Information must be added by an admin.
              </Typography>
            }
          />
        )}
      {!isProcessing && canUserEdit && !modifyFrequencyVisible && !modifyPayoutVisible && hasNoAccounts && (
        <GridItem
          name={bankAccountLabel}
          alert={needsMethod}
          value={
            <Button
              onClick={() => {
                handleAddPayoutMethod();
                handleSetAddingPayoutMethod(true);
              }}
              color="primary"
              variant="outlined"
              className={classes.addButton}
              data-cy="add-account"
            >
              ADD ACCOUNT
            </Button>
          }
        />
      )}
      {canUserEdit && !modifyFrequencyVisible && modifyPayoutVisible && (
        <GridItem
          name={bankAccountLabel}
          alert={needsMethod}
          value={
            <PayoutMethod
              defaultCurrency={defaultCurrency}
              onAccountNumberChange={handleAccountNumberChange}
              onInstitutionNumberChange={handleInstitutionNumberChange}
              onRoutingTransitNumberChange={handleRoutingTransitNumberChange}
              onAccountTypeChange={handleAccountTypeChange}
              isPayoutMethodValid={isPayoutMethodValid}
              close={handleClosePayoutMethod}
              submit={submitPayoutMethod}
              data={payoutMethodData}
              isAddingPayoutMethod={isAddingPayoutMethod}
            />
          }
        />
      )}
      {isProcessing ||
        (payoutSettings &&
          payoutSettings.accountLastFour &&
          payoutSettings.accountType !== BankAccountType.Unknown &&
          !modifyPayoutVisible &&
          !modifyFrequencyVisible &&
          canUserEdit &&
          !modifyPayoutVisible &&
          !modifyFrequencyVisible && (
            <GridItem
              key={'frequency'}
              name={frequencyLabel}
              alert={needsPeriod}
              value={
                <Grid container>
                  <Grid item xs={9} sm={9}>
                    <Typography variant="body1" className={classes.payoutItemValue} data-cy="frequency-value">
                      {payoutSettings?.schedule?.interval}
                    </Typography>
                  </Grid>
                  {payoutSettings?.schedule?.interval !== PayoutInterval.Daily && (
                    <Grid item container xs={3} sm={3}>
                      <Grid item>
                        <Tooltip
                          title={<span className={classes.tooltip}>Edit</span>}
                          PopperProps={{
                            popperOptions: {
                              placement: 'right-end',
                            },
                          }}
                        >
                          <Button
                            className={classes.payoutItemDelete}
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => handleEditFrequency()}
                            data-cy="frequency-edit-button"
                            startIcon={<EditIcon />}
                            aria-label="edit frequency"
                          >
                            EDIT
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              }
            />
          ))}
      {canUserEdit &&
        !modifyPayoutVisible &&
        modifyFrequencyVisible &&
        payoutSettings?.schedule?.interval !== PayoutInterval.Daily && (
          <GridItem
            name={frequencyLabel}
            alert={needsPeriod}
            value={
              <PayoutFrequency
                close={handleCloseFrequency}
                submit={handleSaveFrequency}
                submitDaily={handleDailyFrequency}
                interval={payoutSettings?.schedule?.interval || undefined}
              />
            }
          />
        )}
    </Grid>
  );
};

export default PayoutSettings;
