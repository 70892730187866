import { Box, Button, IconButton, Select, TextField, Theme, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef } from 'react';
import { SupportingDoc, documentationTypes } from '../features/challenge-dispute/ChallengeDispute';
import LoadingMask from './LoadingMask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'start',
      padding: theme.spacing(3, 4),
      borderTop: theme.palette.uxGrey.border,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3),
      },
    },
    instructions: {
      fontSize: '17px',
      marginBottom: theme.spacing(2),
    },
    documentBox: {
      border: '1px solid #00000033',
      padding: theme.spacing(1.5),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    documentSelect: {
      width: '60%',
    },
    documentSelectArrow: {
      top: 0,
      right: 0,
      padding: theme.spacing(0, 1),
      borderLeft: '1px solid #00000033',
      height: '100%',
      width: theme.spacing(5.5),
    },
    documentSelectPadding: {
      padding: theme.spacing(1.5),
      '&$outlined': {
        paddingRight: `${theme.spacing(6)} !important`,
      },
    },
    outlined: {}, // Needed for above fix
    fileBox: {
      border: '1px solid #00000033',
      borderBottom: 'none',
      padding: theme.spacing(0.5, 1.5),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-evenly',
        padding: theme.spacing(0.5),
      },
    },
    loadingBoxSingle: {
      border: '1px solid #00000033',
      borderBottom: 'none',
      position: 'relative',
      height: '62px',
    },
    loadingBox: {
      position: 'relative',
    },
    fileActionBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
    },
    fileText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    explanationBox: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(1.5),
    },
    buttonHouse: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
    },
    cancelButton: {
      marginRight: theme.spacing(2),
    },
    hidden: {
      display: 'none',
    },
  }),
);

interface SubmitChallengeDisputeProps {
  supportingDocuments: SupportingDoc[];
  documentType: string;
  documentsError: boolean;
  handleSelectChange: (value: string) => void;
  explanation: string;
  explanationError: boolean;
  handleTextfieldChange: (explanation: string) => void;
  submitClicked: boolean;
  cancel: () => void;
  submit: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveClick: (index: number, token: string) => void;
  showMask: boolean;
}
const SubmitChallengeDispute: React.FC<SubmitChallengeDisputeProps> = props => {
  const classes = useStyles();
  const {
    supportingDocuments,
    documentType,
    documentsError,
    handleSelectChange,
    explanation,
    explanationError,
    handleTextfieldChange,
    submitClicked,
    cancel,
    submit,
    handleFileChange,
    handleRemoveClick,
    showMask,
  } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleUploadClick = () => {
    setTimeout(() => {
      if (fileRef && fileRef.current) {
        fileRef.current.click();
      }
    }, 100);
  };

  const bytesConverter = (bytes: number) => {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const decimals = 2;
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
  };

  return (
    <Box className={classes.content}>
      <Typography className={classes.instructions} data-cy="documentation-instructions">
        Please upload acceptable supporting documentation. The file must be a .png, .jpg, or .pdf smaller than 10MB.{' '}
        <strong>You may attach up to 5 documents</strong>
      </Typography>
      {supportingDocuments && (
        <span
          className={showMask && supportingDocuments.length === 0 ? classes.loadingBoxSingle : classes.loadingBox}
          data-cy="file-box"
        >
          <LoadingMask loading={showMask} />
          {supportingDocuments.map((entry, index) => {
            if (!entry) {
              return <></>;
            }
            const { file } = entry;
            if (!file) {
              return <></>;
            }
            const size = bytesConverter(file.size);
            return (
              <Grid
                container
                key={`${file.name}`}
                className={classes.fileBox}
                data-cy={`${file.name.substring(0, file.name.length - 4)}-display`}
              >
                <Grid item xs={6} sm={8}>
                  <Typography className={classes.fileText}>{file.name}</Typography>
                  <Typography className={classes.fileText} variant="body1" color="textSecondary">
                    {entry.docType}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} className={classes.fileActionBox}>
                  <Typography variant={matches ? 'body1' : undefined} color="textSecondary">
                    {size}
                  </Typography>
                  <IconButton disabled={submitClicked} onClick={() => handleRemoveClick(index, entry.token)} edge="end" size="large">
                    <CloseIcon style={{ fontSize: matches ? '20px' : '30px' }} />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </span>
      )}
      <TextField
        className={classes.hidden}
        onChange={handleFileChange}
        type="file"
        inputProps={{
          accept: '.pdf,.png,.jpg',
          multiple: true,
        }}
        inputRef={fileRef}
        data-cy="hidden-file-input"
      />
      <Box className={classes.documentBox}>
        <Select
          variant="outlined"
          className={classes.documentSelect}
          disabled={submitClicked}
          required
          error={documentsError}
          value={documentType}
          onChange={event => handleSelectChange(event.target.value as string)}
          native
          classes={{
            select: classes.documentSelectPadding,
            iconOutlined: classes.documentSelectArrow,
            outlined: classes.outlined,
          }}
          data-cy="documentation-select"
        >
          <option value="" style={{ display: 'none' }}>
            Select an acceptable supporting document
          </option>
          {documentationTypes.map(doc => {
            return (
              <option key={doc.display} value={doc.type}>
                {doc.display}
              </option>
            );
          })}
        </Select>
        <Button
          variant="outlined"
          color="primary"
          disabled={submitClicked || documentType === ''}
          onClick={handleUploadClick}
          data-cy="documentation-upload"
        >
          Upload
        </Button>
      </Box>
      <Box className={classes.explanationBox}>
        <Typography style={{ fontSize: '17px', marginBottom: '8px' }}>Please provide an explanation for the challenge.</Typography>
        <TextField
          variant="outlined"
          disabled={submitClicked}
          multiline
          fullWidth
          required
          error={explanationError}
          placeholder="Explanation"
          inputProps={{ 'aria-label': 'challange explanation' }}
          rows={7}
          value={explanation}
          onChange={event => handleTextfieldChange(event.target.value)}
          data-cy="challenge-explanation"
        />
        <Box className={classes.buttonHouse}>
          <Button
            onClick={cancel}
            disabled={submitClicked}
            className={classes.cancelButton}
            variant="text"
            color="primary"
            data-cy="challenge-cancel"
          >
            Cancel
          </Button>
          <Button onClick={submit} disabled={submitClicked} variant="contained" color="primary" data-cy="challenge-submit">
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SubmitChallengeDispute;
