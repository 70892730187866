import { AUTH_MODE, MSAL_AUTHORITY, MSAL_CLIENT_ID, MSAL_READ_SCOPE, MSAL_WRITE_SCOPE } from './Constants';
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { LogLevel, Logger } from 'msal';

const logger = new Logger(
  (_logLevel, message, _containsPii) => {
    console.log('[MSAL]', message);
  },
  {
    level: LogLevel.Verbose,
    piiLoggingEnabled: false,
  },
);

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
// We don't want to instantiate the provider if we are not using it.
export const b2cAuthProvider =
  AUTH_MODE === 'b2c'
    ? new MsalAuthProvider(
        {
          auth: {
            authority: MSAL_AUTHORITY,
            clientId: MSAL_CLIENT_ID,
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: false,
            // After being redirected to the "redirectUri" page, should user
            // be redirected back to the Url where their login originated from?
            navigateToLoginRequestUrl: false,
          },
          // Enable logging of MSAL events for easier troubleshooting.
          // This should be disabled in production builds.
          system: {
            logger,
          },
          cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
          },
        },
        {
          scopes: [MSAL_READ_SCOPE, MSAL_WRITE_SCOPE],
        },
        {
          loginType: LoginType.Redirect,
          // When a token is refreshed it will be done by loading a page in an iframe.
          // Rather than reloading the same page, we can point to an empty html file which will prevent
          // site resources from being loaded twice.
          tokenRefreshUri: `${window.location.origin}/auth.html`,
        },
      )
    : undefined;
