import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DisputeConnection, DisputeOrder, DisputesQuery } from '../gql-types.generated';
import { getStandardHeaders } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_DISPUTE_CONNECTION = gql`
  query disputes($after: String, $before: String, $first: Int, $last: Int, $orderBy: DisputeOrder!) {
    disputes(after: $after, before: $before, first: $first, last: $last, orderBy: $orderBy) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        amount
        status
        disputeType
        currency
        reason
        reasonMessage
        reasonDetails {
          code
          message
        }
        createdAt
        id
        resolution
        resolvedAt
        concedeMessage
        owner {
          paymentId
          tenantId
        }
        payment {
          id
          attemptTimestamp
          createdAt
          immediateCapture
          description
          paymentRequestAllocation {
            amount
            paymentRequest {
              id
              amount
              referenceNumber
              invoiceLink
              invoiceLinks
              communications {
                email
              }
              payments {
                id
                amount
                creditAmount
              }
            }
          }
          paymentRequest {
            id
            amount
            referenceNumber
            invoiceLink
            communications {
              email
            }
            payments {
              id
              amount
              creditAmount
            }
          }
        }
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const queryDisputeConnection = async (
  client: ApolloClient<NormalizedCacheObject>,
  after: string | undefined,
  before: string | undefined,
  first: number | undefined,
  last: number | undefined,
  orderBy: DisputeOrder,
): Promise<DisputeConnection | undefined> => {
  const headers = await getStandardHeaders();
  const { data, errors } = await client.query<DisputesQuery>({
    variables: { after, before, first, last, orderBy },
    query: QUERY_DISPUTE_CONNECTION,
    context: {
      headers,
    },
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }
  return data?.disputes as DisputeConnection;
};
