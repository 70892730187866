import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** Payment methods enabled for this tenant account */
export enum AcceptedPaymentMethod {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  PaymentBank = 'PAYMENT_BANK',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type AccountBalances = {
  __typename?: 'AccountBalances';
  /**
   * Available balance in the given currency
   * @deprecated Use new field currentBalance
   */
  balance?: Maybe<Scalars['Float']>;
  /** Available balance in the given currency */
  currentBalance?: Maybe<Money>;
  /** Reserved balance */
  currentReserve?: Maybe<Money>;
  /**
   * Pending incoming balance
   * @deprecated Use new field incomingPendingBalance
   */
  incomingPending?: Maybe<Scalars['Float']>;
  /** Pending incoming balance */
  incomingPendingBalance?: Maybe<Money>;
  /**
   * Pending outgoing balance
   * @deprecated Use new field outgoingPendingBalance
   */
  outgoingPending?: Maybe<Scalars['Float']>;
  /** Pending outgoing balance */
  outgoingPendingBalance?: Maybe<Money>;
  /**
   * Reserved balance
   * @deprecated Use new field currentReserve
   */
  reserve?: Maybe<Scalars['Float']>;
  /** The timestamp when the balances where last updated */
  updatedTimestamp?: Maybe<Scalars['DateTime']>;
};

export type AccountOptions = {
  __typename?: 'AccountOptions';
  /** Payment options */
  payments?: Maybe<AccountPaymentOptions>;
};

export type AccountPaymentOptions = {
  __typename?: 'AccountPaymentOptions';
  /** Indicates whether payer needs to provide a reason for partial payment. */
  requirePartialPaymentReason?: Maybe<Scalars['Boolean']>;
};

/** Values for the state of updating payout account settings. */
export enum AccountPayoutsSettingsStatus {
  Processing = 'PROCESSING',
  ProcessingComplete = 'PROCESSING_COMPLETE',
  Unknown = 'UNKNOWN'
}

export type AccountPersonalVerification = {
  __typename?: 'AccountPersonalVerification';
  /** Documents showing address, either a passport, local ID card, or utility bill from a well-known utility company. */
  additionalDocuments: Array<Maybe<AccountVerificationDocument>>;
  /** Identifying documents for the person. */
  documents: Array<Maybe<AccountVerificationDocument>>;
  /** The state of verification for the person. */
  status: AccountPersonalVerificationStatus;
};

/** Values for the state of verification for a person. */
export enum AccountPersonalVerificationStatus {
  Pending = 'PENDING',
  Unspecified = 'UNSPECIFIED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type AccountRelationship = {
  __typename?: 'AccountRelationship';
  /** How the person is involved with the account, controller, additional reps etc... */
  involvement?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  percentOwnership?: Maybe<Scalars['Int']>;
  primaryAccountHolder?: Maybe<Scalars['Boolean']>;
  role: AppRole;
  title?: Maybe<Scalars['String']>;
};

export type AccountRequirements = {
  __typename?: 'AccountRequirements';
  currentDeadline?: Maybe<Scalars['DateTime']>;
  currentlyDue?: Maybe<Array<Scalars['String']>>;
  disabledReason?: Maybe<Scalars['String']>;
  /** Indicates which fields need to be updated. */
  errantFields?: Maybe<Scalars['JSONObject']>;
  errors?: Maybe<Array<Scalars['String']>>;
  eventuallyDue?: Maybe<Array<Scalars['String']>>;
  pastDue?: Maybe<Array<Scalars['String']>>;
  pendingVerification?: Maybe<Array<Scalars['String']>>;
};

export type AccountSettings = {
  __typename?: 'AccountSettings';
  /** Settings specific to the account’s payouts. */
  accountPayouts?: Maybe<PayoutSettings>;
  /** Settings specific to ACH payments on the account. */
  achPayments?: Maybe<PaymentSettings>;
  /** Settings specific to card charging on the account. */
  cardPayments?: Maybe<PaymentSettings>;
  /** The convenience fees. */
  convenienceFees?: Maybe<ConvenienceFees>;
  /** The custom email settings. */
  customEmailSettings?: Maybe<TenantAccountEmailSettings>;
  /** Set of feature flags enabled/disabled for the account. */
  features?: Maybe<Features>;
  /** The integration settings. */
  integrationSettings?: Maybe<IntegrationSettings>;
  /** Indicates if the tenant is an ACS tenant. */
  isAcsTenant: Scalars['Boolean'];
  /** Set of miscellaneous options on the account */
  options?: Maybe<AccountOptions>;
  /** The payment reminder settings. */
  paymentReminders?: Maybe<PaymentReminderSettings>;
  /** The capabilities supported by the tenant. */
  supportedCapabilities: TenantSupportedCapabilities;
  /** Supported payment methods enabled for the tenant. */
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
};

export type AccountVerificationDocument = {
  __typename?: 'AccountVerificationDocument';
  customData?: Maybe<Scalars['JSONObject']>;
  documentId?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  status?: Maybe<DocumentStatus>;
  type?: Maybe<DocumentType>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  /** The country in which the address resides. This should be an ISO 3166-1 alpha-2 country code. */
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** Input for an address */
export type AddressInputType = {
  /** Address city. (Constraints: Maximum Length: 30) */
  city?: Maybe<Scalars['String']>;
  /** the country code in ISO format. (Constraints: Length: 2) */
  country: Scalars['String'];
  /** Addresss line 1. (Constraints: Maximum Length: 60) */
  line1?: Maybe<Scalars['String']>;
  /** Address line 2. (Constraints: Maximum Length: 60) */
  line2?: Maybe<Scalars['String']>;
  /** the address postal code. (Constraints: Maximum Length: 14) */
  postalCode: Scalars['String'];
  /** Address region. (Constraints: Maximum Length: 30) */
  region?: Maybe<Scalars['String']>;
};

/** The type of allocation for the customer allocation */
export enum AllocationType {
  Customer = 'CUSTOMER',
  Person = 'PERSON'
}

/** Input data for filtering by amount range */
export type AmountRange = {
  end?: Maybe<Scalars['Float']>;
  start: Scalars['Float'];
};

export enum AppRole {
  Admin = 'ADMIN',
  AnonymousPayer = 'ANONYMOUS_PAYER',
  ApiConsumer = 'API_CONSUMER',
  CheckoutApiConsumer = 'CHECKOUT_API_CONSUMER',
  Editor = 'EDITOR',
  JsLibrary = 'JS_LIBRARY',
  LimitedPayer = 'LIMITED_PAYER',
  LimitedUser = 'LIMITED_USER',
  Payer = 'PAYER',
  Reader = 'READER',
  Unallocated = 'UNALLOCATED'
}

/** Aptean products. */
export enum ApteanProduct {
  Apprise = 'APPRISE',
  DynamicsFb = 'DYNAMICS_FB',
  M2M = 'M2M',
  Respond = 'RESPOND',
  Ross = 'ROSS',
  Unknown = 'UNKNOWN'
}

/** Input data for attaching a payment method */
export type AttachPaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
  /** The id of the person, tenant or customer to attach the payment method to. */
  resourceId: Scalars['ID'];
  /** Indicates if this payment method is to be shared with merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
};

/** Status response for mutation for creating a payment method. */
export type AttachPaymentMethodStatus = {
  __typename?: 'AttachPaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated This field is deprecated */
  resource?: Maybe<PaymentMethodOwnerResourceUnion>;
};

/** The type of bank account, checking or savings. */
export enum BankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
  Unknown = 'UNKNOWN'
}

/** Input data for Bank */
export type BankDetailsInput = {
  /** last four digits of account number */
  lastFour?: Maybe<Scalars['String']>;
};

/** The business profile contains key details about the business, associated with the tenant account */
export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  description?: Maybe<Scalars['String']>;
  /** High resolution logo image url */
  highResLogoUrl?: Maybe<Scalars['String']>;
  /** The url for the merchant logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** The merchant classification code */
  mcc?: Maybe<Scalars['String']>;
  /** The name of the business profile */
  name: Scalars['String'];
  /** The support address */
  supportAddress?: Maybe<Address>;
  /** The email address of the merchants support team */
  supportEmail?: Maybe<Scalars['String']>;
  /** The phone number of the merchants support team */
  supportPhone?: Maybe<Phone>;
  /** The link to the merchants support website */
  supportUrl?: Maybe<Scalars['String']>;
  /** The url for the merchants website */
  url?: Maybe<Scalars['String']>;
};

/** The categories identifying the legal structure of the company or legal entity. */
export enum BusinessStructure {
  Corporation = 'CORPORATION',
  GovernmentAgency = 'GOVERNMENT_AGENCY',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  NonprofitCorporation = 'NONPROFIT_CORPORATION',
  Partnership = 'PARTNERSHIP',
  PersonalTrust = 'PERSONAL_TRUST',
  SoleProprietor = 'SOLE_PROPRIETOR',
  StatutoryTrust = 'STATUTORY_TRUST',
  UnincorporatedAssociation = 'UNINCORPORATED_ASSOCIATION',
  Unknown = 'UNKNOWN'
}

/** The type of business for the legal entity. */
export enum BusinessType {
  Company = 'COMPANY',
  GovtEntity = 'GOVT_ENTITY',
  Individual = 'INDIVIDUAL',
  NonProfit = 'NON_PROFIT',
  Unknown = 'UNKNOWN'
}

/** The calculated convenience fees. */
export type CalculatedConvenienceFees = {
  __typename?: 'CalculatedConvenienceFees';
  /** The convenience fees amount. */
  amount: Scalars['Int'];
  /** The convenience fees rate in basis points. Ex: '200' for 2% */
  rateBps: Scalars['Int'];
};

/** Input data for cancelling a pre-authorized payment. */
export type CancelPaymentInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  paymentId: Scalars['ID'];
  reason: Scalars['String'];
};

/** Status response for cancel payment mutation. */
export type CancelPaymentStatus = {
  __typename?: 'CancelPaymentStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
};

/** The statuses for account capabilities. */
export enum CapabilityStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

/** Input data for payment capture amounts. */
export type CapturePaymentAmountsInput = {
  amount: Scalars['Int'];
  currency: CurrencyType;
};

/** Input data for manually capturing a payment. */
export type CapturePaymentInput = {
  /** The final capture amounts if less than the original authorized amounts. */
  amounts?: Maybe<CapturePaymentAmountsInput>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  paymentId: Scalars['ID'];
};

/** Status response for capturing payments mutation. */
export type CapturePaymentStatus = {
  __typename?: 'CapturePaymentStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
};

/** The brand of credit card. */
export enum CardBrand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type CardDetails = {
  __typename?: 'CardDetails';
  /** bin number */
  bin?: Maybe<Scalars['String']>;
  /** card type */
  type?: Maybe<CardType>;
};

/** Input data for card details */
export type CardDetailsInput = {
  /** bin number */
  bin?: Maybe<Scalars['String']>;
  /** card type */
  cardBrand?: Maybe<Scalars['String']>;
  /** card expiration month */
  expirationMonth?: Maybe<Scalars['Int']>;
  /** card expiration year */
  expirationYear?: Maybe<Scalars['Int']>;
  /** last four digits of card number */
  lastFour?: Maybe<Scalars['String']>;
};

export type CardInfo = {
  __typename?: 'CardInfo';
  /** The credit card brand name. */
  cardBrand?: Maybe<CardBrand>;
  /** The credit card expiration month. */
  expirationMonth?: Maybe<Scalars['Int']>;
  /** The credit card expiration year. */
  expirationYear?: Maybe<Scalars['Int']>;
  /** The last four digit of the credit card. */
  lastFour?: Maybe<Scalars['String']>;
};

/** The type of card i.e. Credit/Debit. */
export enum CardType {
  ChargeCard = 'CHARGE_CARD',
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Unknown = 'UNKNOWN'
}

/** A checkout session record */
export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The URL the customer will be directed to if they choose to cancel the payment. */
  cancelUrl: Scalars['String'];
  /** The URL the customer will be directed to complete the payment. */
  checkoutUrl?: Maybe<Scalars['String']>;
  /** The currency used for the payment */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates whether to fail the mutation call and cancel the payment when it goes into the REVIEW state. (Default value: false) */
  failOnReview?: Maybe<Scalars['Boolean']>;
  /** The Id for the session */
  id: Scalars['String'];
  /** Indicates whether to immediately capture payment amount (default: true) */
  immediateCapture?: Maybe<Scalars['Boolean']>;
  /** The order details */
  orderDetails?: Maybe<OrderDetails>;
  /** The payer details */
  payerDetails?: Maybe<PayerDetails>;
  /** The payment information */
  paymentInfo?: Maybe<PaymentInfo>;
  /** Status of the checkout session */
  status?: Maybe<CheckoutSessionStatus>;
  /** The URL to which the customer will be directed if the payment is successful. */
  successUrl: Scalars['String'];
};

/** Input data for creating a session */
export type CheckoutSessionInput = {
  /** The id of the session */
  id: Scalars['String'];
};

/** Possible statuses for checkout session */
export enum CheckoutSessionStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  New = 'NEW'
}

/** The type of communication to send, sms or email */
export enum CommunicationType {
  Email = 'EMAIL',
  EmailAndSms = 'EMAIL_AND_SMS',
  None = 'NONE',
  Sms = 'SMS'
}

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Phone>;
  structure?: Maybe<BusinessStructure>;
  taxIdProvided?: Maybe<Scalars['Boolean']>;
  verification?: Maybe<CompanyAccountVerification>;
};

/** A list of documentation used to verify the company account */
export type CompanyAccountVerification = {
  __typename?: 'CompanyAccountVerification';
  additionalDocuments: Array<Maybe<AccountVerificationDocument>>;
  documents: Array<Maybe<AccountVerificationDocument>>;
  /** Indicates if the account has got any KYC issues that would require a merchant to re-onboard. */
  kycIssue?: Maybe<Scalars['Boolean']>;
  /** Indicates if the account has got an issue with Social Security Number that would require a merchant to re-onboard. */
  ssnIssue?: Maybe<Scalars['Boolean']>;
  status: CompanyVerificationStatus;
  transitionStatus: TransitionStatus;
};

/** Values for the state of verification for a company */
export enum CompanyVerificationStatus {
  Pending = 'PENDING',
  Unspecified = 'UNSPECIFIED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

/** Input data for completing Payment Method Request */
export type CompletePaymentMethodRequestInput = {
  /** The Payment Method Request ID. */
  id: Scalars['ID'];
  /** The Payment Method ID. */
  paymentMethodId: Scalars['ID'];
};

/** Status response for mutation for completing a payment method request. */
export type CompletePaymentMethodRequestStatus = {
  __typename?: 'CompletePaymentMethodRequestStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The payment method request */
  paymentMethodRequest?: Maybe<PaymentMethodRequest>;
};

/** Input data for conceding a dispute */
export type ConcedeDisputeInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The explanation for changing the status. (Constraints: Maximum Length: 65535) */
  explanation: Scalars['String'];
  /** The dispute id */
  id: Scalars['ID'];
};

/** The convenience fees. */
export type ConvenienceFees = {
  __typename?: 'ConvenienceFees';
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps?: Maybe<Scalars['Int']>;
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps?: Maybe<Scalars['Int']>;
  /**
   * The maximum convenience fee could be set at a platform level (in basis points).
   * @deprecated Please use maxCreditConvenienceFee and maxDebitConvenienceFee
   */
  maxConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum credit convenience fee could be set at a platform level (in basis points). */
  maxCreditConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum debit convenience fee could be set at a platform level (in basis points). */
  maxDebitConvenienceFee?: Maybe<Scalars['Int']>;
};

/** Input data for converting a payfac payment method token */
export type ConvertPayfacPaymentMethodTokenInput = {
  /** Optional bank details */
  bank?: Maybe<BankDetailsInput>;
  /** Optional card details */
  card?: Maybe<CardDetailsInput>;
  /** Customer id to attach payment method with(only for merchant use). */
  customerId?: Maybe<Scalars['String']>;
  /** The payment method holder */
  holder: PaymentMethodHolderInput;
  /** Indicates if the resulting token will be kept on file as a recurring payment method */
  recurring?: Maybe<Scalars['Boolean']>;
  /** The payment method token */
  token: Scalars['String'];
  /** The payment method type */
  type: PaymentMethodType;
  /** This is set if the merchant manually entered the credit card number. "MOBILE" indicates that the virtual terminal transaction was completed in a Card Present environment. "WEB" indicates that the virtual terminal transaction was completed in a Card Not Present environment. */
  virtualTerminalMode?: Maybe<VirtualTerminalMode>;
};

/** Status response for mutation for converting a payment method token. */
export type ConvertPayfacPaymentMethodTokenStatus = {
  __typename?: 'ConvertPayfacPaymentMethodTokenStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  token?: Maybe<Token>;
};

/** Input data for converting a payment method claim */
export type ConvertPaymentMethodClaimInput = {
  /** The id of the payment method claim. */
  claimId: Scalars['String'];
};

/** Status response for mutation for creating a payment method claim. */
export type ConvertPaymentMethodClaimStatus = {
  __typename?: 'ConvertPaymentMethodClaimStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** The country code */
export enum CountryCode {
  Ca = 'CA',
  Us = 'US'
}

/** Input data for creating a session */
export type CreateCheckoutSessionInput = {
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The URL the customer will be directed to if they choose to cancel the payment. */
  cancelUrl: Scalars['String'];
  /** The currency used for the payment */
  currency: CurrencyType;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates whether to fail the mutation call and cancel the payment when it goes into the REVIEW state. (Default value: false) */
  failOnReview?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to immediately capture payment amount (default: true) */
  immediateCapture: Scalars['Boolean'];
  /** The order details */
  orderDetails: OrderDetailsInput;
  /** The payer details */
  payerDetails?: Maybe<PayerDetailsInput>;
  /** The URL to which the customer will be directed if the payment is successful. */
  successUrl: Scalars['String'];
};

/** Status response for mutation for creating a checkout session. */
export type CreateCheckoutSessionStatus = {
  __typename?: 'CreateCheckoutSessionStatus';
  /** The checkout session */
  checkoutSession?: Maybe<CheckoutSession>;
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for creating a payment */
export type CreatePaymentInput = {
  /** The amount to charge the payment method, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount: Scalars['Int'];
  /** The payment amount excluding convenience fees. This value is required when charging a convenience fee. */
  amountBeforeFees?: Maybe<Scalars['Int']>;
  /** The date to defer capturing the payment. Must be less than 7 days from payment creation date. */
  captureAt?: Maybe<Scalars['DateTime']>;
  /** The convenience fees amount */
  convenienceFee?: Maybe<Scalars['Int']>;
  /** The amount of credit to use (if this amount is not available payment will fail), the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  creditAmount?: Maybe<Scalars['Int']>;
  currency: CurrencyType;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The customer id, must be unique for the platform */
  customerId?: Maybe<Scalars['String']>;
  /** The customer number, must be unique for the tenant */
  customerNumber?: Maybe<Scalars['String']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The description of the payment */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether to fail the mutation call and cancel the payment when it goes into the REVIEW state. (Default value: true) */
  failOnReview?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to immediately capture payment amount */
  immediateCapture: Scalars['Boolean'];
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['ID'];
  paymentRequestAllocation?: Maybe<Array<PaymentRequestAllocationInput>>;
  paymentRequestId?: Maybe<Scalars['ID']>;
  /** Additional data for the payment that is used for risk analysis. */
  riskMetadata: RiskMetadataPaymentInput;
};

/** Input data for upserting a payment method claim */
export type CreatePaymentMethodClaimInput = {
  /** The expiry date of the payment method claim. */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** Indicates if this payment method claim is single use. */
  singleUse?: Maybe<Scalars['Boolean']>;
  /** The short lived or long lived payment method token. (Short lived will be converted to long lived). */
  token: Scalars['String'];
};

/** Status response for mutation for creating a payment method claim. */
export type CreatePaymentMethodClaimStatus = {
  __typename?: 'CreatePaymentMethodClaimStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethodClaim?: Maybe<PaymentMethodClaim>;
};

/** Input data for upserting a payment method */
export type CreatePaymentMethodInput = {
  /** The id of the person or tenant to attach the payment method to. */
  attachToResourceId: Scalars['String'];
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Indicates if this payment method is shared with merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
  /** The payment method token */
  token: Scalars['String'];
};

/** Status response for mutation for creating a payment method. */
export type CreatePaymentMethodStatus = {
  __typename?: 'CreatePaymentMethodStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Status response for creating payments mutation. */
export type CreatePaymentStatus = {
  __typename?: 'CreatePaymentStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
};

/** Input data for creating a refund */
export type CreateRefundInput = {
  /** The amount of the refund, if partial. Otherwise null. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The payment id */
  paymentId: Scalars['String'];
  /** The payment request id */
  paymentRequestId?: Maybe<Scalars['ID']>;
  /** The reason for the refund. (Constraints: Minimum Length: 1, Maximum Length: 65535) */
  refundReason: Scalars['String'];
};

/** Status response for mutation for creating a refund. */
export type CreateRefundStatus = {
  __typename?: 'CreateRefundStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  refund?: Maybe<Refund>;
};

/** Input data for creating a report */
export type CreateReportInput = {
  /** The report end date */
  endDate: Scalars['DateTime'];
  /** The file format of the report */
  format: FileFormat;
  /** The file format of the report */
  reportType: ReportType;
  /** The report start date */
  startDate: Scalars['DateTime'];
};

/** Status response mutation for creating a report */
export type CreateReportStatus = {
  __typename?: 'CreateReportStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  report?: Maybe<Report>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  /** Brand of the credit card. Possible values include: amex, discover, diners, jcb, mastercard, visa. */
  cardBrand?: Maybe<CardBrand>;
  /** Personal details of the person who owns the card. */
  cardHolder?: Maybe<PaymentMethodHolder>;
  /** Indicates if the resulting token will be kept on file as a non-recurring payment method. */
  cardOnFile?: Maybe<Scalars['Boolean']>;
  /** Card Verification Value (CVV) of the card. */
  cvvProvided?: Maybe<Scalars['Boolean']>;
  /** Card details */
  details?: Maybe<CardDetails>;
  /** The display name for the card. */
  displayName?: Maybe<Scalars['String']>;
  /** The month the card expires. */
  expirationMonth?: Maybe<Scalars['Int']>;
  /** The year the card expires. */
  expirationYear?: Maybe<Scalars['Int']>;
  /** The last four digits of the card number. */
  lastFour?: Maybe<Scalars['String']>;
  /** Indicates if the resulting token will be kept on file as a recurring payment method. */
  recurring?: Maybe<Scalars['Boolean']>;
  /** This is set if the merchant manually entered the credit card number. "MOBILE" indicates that the virtual terminal transaction was completed in a Card Present environment. "WEB" indicates that the virtual terminal transaction was completed in a Card Not Present environment. */
  virtualTerminalMode?: Maybe<VirtualTerminalMode>;
};

/** A credit memo record */
export type CreditMemo = {
  __typename?: 'CreditMemo';
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount: Scalars['Int'];
  /** The time the credit memo was closed */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** The time the credit memo was created */
  createdAt: Scalars['DateTime'];
  /** The creator that created the credit memo */
  createdBy: Scalars['String'];
  /** The credit usage history */
  creditUsageHistory?: Maybe<Array<CreditUsageHistory>>;
  /** The currency used for the credit memo */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The list of associated payers */
  customerAllocation: Array<CustomerAllocation>;
  /** The Id for the credit memo */
  id?: Maybe<Scalars['String']>;
  /** The time the credit memo was last redeemed */
  lastRedeemedAt?: Maybe<Scalars['DateTime']>;
  /** The time the credit memo was modified */
  modifiedAt?: Maybe<Scalars['DateTime']>;
  /** The user that modified the credit memo */
  modifiedBy?: Maybe<Scalars['String']>;
  /** The owner of the credit memo. */
  owner: Owner;
  /** The reason for the credit memo */
  reason?: Maybe<Scalars['String']>;
  /** The reference number of the credit memo */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The remaining balance on the credit memo. */
  remainingBalance?: Maybe<Scalars['Int']>;
  /** The current status of the credit memo. */
  status?: Maybe<CreditMemoStatus>;
  /** The current reason for the status */
  statusReason?: Maybe<Scalars['String']>;
};

/** A credit memo allocation */
export type CreditMemoAllocation = {
  __typename?: 'CreditMemoAllocation';
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The time the credit memo was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The Id for the credit memo */
  creditMemoId: Scalars['String'];
  /** The reference number of the credit memo */
  referenceNumber?: Maybe<Scalars['String']>;
};

/** A list of credit memos associated with the payer. */
export type CreditMemoConnection = {
  __typename?: 'CreditMemoConnection';
  /** A list of edges. */
  edges: Array<Maybe<CreditMemoEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<CreditMemo>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The credit memo field to query by */
export enum CreditMemoDateRangeType {
  CreditMemoCreated = 'CREDIT_MEMO_CREATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type CreditMemoEdge = {
  __typename?: 'CreditMemoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CreditMemo;
};

export type CreditMemoFeatures = {
  __typename?: 'CreditMemoFeatures';
  /** Allow merchants to display credit memos for payers. */
  enabled: Scalars['Boolean'];
};

/** Ways in which lists of credit memos can be ordered upon return */
export type CreditMemoOrder = {
  /** The direction in which to order credit memos by the specified field */
  direction: OrderDirection;
  /** The field in which to order credit memos */
  field: CreditMemoOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum CreditMemoOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Possible statuses for the credit memo */
export enum CreditMemoStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Redeemed = 'REDEEMED'
}

/** Credit memo usage history of the payer. */
export type CreditMemoUsageConnection = {
  __typename?: 'CreditMemoUsageConnection';
  /** A list of edges. */
  edges: Array<Maybe<CreditUsageHistoryEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<CreditUsageHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Ways in which lists of credit memo usage history can be ordered upon return */
export type CreditMemoUsageHistoryOrder = {
  /** The direction in which to order credit memo usage history by the specified field */
  direction: OrderDirection;
  /** The field in which to order credit memo usage history */
  field: CreditMemoUsageHistoryOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum CreditMemoUsageHistoryOrderField {
  Timestamp = 'TIMESTAMP'
}

/** A credit usage history */
export type CreditUsageHistory = {
  __typename?: 'CreditUsageHistory';
  /** The amount of the credit memo that was used, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount: Scalars['Int'];
  /** The datetime the credit was used */
  createdAt: Scalars['DateTime'];
  /** The reference number of the corresponding credit memo */
  creditMemoReferenceNumber?: Maybe<Scalars['String']>;
  /** The Id for the payment */
  paymentId?: Maybe<Scalars['String']>;
  /** The breakdown of how the credit memo was spread across payment requests */
  paymentRequestCreditAllocation?: Maybe<Array<PaymentRequestCreditAllocation>>;
  /** The remaining balance on the credit memo. */
  remainingBalance?: Maybe<Scalars['Int']>;
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type CreditUsageHistoryEdge = {
  __typename?: 'CreditUsageHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CreditUsageHistory;
};

/** The currency of the transaction, usd or cad */
export enum CurrencyType {
  Cad = 'CAD',
  Unknown = 'UNKNOWN',
  Usd = 'USD'
}

export type CustomEmailSettingsFeatures = {
  __typename?: 'CustomEmailSettingsFeatures';
  /** Allow merchants to set custom email settings for payers. */
  enabled: Scalars['Boolean'];
};

export type Customer = {
  __typename?: 'Customer';
  /** The customer convenience fees. */
  convenienceFees?: Maybe<CustomerConvenienceFees>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** The customer number, must be unique for the tenant */
  customerNumber: Scalars['String'];
  /** The list of customer users. */
  customerUsers?: Maybe<Array<CustomerUser>>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The customer’s name. */
  name?: Maybe<Scalars['String']>;
  /** The owner of the customer record. */
  owner: Owner;
  /** The customer’s payer account details. */
  payerProfile?: Maybe<CustomerPayerProfile>;
  /** The customer’s phone number. */
  phone?: Maybe<Phone>;
  /** The timestamp when the customer was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user identifier who last updated the customer */
  updatedBy?: Maybe<Scalars['String']>;
};

/** A customer allocation */
export type CustomerAllocation = {
  __typename?: 'CustomerAllocation';
  /** The allocation type used for this credit memo */
  allocationType?: Maybe<AllocationType>;
  /** The Id of a customer. */
  customerId?: Maybe<Scalars['String']>;
  /** Unique customer’s number. */
  customerNumber?: Maybe<Scalars['String']>;
  /** The person’s email address. */
  email?: Maybe<Scalars['String']>;
};

/** A customer allocation */
export type CustomerAllocationInput = {
  /** The allocation type used for this credit memo */
  allocationType: AllocationType;
  /** Id of a customer */
  customerId?: Maybe<Scalars['String']>;
  /** Optional unique customer’s number */
  customerNumber?: Maybe<Scalars['String']>;
  /** The person’s email address. */
  email?: Maybe<Scalars['String']>;
};

/** A list of customers associated with the merchant. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** A list of edges. */
  edges: Array<Maybe<CustomerEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Customer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The customer convenience fees. */
export type CustomerConvenienceFees = {
  __typename?: 'CustomerConvenienceFees';
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps?: Maybe<Scalars['Int']>;
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps?: Maybe<Scalars['Int']>;
  /**
   * The maximum convenience fee could be set at a platform level (in basis points).
   * @deprecated Please use maxCreditConvenienceFee and maxDebitConvenienceFee
   */
  maxConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum credit convenience fee could be set at a platform level (in basis points). */
  maxCreditConvenienceFee?: Maybe<Scalars['Int']>;
  /** The maximum debit convenience fee could be set at a platform level (in basis points). */
  maxDebitConvenienceFee?: Maybe<Scalars['Int']>;
  /** Flag, whether to override tenant convenience fees for this customer. */
  overrideConvenienceFees: Scalars['Boolean'];
};

/** Input data for updating the customer convenience fees. */
export type CustomerConvenienceFeesInput = {
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps: Scalars['Int'];
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps: Scalars['Int'];
  /** Flag, whether to override tenant convenience fees for this customer. */
  overrideConvenienceFees: Scalars['Boolean'];
};

/** The customer field to query by. */
export enum CustomerDateRangeType {
  CustomerCreated = 'CUSTOMER_CREATED',
  CustomerUpdated = 'CUSTOMER_UPDATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Customer;
};

export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  /** The customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** The customer role */
  role?: Maybe<CustomerRole>;
  /** The owner tenant id of the customer record. */
  tenantId?: Maybe<Scalars['String']>;
};

export type CustomerOption = {
  __typename?: 'CustomerOption';
  /** The customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** The name of the customer. */
  customerName?: Maybe<Scalars['String']>;
};

/** Ways in which lists of customers can be ordered upon return */
export type CustomerOrder = {
  /** The direction in which to order customers by the specified field */
  direction: OrderDirection;
  /** The field in which to order customers */
  field: CustomerOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum CustomerOrderField {
  CustomerName = 'CUSTOMER_NAME',
  Timestamp = 'TIMESTAMP'
}

export type CustomerPayerProfile = {
  __typename?: 'CustomerPayerProfile';
  /** List of payment methods. */
  paymentMethods?: Maybe<Array<CustomerPaymentMethod>>;
};

export type CustomerPaymentMethod = {
  __typename?: 'CustomerPaymentMethod';
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** A payment method */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Indicates if this payment method is shared with the merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
};

export enum CustomerRole {
  CustomerAdmin = 'CUSTOMER_ADMIN',
  CustomerManager = 'CUSTOMER_MANAGER',
  CustomerPayer = 'CUSTOMER_PAYER',
  CustomerReader = 'CUSTOMER_READER'
}

export type CustomerUser = {
  __typename?: 'CustomerUser';
  /** The customer's email address. */
  email?: Maybe<Scalars['String']>;
  /** The customer role */
  role?: Maybe<CustomerRole>;
};

export type CustomersFeatures = {
  __typename?: 'CustomersFeatures';
  /** Allow merchants to create customers. */
  enabled: Scalars['Boolean'];
};

/** The payment field to query by */
export enum DateRangeType {
  CompletedTimestamp = 'COMPLETED_TIMESTAMP',
  CreatedTimestamp = 'CREATED_TIMESTAMP'
}


/** Input data for deleting a payment method */
export type DeletePaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
};

/** Status response for mutation for deleting a payment method. */
export type DeletePaymentMethodStatus = {
  __typename?: 'DeletePaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Input data for deleting a person */
export type DeletePersonInput = {
  id: Scalars['ID'];
};

/** Status response mutation for deleting a person */
export type DeletePersonStatus = {
  __typename?: 'DeletePersonStatus';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for detaching a payment method */
export type DetachPaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
  /** The id of the person, tenant or customer to attach the payment method to. */
  resourceId: Scalars['ID'];
};

/** Status response for mutation for detaching a payment method. */
export type DetachPaymentMethodStatus = {
  __typename?: 'DetachPaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated This field is deprecated */
  resource?: Maybe<PaymentMethodOwnerResourceUnion>;
};

/** The dispute is raised by the card / bank when the payee queries a payment that was made. */
export type Dispute = {
  __typename?: 'Dispute';
  /** The amount being disputed */
  amount?: Maybe<Scalars['Int']>;
  /** The type of card being used */
  cardBrand?: Maybe<CardBrand>;
  /** The explaination as to why the dispute has been conceded */
  concedeMessage?: Maybe<Scalars['String']>;
  /** The time the dispute was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency of the dispute */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The type of dispute, inquiry / charge back */
  disputeType?: Maybe<DisputeType>;
  /** The Id of the dispute */
  id?: Maybe<Scalars['ID']>;
  /** The owner of the payment dispute. */
  owner?: Maybe<Owner>;
  /** The payment related to the dispute */
  payment?: Maybe<Payment>;
  /** The reason why the dispute has been raised */
  reason?: Maybe<DisputeReason>;
  /** The details behind the reason */
  reasonDetails?: Maybe<Array<DisputeReasonDetail>>;
  /** The message sent from payfac explainging the reason */
  reasonMessage?: Maybe<Scalars['String']>;
  /** The final resolution from a resolved dispute, pending once a challenge has been made. won / lost will be returned from the pay fac */
  resolution?: Maybe<DisputeResolutionType>;
  /** The time of the final resolution */
  resolvedAt?: Maybe<Scalars['DateTime']>;
  /** The current status of the dispute */
  status?: Maybe<DisputeStatus>;
};

/** A list of disputes associated with the merchant. */
export type DisputeConnection = {
  __typename?: 'DisputeConnection';
  /** A list of edges. */
  edges: Array<Maybe<DisputeEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Dispute>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The dispute field to query by */
export enum DisputeDateRangeType {
  DisputeCreated = 'DISPUTE_CREATED'
}

/** An edge in a connection. */
export type DisputeEdge = {
  __typename?: 'DisputeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Dispute;
};

/** Ways in which lists of disputes can be ordered upon return */
export type DisputeOrder = {
  /** The direction in which to order by the specified field */
  direction: OrderDirection;
  /** The field in which to order disputes */
  field: DisputeOrderField;
};

/** The dispute field to order by */
export enum DisputeOrderField {
  Timestamp = 'TIMESTAMP'
}

/** The reason the disputes was raised */
export enum DisputeReason {
  Fraud = 'FRAUD',
  Inquiry = 'INQUIRY',
  NotAsDescribed = 'NOT_AS_DESCRIBED',
  ProcessingError = 'PROCESSING_ERROR',
  Recognition = 'RECOGNITION',
  ServicesNotProvided = 'SERVICES_NOT_PROVIDED',
  Unknown = 'UNKNOWN'
}

/** The details for the card company explaining the reason for the dispute */
export type DisputeReasonDetail = {
  __typename?: 'DisputeReasonDetail';
  /** The code originating from the finance / card company */
  code: Scalars['String'];
  /** The message related to the code */
  message: Scalars['String'];
};

/** The outcome of the dispute, won, lost or pending */
export enum DisputeResolutionType {
  Lost = 'LOST',
  Pending = 'PENDING',
  Won = 'WON'
}

/** The status of the dispute, conceded-awaiting-payfac and challenge-awating-payfac are while we are waiting for the pay fac to respond */
export enum DisputeStatus {
  AwaitingChargebackDecision = 'AWAITING_CHARGEBACK_DECISION',
  AwaitingMerchantResponse = 'AWAITING_MERCHANT_RESPONSE',
  ChallengeAwaitingPayfac = 'CHALLENGE_AWAITING_PAYFAC',
  ConcededAwaitingPayfac = 'CONCEDED_AWAITING_PAYFAC',
  PendingPayfacReview = 'PENDING_PAYFAC_REVIEW',
  Resolved = 'RESOLVED',
  Unknown = 'UNKNOWN'
}

/** The type of dispute, inquiry or chargeback */
export enum DisputeType {
  Chargeback = 'CHARGEBACK',
  Inquiry = 'INQUIRY'
}

/** The current status of the document */
export enum DocumentStatus {
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  Unspecified = 'UNSPECIFIED',
  Verified = 'VERIFIED'
}

/** Input for document tokens. */
export type DocumentTokenInput = {
  /** the type of document the token relates to */
  documentType: DocumentType;
  /** the subject the documents relate to */
  subject: DocumentTokenSubject;
  /** The token returned from wepay */
  token: Scalars['String'];
};

/** Values for document token subject. */
export enum DocumentTokenSubject {
  ControllerPersonalVerification = 'CONTROLLER_PERSONAL_VERIFICATION',
  EntityVerification = 'ENTITY_VERIFICATION',
  Representative_0 = 'REPRESENTATIVE_0',
  Representative_1 = 'REPRESENTATIVE_1',
  Representative_2 = 'REPRESENTATIVE_2',
  Representative_3 = 'REPRESENTATIVE_3'
}

/** The type of document used as evidence during legal entity onboarding. */
export enum DocumentType {
  BenefitsCard = 'BENEFITS_CARD',
  BirthCertificate = 'BIRTH_CERTIFICATE',
  CertificateOfCitizenship = 'CERTIFICATE_OF_CITIZENSHIP',
  CertificateOfNaturalization = 'CERTIFICATE_OF_NATURALIZATION',
  CertifiedCopyOfCourtOrder = 'CERTIFIED_COPY_OF_COURT_ORDER',
  CurrentBankStatement = 'CURRENT_BANK_STATEMENT',
  CurrentLeaseContract = 'CURRENT_LEASE_CONTRACT',
  CurrentLocalTaxBill = 'CURRENT_LOCAL_TAX_BILL',
  CurrentUtilityBill = 'CURRENT_UTILITY_BILL',
  DriversLicense = 'DRIVERS_LICENSE',
  ElectoralRegisterEntry = 'ELECTORAL_REGISTER_ENTRY',
  EmploymentAuthorizationCard = 'EMPLOYMENT_AUTHORIZATION_CARD',
  EvidenceOfAuthority = 'EVIDENCE_OF_AUTHORITY',
  EvidenceOfCorporateRegistration = 'EVIDENCE_OF_CORPORATE_REGISTRATION',
  EvidenceOfNonProfitRegistration = 'EVIDENCE_OF_NON_PROFIT_REGISTRATION',
  MortgageStatement = 'MORTGAGE_STATEMENT',
  OtherGovernmentIssuedPhotoId = 'OTHER_GOVERNMENT_ISSUED_PHOTO_ID',
  Passport = 'PASSPORT',
  PermanentResidentCard = 'PERMANENT_RESIDENT_CARD',
  SocialInsuranceNumberCard = 'SOCIAL_INSURANCE_NUMBER_CARD',
  SocialSecurityCard = 'SOCIAL_SECURITY_CARD',
  Unspecified = 'UNSPECIFIED'
}

export type Features = {
  __typename?: 'Features';
  /** Set of credit memo feature flags enabled/disabled for the account. */
  creditMemos: CreditMemoFeatures;
  /** Set of custom email settings feature flags enabled/disabled for the account. */
  customEmailSettings: CustomEmailSettingsFeatures;
  /** Set of customer feature flags enabled/disabled for the account. */
  customers: CustomersFeatures;
  /** Set of payment methods feature flags enabled/disabled for the account. */
  paymentMethods: PaymentMethodFeatures;
  /** Set of payment requests feature flags enabled/disabled for the account. */
  paymentRequests: PaymentRequestFeatures;
  /** Set of payment feature flags enabled/disabled for the account. */
  payments: PaymentsFeatures;
  /** Set of refund feature flags enabled/disabled for the account. */
  refunds: RefundsFeatures;
};

/** The format of the file */
export enum FileFormat {
  Csv = 'CSV',
  Html = 'HTML',
  Pdf = 'PDF'
}

/** Input data for generating statement access Url */
export type FileName = {
  /** File name for statement */
  name: Scalars['String'];
};

/** Status response for mutation for uploading media. */
export type ImageStatus = {
  __typename?: 'ImageStatus';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Publicly accessible image URI */
  imageUri?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The unique id (generated file name) of the uploaded file */
  uniqueId?: Maybe<Scalars['ID']>;
};

/** Type of uploaded image */
export enum ImageType {
  Logo = 'LOGO',
  Other = 'OTHER'
}

/** The integration mode */
export enum IntegrationMode {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

/** The integration settings. */
export type IntegrationSettings = {
  __typename?: 'IntegrationSettings';
  /** The integration mode */
  mode: IntegrationMode;
  /** The settings for the preview mode */
  previewSettings: PreviewSettings;
  /** The flag indicates whether to process unsent payment request communications */
  processUnsentCommunications?: Maybe<Scalars['Boolean']>;
};


export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  /** The key. */
  key: Scalars['String'];
  /** The value. */
  value?: Maybe<Scalars['String']>;
};

/** Data used for Level 2/Level 3 processing. */
export type L2L3Data = {
  __typename?: 'L2L3Data';
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber?: Maybe<Scalars['String']>;
  /** The order items. */
  lineItems?: Maybe<Array<L2L3DataLineItem>>;
  /** The order type. */
  orderType?: Maybe<L2L3OrderType>;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription?: Maybe<Scalars['String']>;
  /** The tax amount for the order. */
  taxAmount?: Maybe<Scalars['Int']>;
};

/** Input data for Level 2/Level 3 data processing. */
export type L2L3DataInput = {
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber: Scalars['String'];
  /** The order items. */
  lineItems?: Maybe<Array<L2L3DataLineItemInput>>;
  /** The order type. */
  orderType: L2L3OrderType;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription: Scalars['String'];
  /** The tax amount for the order. */
  taxAmount: Scalars['Int'];
};

/** Order line item for Level 2/Level 3 data processing. */
export type L2L3DataLineItem = {
  __typename?: 'L2L3DataLineItem';
  /** ISO 4217 currency code. */
  currency?: Maybe<CurrencyType>;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description?: Maybe<Scalars['String']>;
  /** The number of items ordered. */
  quantity?: Maybe<Scalars['Int']>;
  /** Tax Amount. */
  taxAmount?: Maybe<Scalars['Int']>;
  /** The tax amount for the order. */
  totalAmount?: Maybe<Scalars['Int']>;
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure?: Maybe<Scalars['String']>;
  /** Price per unit. */
  unitPrice?: Maybe<Scalars['Int']>;
};

/** Order line item for Level 2/Level 3 data processing. */
export type L2L3DataLineItemInput = {
  /** ISO 4217 currency code. */
  currency: CurrencyType;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The number of items ordered. */
  quantity: Scalars['Int'];
  /** Tax Amount. */
  taxAmount?: Maybe<Scalars['Int']>;
  /** The tax amount for the order. */
  totalAmount: Scalars['Int'];
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure: Scalars['String'];
  /** Price per unit. */
  unitPrice: Scalars['Int'];
};

/** The type of order for Level 2/Level 3 processing. */
export enum L2L3OrderType {
  Donation = 'DONATION',
  Event = 'EVENT',
  Goods = 'GOODS',
  Personal = 'PERSONAL',
  Service = 'SERVICE'
}

/** Order line item for Risk Metadata & Level 2/Level 3 data processing. */
export type LineItem = {
  __typename?: 'LineItem';
  /** ISO 4217 currency code. */
  currency: CurrencyType;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The number of items ordered. */
  quantity: Scalars['Int'];
  /** The tax amount for the order. */
  totalAmount: Scalars['Int'];
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure: Scalars['String'];
  /** Price per unit. */
  unitPrice: Scalars['Int'];
};

/** Order line item. */
export type LineItemInput = {
  /** ISO 4217 currency code. */
  currency: CurrencyType;
  /** Text description (especially for services and invoices). (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The number of items ordered. */
  quantity: Scalars['Int'];
  /** The tax amount for the order. */
  totalAmount: Scalars['Int'];
  /** The unit of measure relative to the line item. (Constraints: Maximum Length: 255) */
  unitOfMeasure: Scalars['String'];
  /** Price per unit. */
  unitPrice: Scalars['Int'];
};

/** Login context. */
export type LoginContext = {
  __typename?: 'LoginContext';
  accounts: Array<TenantAccount>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Status response for mutation for uploading media. */
export type MediaStatus = {
  __typename?: 'MediaStatus';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The unique id (generated file name) of the uploaded file */
  uniqueId?: Maybe<Scalars['ID']>;
};

/** Status response for mutation for uploading media. */
export type MediaStatus2 = {
  __typename?: 'MediaStatus2';
  /** Whether the delete was successful, failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The unique id (generated file name "filename_uuid") of the uploaded file */
  uniqueId?: Maybe<Scalars['String']>;
};

export type MerchantInfo = {
  __typename?: 'MerchantInfo';
  /** Accepted payment methods for the merchant */
  acceptedPaymentMethods?: Maybe<Array<AcceptedPaymentMethod>>;
  /** The merchant’s country */
  country?: Maybe<Scalars['String']>;
  /** Three-letter ISO currency code representing the default currency for the merchant. */
  defaultCurrency?: Maybe<CurrencyType>;
  /** Set of feature flags enabled/disabled for the merchant. */
  features?: Maybe<Features>;
  /** Unique identifier for the merchant */
  id: Scalars['ID'];
  /** The url for the merchant logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** Name of the company or business. */
  name?: Maybe<Scalars['String']>;
  /** Set of miscellaneous options on the account */
  options?: Maybe<AccountOptions>;
  /** The owner of the merchant account. */
  owner?: Maybe<Owner>;
  /** The refund policy */
  refundPolicy?: Maybe<Scalars['String']>;
  /** The description to appear on statements */
  statementDescription?: Maybe<Scalars['String']>;
  /** Supported payment methods enabled for the merchant. */
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
};

/** Represents an amount of money with its currency type. */
export type Money = {
  __typename?: 'Money';
  /** The amount */
  amount: Scalars['Int'];
  /** The three-letter currency code defined in ISO 4217. */
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Attaches a payment method to person or tenant. */
  attachPaymentMethod: AttachPaymentMethodStatus;
  /** Cancel a pre-authorized payment. */
  cancelPayment: CancelPaymentStatus;
  /** Capture a payment. */
  capturePayment: CapturePaymentStatus;
  /** Completes a Payment Method Request */
  completePaymentMethodRequest: CompletePaymentMethodRequestStatus;
  /** [NOT ACCESSIBLE BY API] Concede the dispute, and explanation must be provided */
  concedeDispute: MutationStatusCode;
  /** Converts a payfac payment method token. Returned token has a 15 min expiration. */
  convertPayfacPaymentMethodToken: ConvertPayfacPaymentMethodTokenStatus;
  /** Converts a payment method claim to a payment method */
  convertPaymentMethodClaim: ConvertPaymentMethodClaimStatus;
  /** Create a checkout session. */
  createCheckoutSession: CreateCheckoutSessionStatus;
  /** Create a payment. */
  createPayment: CreatePaymentStatus;
  /** Creates a new payment method. */
  createPaymentMethod: CreatePaymentMethodStatus;
  /** [NOT ACCESSIBLE BY API] Creates a new payment method claim. */
  createPaymentMethodClaim: CreatePaymentMethodClaimStatus;
  /** Create a refund. The payment id, refund reason and amount refunded are all required. */
  createRefund: CreateRefundStatus;
  /** Create the report */
  createReport: CreateReportStatus;
  /** Deletes a payment method. */
  deletePaymentMethod: DeletePaymentMethodStatus;
  /** [NOT ACCESSIBLE BY API] Delete the person. Note: soft delete only */
  deletePerson: DeletePersonStatus;
  /** Detaches a payment method from person or tenant. */
  detachPaymentMethod: DetachPaymentMethodStatus;
  /** Get statement access url, use the file name when creating a statement access url request. */
  generateStatementAccessUrl: StatementAccessUrlRequestStatus;
  /** [NOT ACCESSIBLE BY API] Forwards the payment request to the mentioned email address. */
  paymentRequestForward: PaymentRequestForwardStatus;
  /**
   * Start processing the refund. The payment id, refund reason and amount refunded are all required.
   * @deprecated Deprecated in favor of the createRefund mutation
   */
  processRefund: ProcessRefundStatus;
  /** [NOT ACCESSIBLE BY API] Remove the customer user */
  removeCustomerUser: DeletePersonStatus;
  /** Resend payment receipt */
  resendPaymentReceipt: ResendPaymentReceiptStatus;
  /** Send the test email */
  sendTestEmail: SendTestEmailStatus;
  /** [NOT ACCESSIBLE BY API] Submit documentation (tokens) used to verify the merchant */
  submitVerification: SubmitVerificationStatus;
  /** Update the convenience fees for the tenant account */
  updateConvenienceFees: UpdateConvenienceFeesStatus;
  /** [NOT ACCESSIBLE BY API] Challenge the dispute, the explaination and document tokens are required */
  updateDispute: MutationStatusCode;
  /** Update the tenant account integration settings */
  updateIntegrationSettings: UpdateIntegrationSettingsStatus;
  /** Updates a payment method. */
  updatePaymentMethod: UpdatePaymentMethodStatus;
  /** Update the payment reminder settings for the tenant account */
  updatePaymentReminderSettings: UpdatePaymentReminderSettingsStatus;
  /** Update the email settings for the tenant account */
  updateTenantAccountEmailSettings: UpdateTenantAccountEmailSettingsStatus;
  /** Update the payout settings for the tenant account */
  updateTenantTransitionStatus: UpdatePayoutSettingsStatus;
  /** Uploads the PDF file required by the payment request, use the unique id returned as the invoiceRef when creating a payment request. */
  upload: MediaStatus;
  /** Uploads the PDF file required by the payment request, use the unique id returned as the invoiceRef when creating a payment request. */
  upload2: MediaStatus2;
  /** Uploads the image into publicly accessible image container on azure blob storage. */
  uploadImage: ImageStatus;
  /** Upsert a credit memo. */
  upsertCreditMemo: UpsertCreditMemoStatus;
  /** Create or update the customer */
  upsertCustomer: UpsertCustomerStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the customer user */
  upsertCustomerUser: UpsertPersonStatus;
  /** [NOT ACCESSIBLE BY API] Create or Update a payer person */
  upsertPayer: UpsertPersonStatus;
  /** Create or update a Payment Method Request */
  upsertPaymentMethodRequest: UpsertPaymentMethodRequestStatus;
  /** Creates a new payment request. The reference number must be unique and the invoice ref must correspond to a pdf file that has already been uploaded. */
  upsertPaymentRequest: UpsertPaymentRequestStatus;
  /** Creates a new payment request without an invoice ref. The reference number must be unique. */
  upsertPaymentRequestNoInvoice: UpsertPaymentRequestStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the payout frequency schedule. This can only be called once the tenant account has been created with wepay */
  upsertPayoutFrequency: UpsertPayoutFrequencyStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the payout settings. This can only be called once the tenant account has been created with wepay */
  upsertPayoutSettings: UpsertPayoutSettingsStatus;
  /** [NOT ACCESSIBLE BY API] Create or update the person / user */
  upsertPerson: UpsertPersonStatus;
  /** [NOT ACCESSIBLE BY API] Update the tenant account properties */
  upsertTenantAccount: UpsertTenantAccountStatus;
  /** [NOT ACCESSIBLE BY API] Verify bank payment method with microdeposits. */
  verifyPaymentMethod: VerifyPaymentMethodStatus;
};


export type MutationAttachPaymentMethodArgs = {
  input: AttachPaymentMethodInput;
};


export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};


export type MutationCapturePaymentArgs = {
  input: CapturePaymentInput;
};


export type MutationCompletePaymentMethodRequestArgs = {
  input: CompletePaymentMethodRequestInput;
};


export type MutationConcedeDisputeArgs = {
  input: ConcedeDisputeInput;
};


export type MutationConvertPayfacPaymentMethodTokenArgs = {
  input: ConvertPayfacPaymentMethodTokenInput;
};


export type MutationConvertPaymentMethodClaimArgs = {
  input: ConvertPaymentMethodClaimInput;
};


export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePaymentMethodClaimArgs = {
  input: CreatePaymentMethodClaimInput;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeletePersonArgs = {
  input: DeletePersonInput;
};


export type MutationDetachPaymentMethodArgs = {
  input: DetachPaymentMethodInput;
};


export type MutationGenerateStatementAccessUrlArgs = {
  input: FileName;
};


export type MutationPaymentRequestForwardArgs = {
  input: PaymentRequestForwardInput;
};


export type MutationProcessRefundArgs = {
  input: ProcessRefundInput;
};


export type MutationRemoveCustomerUserArgs = {
  input: RemoveCustomerUserInput;
};


export type MutationResendPaymentReceiptArgs = {
  input: ResendPaymentReceiptInput;
};


export type MutationSendTestEmailArgs = {
  input: SendTestEmailInput;
};


export type MutationSubmitVerificationArgs = {
  input: SubmitVerificationInput;
};


export type MutationUpdateConvenienceFeesArgs = {
  input: UpdateConvenienceFeesInput;
};


export type MutationUpdateDisputeArgs = {
  input: UpdateDisputeInput;
};


export type MutationUpdateIntegrationSettingsArgs = {
  input: UpdateIntegrationSettingsInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdatePaymentReminderSettingsArgs = {
  input: UpdatePaymentReminderSettingsInput;
};


export type MutationUpdateTenantAccountEmailSettingsArgs = {
  input: UpdateTenantAccountEmailSettingsInput;
};


export type MutationUpdateTenantTransitionStatusArgs = {
  input: UpdateTenantAccountTransitionStatusInput;
};


export type MutationUploadArgs = {
  input: UploadInput;
};


export type MutationUpload2Args = {
  input: UploadInput;
};


export type MutationUploadImageArgs = {
  input: UploadImageInput;
};


export type MutationUpsertCreditMemoArgs = {
  input: UpsertCreditMemoInput;
};


export type MutationUpsertCustomerArgs = {
  input: UpsertCustomerInput;
};


export type MutationUpsertCustomerUserArgs = {
  input: UpsertCustomerUserInput;
};


export type MutationUpsertPayerArgs = {
  input: UpsertPayerInput;
};


export type MutationUpsertPaymentMethodRequestArgs = {
  input: UpsertPaymentMethodRequestInput;
};


export type MutationUpsertPaymentRequestArgs = {
  input: UpsertPaymentRequestInput;
};


export type MutationUpsertPaymentRequestNoInvoiceArgs = {
  input: UpsertPaymentRequestNoInvoiceInput;
};


export type MutationUpsertPayoutFrequencyArgs = {
  input: UpsertPayoutFrequencyInput;
};


export type MutationUpsertPayoutSettingsArgs = {
  input: UpsertPayoutSettingsInput;
};


export type MutationUpsertPersonArgs = {
  input: UpsertPersonInput;
};


export type MutationUpsertTenantAccountArgs = {
  input: UpsertTenantAccountInput;
};


export type MutationVerifyPaymentMethodArgs = {
  input: VerifyPaymentMethodInput;
};

/** All possible statuses for mutations */
export enum MutationStatusCode {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

/** Data used for Level 2/Level 3 processing & rbits. */
export type OrderDetails = {
  __typename?: 'OrderDetails';
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber: Scalars['String'];
  /** The order items. */
  lineItems: Array<LineItem>;
  /** The order type. */
  orderType: OrderType;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription: Scalars['String'];
  /** The tax amount for the order. */
  taxAmount: Scalars['Int'];
};

/** Input data for Risk Metadata & Level 2/Level 3 data processing. */
export type OrderDetailsInput = {
  /** Customer reference number. (Constraints: Maximum Length: 255) */
  customerReferenceNumber: Scalars['String'];
  /** The order items. */
  lineItems: Array<LineItemInput>;
  /** The order type. */
  orderType: OrderType;
  /** The short description of the order. (Constraints: Maximum Length: 255) */
  shortDescription: Scalars['String'];
  /** The tax amount for the order. */
  taxAmount: Scalars['Int'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Input data for a sales order line item */
export type OrderLineItemInput = {
  /** Currency of the associated price. */
  currency: CurrencyType;
  /** Description of the line item. (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** Price of the line item */
  price: Scalars['Int'];
  /** Quantity of the line item. */
  quantity: Scalars['Int'];
};

/** The type of order. */
export enum OrderType {
  Donation = 'DONATION',
  Event = 'EVENT',
  Goods = 'GOODS',
  Personal = 'PERSONAL',
  Service = 'SERVICE',
  Unknown = 'UNKNOWN'
}

/** The owner data attached to most of the data objects, only the relevant properties will be populated */
export type Owner = {
  __typename?: 'Owner';
  /** The Id of the adjustment */
  adjustmentId?: Maybe<Scalars['ID']>;
  /** The Id of the customer */
  customerId?: Maybe<Scalars['ID']>;
  /** The Id of the dispute */
  disputeId?: Maybe<Scalars['ID']>;
  /** The Id of the payment */
  paymentId?: Maybe<Scalars['ID']>;
  /** The Id of the payment request */
  paymentRequestId?: Maybe<Scalars['ID']>;
  /** The Id of the payout */
  payoutId?: Maybe<Scalars['ID']>;
  /** The Id of the refund */
  personId?: Maybe<Scalars['ID']>;
  /** The Id of the recovery */
  recoveryId?: Maybe<Scalars['ID']>;
  /** The Id of the refund */
  refundId?: Maybe<Scalars['ID']>;
  /** The Id of the tenant */
  tenantId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor for the last items in the connection, use this as the start when you want to get the next page */
  endCursor?: Maybe<Scalars['String']>;
  /** Whether the connection can return any more results */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** whether the connetion can return any previous pages */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor for the first item in the connection */
  startCursor?: Maybe<Scalars['String']>;
};

/** The Payment Facilitator. */
export enum PayFac {
  Ptt = 'PTT',
  Unknown = 'UNKNOWN',
  Wepay = 'WEPAY'
}

export type PayFacId = {
  __typename?: 'PayFacId';
  /** Id of the resource. */
  resourceId?: Maybe<Scalars['String']>;
  /** Type of resource. */
  resourceType?: Maybe<Scalars['String']>;
};

export type PayFacIds = {
  __typename?: 'PayFacIds';
  /** List of resource ids and types. */
  ids?: Maybe<Array<PayFacId>>;
};

/** A list of credit memos associated with the payer. */
export type PayerCreditMemoConnection = {
  __typename?: 'PayerCreditMemoConnection';
  /** A list of edges. */
  edges: Array<Maybe<CreditMemoEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<CreditMemo>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total remaining balance. */
  totalBalance: Scalars['Int'];
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type PayerDetails = {
  __typename?: 'PayerDetails';
  /** The address */
  address?: Maybe<Address>;
  /** The email address */
  email: Scalars['String'];
  /** The card/account holder name */
  name: Scalars['String'];
  /** The phone muber */
  phone?: Maybe<Phone>;
};

/** Input data for the payer */
export type PayerDetailsInput = {
  /** The address of the payment method card/account holder */
  address?: Maybe<AddressInputType>;
  /** The email address of the payment method card/account holder. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The name of the payment method card/account holder. (Constraints: Minimum Length: 3, Maximum Length: 26, Pattern: ^[^\x00-\x1f]+$) */
  name?: Maybe<Scalars['String']>;
  /** The phone number of the payment method card/account holder */
  phone?: Maybe<PhoneInputType>;
};

/** A payer merchant payments due summary */
export type PayerMerchantSummary = {
  __typename?: 'PayerMerchantSummary';
  /** The earliest/minimum created date from the all payment requests */
  earliestCreatedDate?: Maybe<Scalars['DateTime']>;
  /** The earliest/minimum due date from the all payment requests */
  earliestDueDate?: Maybe<Scalars['DateTime']>;
  /** The details of the merchant */
  merchantInfo?: Maybe<MerchantInfo>;
  /** The number of unpaid payment requests */
  numberOfUnpaidRequests: Scalars['Int'];
  /** Pending payment method request */
  pendingPaymentMethodRequest?: Maybe<PaymentMethodRequest>;
  /** The total amount due */
  totalAmount: Scalars['Int'];
  /** The remaining amount on outstanding payment requests */
  totalDue: Scalars['Int'];
  /** The total amount paid */
  totalPaid: Scalars['Int'];
  /** The total amount refunded */
  totalRefunded: Scalars['Int'];
};

export type PayerPaymentMethod = {
  __typename?: 'PayerPaymentMethod';
  /** Indicates if this payment method is the default for the payer. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** A payment method */
  paymentMethod?: Maybe<PaymentMethod>;
};

export type PayerProfile = {
  __typename?: 'PayerProfile';
  /** List of payment methods. */
  paymentMethods?: Maybe<Array<PayerPaymentMethod>>;
};

/** A payer transaction */
export type PayerTransaction = {
  __typename?: 'PayerTransaction';
  /**
   * The payment amount
   * @deprecated Please use Payment field
   */
  amount?: Maybe<Scalars['Int']>;
  /**
   * The currency of the payment
   * @deprecated Please use Payment field
   */
  currency?: Maybe<CurrencyType>;
  /**
   * The reference number from the payment request
   * @deprecated Please use PaymentRequest field
   */
  invoiceReferenceNumber: Scalars['String'];
  /** The country of the merchant */
  merchantCountry: Scalars['String'];
  /** The name of the merchant payee */
  merchantName: Scalars['String'];
  /** The payer. */
  payer?: Maybe<Person>;
  /** The payment. */
  payment?: Maybe<Payment>;
  /**
   * The date for the payment
   * @deprecated Please use Payment field
   */
  paymentDate?: Maybe<Scalars['DateTime']>;
  /**
   * The id for the payment
   * @deprecated Please use Payment field
   */
  paymentId?: Maybe<Scalars['String']>;
  /** The payment method used for payment. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** The payment request. */
  paymentRequest?: Maybe<PaymentRequest>;
  /**
   * The create timestamp for the payment request
   * @deprecated Please use Payment field
   */
  paymentRequestCreatedTimestamp: Scalars['DateTime'];
  /**
   * The id for the payment request
   * @deprecated Please use Payment field
   */
  paymentRequestId: Scalars['String'];
  /** The payment url token */
  paymentUrlToken?: Maybe<Scalars['String']>;
  /**
   * The id for the payer
   * @deprecated Please use Payment field
   */
  personId: Scalars['String'];
  /**
   * The status of the payment request
   * @deprecated Please use PaymentRequest field
   */
  status: PaymentRequestStatus;
  /** The id of the merchant payee */
  tenantId: Scalars['String'];
};

/** A list of transactions associated with the payer. */
export type PayerTransactionConnection = {
  __typename?: 'PayerTransactionConnection';
  /** A list of edges. */
  edges: Array<Maybe<PayerTransactionEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PayerTransaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PayerTransactionEdge = {
  __typename?: 'PayerTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PayerTransaction;
};

/** Ways in which lists of payer transactions can be ordered upon return */
export type PayerTransactionOrder = {
  /** The direction in which to order payer transactions by the specified field */
  direction: OrderDirection;
  /** The field in which to order payer transactions */
  field: PayerTransactionOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum PayerTransactionOrderField {
  AttemptTimestamp = 'ATTEMPT_TIMESTAMP',
  Timestamp = 'TIMESTAMP'
}

/** A payer transaction summary by merchant */
export type PayerTransactionSummary = {
  __typename?: 'PayerTransactionSummary';
  /** The merchant payments due summary */
  merchantSummary: Array<PayerMerchantSummary>;
  /** The payer. */
  payer?: Maybe<Person>;
};

/** A payment record */
export type Payment = {
  __typename?: 'Payment';
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The payment amount excluding convenience fees */
  amountBeforeFees?: Maybe<Scalars['Int']>;
  /** The amount being disputed against this payment */
  amountDisputed?: Maybe<Scalars['Int']>;
  /** The amount refunded against the payment */
  amountRefunded?: Maybe<Scalars['Int']>;
  /** The time the payment was attempted */
  attemptTimestamp?: Maybe<Scalars['DateTime']>;
  /** The authorization code for the payment */
  authorizationCode?: Maybe<Scalars['String']>;
  /** The reason why the payment was cancelled */
  cancelReason?: Maybe<Scalars['String']>;
  /** The time the payment was captured */
  capturedAt?: Maybe<Scalars['DateTime']>;
  /** The time the payment was completed */
  completedTimestamp?: Maybe<Scalars['DateTime']>;
  /** The convenience fees amount */
  convenienceFee?: Maybe<Scalars['Int']>;
  /** The time the payment was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The amount used from the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  creditAmount?: Maybe<Scalars['Int']>;
  /** The list of associated credit memos */
  creditMemoAllocation?: Maybe<Array<CreditMemoAllocation>>;
  /** The currency used for this payment */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The customer associated to payment. */
  customer?: Maybe<Customer>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The description of the payment */
  description?: Maybe<Scalars['String']>;
  /** Details of what failed */
  failureDetail?: Maybe<ReasonInfo>;
  /** The reason why the payment failed */
  failureReason?: Maybe<Scalars['String']>;
  /** The fee for this payment (lowest denomination). (Constraints: >= 0) */
  feeAmount?: Maybe<Scalars['Int']>;
  /** The Id for the payment */
  id: Scalars['String'];
  /** Indicates whether to immediately capture payment amount */
  immediateCapture?: Maybe<Scalars['Boolean']>;
  /** The user that initiated the payment */
  initiatedBy?: Maybe<Scalars['String']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 processing data */
  l2l3Data?: Maybe<L2L3Data>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The owner of the payment. */
  owner: Owner;
  /** The used payment method */
  paymentMethod?: Maybe<PaymentMethod>;
  /**
   * The parent payment request
   * @deprecated Use paymentRequestAllocation instead
   */
  paymentRequest?: Maybe<PaymentRequest>;
  /** The list of associated payment requests */
  paymentRequestAllocation?: Maybe<Array<PaymentRequestAllocation>>;
  /** The reason why the payment is pending */
  pendingReason?: Maybe<Scalars['String']>;
  /** The reason code for the pending payment */
  pendingReasonCode?: Maybe<PendingReasonCode>;
  /** A list of the refunds made against this payment */
  refunds: Array<Maybe<Refund>>;
  /** The current status of the payment */
  status: PaymentStatus;
  /** Total payment amount inclusive of convenience fee and credit amount */
  totalAmount?: Maybe<Scalars['Int']>;
};

/** The Payment Attempt */
export type PaymentAttempt = {
  __typename?: 'PaymentAttempt';
  amount: Scalars['Int'];
  customerPONumber?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentRequest?: Maybe<PaymentRequest>;
  timestamp: Scalars['DateTime'];
};

/** Input for the Payment Attempt */
export type PaymentAttemptInput = {
  amount: Scalars['Int'];
  customerPONumber?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  paymentRequestId?: Maybe<Scalars['ID']>;
  timestamp: Scalars['DateTime'];
};

export type PaymentBank = {
  __typename?: 'PaymentBank';
  accountHolder?: Maybe<PaymentMethodHolder>;
  accountType?: Maybe<BankAccountType>;
  lastFour?: Maybe<Scalars['String']>;
};

export type PaymentBankInfo = {
  __typename?: 'PaymentBankInfo';
  /** The bank account type */
  accountType?: Maybe<BankAccountType>;
  /** The last four digits of the bank account */
  lastFour?: Maybe<Scalars['String']>;
};

/** A list of payments associated with the merchant. */
export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Payment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export enum PaymentDateRangeType {
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentRequestCreated = 'PAYMENT_REQUEST_CREATED',
  PaymentRequestDueDate = 'PAYMENT_REQUEST_DUE_DATE',
  PaymentRequestUpdated = 'PAYMENT_REQUEST_UPDATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Payment;
};

/** The payment information */
export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  /** The amount of the payment, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The authorization code for the payment */
  authorizationCode?: Maybe<Scalars['String']>;
  /** The time the payment was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency used for this payment */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The reason why the payment failed */
  failureReason?: Maybe<Scalars['String']>;
  /** The Id for the payment */
  id: Scalars['String'];
  /** Indicates whether to immediately capture payment amount */
  immediateCapture?: Maybe<Scalars['Boolean']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The owner of the payment. */
  owner: Owner;
  /** The used payment method information */
  paymentMethodInfo?: Maybe<PaymentMethodInfo>;
  /** The reason why the payment is pending */
  pendingReason?: Maybe<Scalars['String']>;
  /** The current status of the payment */
  status: PaymentStatus;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  creditCard?: Maybe<CreditCard>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Details of what failed */
  errorReason?: Maybe<ReasonInfo>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** Whether the payment method is long lived or not */
  isLongLived?: Maybe<Scalars['Boolean']>;
  /** The owner of the payment method. */
  owner: Owner;
  paymentBank?: Maybe<PaymentBank>;
  /** The status of payment method. */
  status?: Maybe<PaymentMethodStatus>;
  /** The type of payment method. */
  type?: Maybe<PaymentMethodType>;
  /** The timestamp when the payment method was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user identifier who last updated the payment method */
  updatedBy?: Maybe<Scalars['String']>;
};

export type PaymentMethodClaim = {
  __typename?: 'PaymentMethodClaim';
  /** The card information. */
  cardInfo?: Maybe<CardInfo>;
  /** The timestamp when the payment method claim has/will expire(d) */
  expirationDate?: Maybe<Scalars['String']>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The owner of the payment method claim. */
  owner: Owner;
  /** Whether the payment method claim is single use or not */
  singleUse?: Maybe<Scalars['Boolean']>;
};

/** A list of payment methods associated with the merchant. */
export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentMethodEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PaymentMethod>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentMethod;
};

export type PaymentMethodFeatures = {
  __typename?: 'PaymentMethodFeatures';
  /** Automatically update credit cards that have been stored with ApteanPay. If a card is expired, or has been replaced (e.g., due to theft), it will be automatically updated based in information provided by card networks. */
  autoUpdate: Scalars['Boolean'];
  /** Allow Merchant to request new Shared Payment Method from customer. */
  paymentMethodRequests: Scalars['Boolean'];
};

export type PaymentMethodHolder = {
  __typename?: 'PaymentMethodHolder';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  phone?: Maybe<Phone>;
};

/** Input data for the payment method holder */
export type PaymentMethodHolderInput = {
  /** The address of the payment method card/account holder */
  address: AddressInputType;
  /** The email address of the payment method card/account holder. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The name of the payment method card/account holder. (Constraints: Minimum Length: 3, Maximum Length: 26, Pattern: ^[^\x00-\x1f]+$) */
  name?: Maybe<Scalars['String']>;
  /** The phone number of the payment method card/account holder */
  phone?: Maybe<PhoneInputType>;
};

export type PaymentMethodInfo = {
  __typename?: 'PaymentMethodInfo';
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The card information */
  creditCard?: Maybe<CardInfo>;
  /** The payment bank information */
  paymentBank?: Maybe<PaymentBankInfo>;
  /** The type of payment method */
  type?: Maybe<PaymentMethodType>;
};

/** Ways in which lists of payment methods can be ordered upon return */
export type PaymentMethodOrder = {
  /** The direction in which to order payment methods by the specified field */
  direction: OrderDirection;
  /** The field in which to order payment methods */
  field: PaymentMethodOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum PaymentMethodOrderField {
  Timestamp = 'TIMESTAMP'
}

export type PaymentMethodOwnerResourceUnion = Customer | Person | TenantAccount;

/** The PaymentMethodRequest defines an instance of a payment method requested by the merchant / legal entity for customer. */
export type PaymentMethodRequest = {
  __typename?: 'PaymentMethodRequest';
  /** The timestamp when Payment Method Request was completed. */
  completedTimestamp?: Maybe<Scalars['DateTime']>;
  /** The timestamp when Payment Method Request was issued. */
  createdTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner?: Maybe<Owner>;
  paymentAttempts: Array<PaymentAttempt>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** Textual reason the merchant is requesting new card. */
  reason: Scalars['String'];
  restriction: PaymentMethodRequestRestriction;
  sendMerchantEmailOptions?: Maybe<SendMerchantEmailOptions>;
  /** The current status of the payment method request */
  status?: Maybe<PaymentMethodRequestStatus>;
  /** The status reason. */
  statusReason?: Maybe<Scalars['String']>;
  supportedPaymentMethods: Array<PaymentMethodType>;
};

/** A list of payments associated with the merchant. */
export type PaymentMethodRequestConnection = {
  __typename?: 'PaymentMethodRequestConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentMethodRequestEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PaymentMethodRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentMethodRequestEdge = {
  __typename?: 'PaymentMethodRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentMethodRequest;
};

/** Ways in which lists of payment method requests can be ordered upon return */
export type PaymentMethodRequestOrder = {
  /** The direction in which to order payment method requests by the specified field */
  direction: OrderDirection;
  /** The field in which to order payment method requests */
  field: PaymentMethodRequestOrderField;
};

/** Possible fields in which to order a list of items when provided an `orderBy` argument. */
export enum PaymentMethodRequestOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Restriction on payment method request while completing the request. */
export enum PaymentMethodRequestRestriction {
  AddOnly = 'ADD_ONLY',
  AddOrSelectExisting = 'ADD_OR_SELECT_EXISTING',
  AddOrSelectExistingExcludingAttempted = 'ADD_OR_SELECT_EXISTING_EXCLUDING_ATTEMPTED'
}

/** Possible statuses for the payment method request */
export enum PaymentMethodRequestStatus {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

/** Possible values representing the payment method status. */
export enum PaymentMethodStatus {
  Deleted = 'DELETED',
  Processing = 'PROCESSING',
  ProcessingComplete = 'PROCESSING_COMPLETE',
  ProcessingError = 'PROCESSING_ERROR',
  Unknown = 'UNKNOWN',
  Unverified = 'UNVERIFIED',
  VerificationFailed = 'VERIFICATION_FAILED',
  VerificationPending = 'VERIFICATION_PENDING',
  Verified = 'VERIFIED'
}

/** Possible values representing the payment method type. */
export enum PaymentMethodType {
  CreditCard = 'CREDIT_CARD',
  PaymentBankUs = 'PAYMENT_BANK_US'
}

/** Ways in which lists of payments can be ordered upon return */
export type PaymentOrder = {
  /** The direction in which to order payments by the specified field */
  direction: OrderDirection;
  /** The field in which to order payments */
  field: PaymentOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum PaymentOrderField {
  Timestamp = 'TIMESTAMP'
}

/** The payment reminders settings. */
export type PaymentReminderSettings = {
  __typename?: 'PaymentReminderSettings';
  /** Number of days before due date for which reminder would be sent. */
  dueDateReminderDays?: Maybe<Scalars['Int']>;
  /** Number of days after due date for which reminder would be sent. */
  overdueReminderDays?: Maybe<Scalars['Int']>;
  /** Message for reminders after due date. */
  overdueReminderMessage?: Maybe<Scalars['String']>;
  /** Message for reminders before due date. */
  reminderMessage?: Maybe<Scalars['String']>;
  sendDueDateReminder: Scalars['Boolean'];
  sendOverdueReminder: Scalars['Boolean'];
};

/** The PaymentRequest defines an instance of a payment requested by the merchant / legal entity. The payment request can have many communications, payments and refunds. */
export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  /** Additional information for the payment request. */
  additionalInfo?: Maybe<Scalars['String']>;
  /** The amount requested */
  amount?: Maybe<Scalars['Int']>;
  /** A list of the communications sent to the payee against this request */
  communications: Array<Maybe<PaymentRequestCommunication>>;
  /** The time the payment request was created */
  createdAt: Scalars['DateTime'];
  /** The creator of the payment requests */
  createdBy?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The customer associated to payment request. */
  customer?: Maybe<Customer>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The Id for the payment request */
  id: Scalars['String'];
  /** The Id for the invoice */
  invoiceId?: Maybe<Scalars['String']>;
  /** The list of Ids for the invoices */
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  /** The link to the invoice pdf file. */
  invoiceLink?: Maybe<Scalars['String']>;
  /** The list of links to the invoices pdf files. */
  invoiceLinks?: Maybe<Array<Scalars['String']>>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 processing data */
  l2l3Data?: Maybe<L2L3Data>;
  /** The time the payment request was last modified */
  modifiedTimestamp: Scalars['DateTime'];
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The flag to check whether the overdue reminder is sent. */
  overdueReminderSent?: Maybe<Scalars['Boolean']>;
  /** The owner of the payment request. */
  owner: Owner;
  /** The link to the payment request. */
  paymentUrl?: Maybe<Scalars['String']>;
  /** The list of payments made against this request */
  payments: Array<Maybe<Payment>>;
  /** The reference number of the payment request, must be unique for the tenant */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The flag to check whether the reminder is sent. */
  reminderSent?: Maybe<Scalars['Boolean']>;
  /** The current status of the payment request. Pending will be set while the payment is being processed */
  status?: Maybe<PaymentRequestStatus>;
  /** The current reason for the status */
  statusReason?: Maybe<Scalars['String']>;
  /** The remaining amount. */
  totalDue: Scalars['Int'];
  /** The total amount paid. */
  totalPaid: Scalars['Int'];
  /** The total amount refunded. */
  totalRefunded: Scalars['Int'];
};

/** A payment request allocation */
export type PaymentRequestAllocation = {
  __typename?: 'PaymentRequestAllocation';
  /** The amount of the payment, the value should be lowest denomination i.e. cents */
  amount: Scalars['Int'];
  /** The reason for partial payment */
  partialPaymentReason?: Maybe<Scalars['String']>;
  /** The associated payment request */
  paymentRequest?: Maybe<PaymentRequest>;
};

/** Input data for payment request amounts. */
export type PaymentRequestAllocationInput = {
  /** The amount of the payment, the value should be lowest denomination i.e. cents */
  amount: Scalars['Int'];
  /** The reason for partial payment. Maximum 200 characters */
  partialPaymentReason?: Maybe<Scalars['String']>;
  /** The payment request id */
  paymentRequestId: Scalars['ID'];
};

/** The Payment Request Communication represents a single attempt to ask for a payment */
export type PaymentRequestCommunication = {
  __typename?: 'PaymentRequestCommunication';
  communicationType: CommunicationType;
  /** The email address to send the communication */
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /**
   * Indicates whether it is the forwarded communication
   * @deprecated Please use subType
   */
  isForward?: Maybe<Scalars['Boolean']>;
  /**
   * Indicates whether it is a preview communication
   * @deprecated Please use subType
   */
  isPreview: Scalars['Boolean'];
  /** The owner of the payment. */
  owner: Owner;
  /** The phone number to send the communication */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The time the communication request was made */
  requestTimestamp: Scalars['DateTime'];
  /** The time the communication request was sent */
  sentTimestamp?: Maybe<Scalars['DateTime']>;
  /** The current status of the payment request communication. Pending will be set while sending the communication in the automatic mode. */
  status?: Maybe<PaymentRequestCommunicationStatus>;
  /** Subtype of the payment request communication. */
  subType?: Maybe<SubType>;
};

/** Possible statuses for the payment request communication. */
export enum PaymentRequestCommunicationStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Unsent = 'UNSENT'
}

/** A list of payment requests associated with the merchant. */
export type PaymentRequestConnection = {
  __typename?: 'PaymentRequestConnection';
  /** A list of edges. */
  edges: Array<Maybe<PaymentRequestEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<PaymentRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** A payment request credit allocation */
export type PaymentRequestCreditAllocation = {
  __typename?: 'PaymentRequestCreditAllocation';
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The Id for the payment request */
  paymentRequestId?: Maybe<Scalars['String']>;
  /** The reference number of the payment request */
  paymentRequestReferenceNumber?: Maybe<Scalars['String']>;
};

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type PaymentRequestEdge = {
  __typename?: 'PaymentRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PaymentRequest;
};

export type PaymentRequestFeatures = {
  __typename?: 'PaymentRequestFeatures';
  /** Allow payer to pay consolidated payment against multiple payment requests. */
  consolidatedPayment: Scalars['Boolean'];
  /** Allow payer to pay multiple/partial payment against a single payment request. */
  partialPayment: Scalars['Boolean'];
  /** Allow payer to receive payment reminder emails against a single payment request. */
  paymentReminderEmails: Scalars['Boolean'];
};

/** Input data for forwarding a payment request */
export type PaymentRequestForwardInput = {
  /** The secondary email address of the payer, destination for email forwarding. */
  email: Scalars['String'];
  /** The payment request id) */
  paymentRequestId: Scalars['String'];
};

/** Status response for mutation forwarding a payment request. */
export type PaymentRequestForwardStatus = {
  __typename?: 'PaymentRequestForwardStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** The Payment Request Info is a condensed form of the Payment Request. */
export type PaymentRequestInfo = {
  __typename?: 'PaymentRequestInfo';
  /** Accepted payment methods. */
  acceptedPaymentMethods?: Maybe<Array<AcceptedPaymentMethod>>;
  /** Additonal Information for the payment request */
  additionalInfo?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated, the more specific supportedPaymentMethods should be used. */
  allowedPaymentTypes?: Maybe<Array<PaymentType>>;
  amount: Scalars['Int'];
  currency: CurrencyType;
  /** Owner Customer ID */
  customerId?: Maybe<Scalars['ID']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Set of feature flags enabled/disabled for the tenant. */
  features?: Maybe<Features>;
  invoiceLink?: Maybe<Scalars['String']>;
  /** The list of links to the invoices pdf files. */
  invoiceLinks?: Maybe<Array<Scalars['String']>>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  merchantName: Scalars['String'];
  merchantStatementDescription: Scalars['String'];
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  paymentRequestId: Scalars['String'];
  /** The list of payments made against this request */
  payments: Array<Maybe<Payment>>;
  referenceNumber: Scalars['String'];
  refundPolicy: Scalars['String'];
  status: PaymentRequestStatus;
  statusReason?: Maybe<Scalars['String']>;
  /** Supported payment methods enabled for the tenant. */
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
  tenantId: Scalars['ID'];
  /** The remaining amount. */
  totalDue: Scalars['Int'];
  /** The total amount paid. */
  totalPaid: Scalars['Int'];
  /** The total amount refunded. */
  totalRefunded: Scalars['Int'];
};

/** Ways in which lists of payment requests can be ordered upon return */
export type PaymentRequestOrder = {
  /** The direction in which to order payment requests by the specified field */
  direction: OrderDirection;
  /** The field in which to order payment requests */
  field: PaymentRequestOrderField;
};

/** Properties by which payment requests connections can be ordered. */
export enum PaymentRequestOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Possible statuses for the payment request. */
export enum PaymentRequestStatus {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  FullyRefunded = 'FULLY_REFUNDED',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  PendingAuthorization = 'PENDING_AUTHORIZATION',
  RefundFailed = 'REFUND_FAILED',
  RefundPending = 'REFUND_PENDING',
  Unknown = 'UNKNOWN',
  Unpaid = 'UNPAID'
}

/** Details on when funds from charges are available, and when they are paid out to an external account. */
export type PaymentSchedule = {
  __typename?: 'PaymentSchedule';
  interval?: Maybe<PayoutInterval>;
};

export type PaymentSettings = {
  __typename?: 'PaymentSettings';
  /** The refund policy */
  refundPolicy?: Maybe<Scalars['String']>;
  /** The description to appear on statements */
  statementDescription?: Maybe<Scalars['String']>;
};

/** Possible values representing the state of the payment. */
export enum PaymentStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  PendingAuthorization = 'PENDING_AUTHORIZATION',
  Unknown = 'UNKNOWN'
}

/** Possible additional statuses of the payment */
export enum PaymentStatusExtended {
  Disputed = 'DISPUTED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Refunded = 'REFUNDED'
}

/** Possible values representing the type of the payment. */
export enum PaymentType {
  CardPayment = 'CARD_PAYMENT',
  EcheckPayment = 'ECHECK_PAYMENT'
}

export type PaymentsFeatures = {
  __typename?: 'PaymentsFeatures';
  /** Flag to enable/disable the convenience fee feature for the account. */
  convenienceFees?: Maybe<Scalars['Boolean']>;
  /** Flag to enable/disable the virtual terminal in the Merchant portal. */
  virtualTerminal?: Maybe<Scalars['Boolean']>;
};

/** Value describing how frequently funds will be paid out. */
export enum PayoutInterval {
  Daily = 'DAILY',
  Manual = 'MANUAL',
  Monthly = 'MONTHLY',
  Unknown = 'UNKNOWN',
  Weekly = 'WEEKLY'
}

export type PayoutReport = {
  __typename?: 'PayoutReport';
  /** The reason why the payout report creation has failed */
  failureReason?: Maybe<ReasonInfo>;
  /** The file format of the payout report */
  format?: Maybe<FileFormat>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The payout report status */
  status: ReportStatus;
  /** Publicly accessible payout report URI */
  uri?: Maybe<Scalars['ID']>;
};

export type PayoutReportItem = {
  __typename?: 'PayoutReportItem';
  /** The end date for the payout report */
  endTime: Scalars['DateTime'];
  /** The completed date for the payout report */
  payoutCompleteTime: Scalars['DateTime'];
  /** The create date for the payout report */
  payoutCreateTime: Scalars['DateTime'];
  /** The payout report id */
  payoutReportId?: Maybe<Scalars['String']>;
  /** The start date for the payout report */
  startTime: Scalars['DateTime'];
};

export type PayoutSettings = {
  __typename?: 'PayoutSettings';
  /** The last four digits of the account */
  accountLastFour?: Maybe<Scalars['String']>;
  /** The type of bank account to use for payouts */
  accountType: BankAccountType;
  /** The currency for the payouts */
  currency?: Maybe<CurrencyType>;
  /** Details on when funds from charges are available, and when they are paid out to an external account. */
  schedule?: Maybe<PaymentSchedule>;
  /** The text that appears on the bank account statement for payouts. */
  statementDescription?: Maybe<Scalars['String']>;
  /** The current status of the payout setting processing or processed */
  status: AccountPayoutsSettingsStatus;
};

/** The reason code for the pending state */
export enum PendingReasonCode {
  BankVerification = 'BANK_VERIFICATION',
  EcheckSubmission = 'ECHECK_SUBMISSION',
  PendingCapture = 'PENDING_CAPTURE',
  Processing = 'PROCESSING',
  Review = 'REVIEW',
  Unknown = 'UNKNOWN'
}

export type Person = {
  __typename?: 'Person';
  account: TenantAccount;
  /** The person’s address. */
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The person’s customer details */
  customers?: Maybe<Array<CustomerInfo>>;
  /** The person’s email address. */
  email?: Maybe<Scalars['String']>;
  /** The person’s first name. */
  firstName?: Maybe<Scalars['String']>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The person’s last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The owner of the person record. */
  owner: Owner;
  /** The person’s payer account details. */
  payerProfile?: Maybe<PayerProfile>;
  phone?: Maybe<Phone>;
  /** Describes the person’s relationship to the account. */
  relationship?: Maybe<AccountRelationship>;
  /** Information about the requirements for the individual, including what information needs to be collected, and by when. */
  requirements?: Maybe<AccountRequirements>;
  /** The timestamp when the person was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user identifier who last updated the person */
  updatedBy?: Maybe<Scalars['String']>;
  /** The persons’s verification status. */
  verification?: Maybe<AccountPersonalVerification>;
};

export type Phone = {
  __typename?: 'Phone';
  countryCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  /** @deprecated deprecated, use number instead */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Input for a phone number */
export type PhoneInputType = {
  /** the phone country code. (Constraints: Pattern: ^\+?[0-9]{1,3}$) */
  countryCode: Scalars['String'];
  /** the phone number. (Constraints: Pattern: ^[0-9]{7,14}$) */
  number: Scalars['String'];
};

/** The Preview Settings */
export type PreviewSettings = {
  __typename?: 'PreviewSettings';
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

/** The Preview Settings */
export type PreviewSettingsInput = {
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

/** Input data for processing a refund */
export type ProcessRefundInput = {
  /** The amount of the refund, if partial. Otherwise null. */
  amount?: Maybe<Scalars['Int']>;
  /** The payment id */
  paymentId: Scalars['String'];
  /** The reason for the refund */
  refundReason: Scalars['String'];
};

/** Status response for mutation for creating a refund. */
export type ProcessRefundStatus = {
  __typename?: 'ProcessRefundStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get the details from the contextual tenant account */
  account: TenantAccount;
  /** Calculates the convenience fees for the amount provided. */
  calculateConvenienceFees: CalculatedConvenienceFees;
  /** Get details of a checkout session */
  checkoutSession: CheckoutSession;
  /** NOT ACCESSIBLE VIA THE API, queries the payers credit memo usage history */
  creditMemoHistory: CreditMemoUsageConnection;
  creditMemos: CreditMemoConnection;
  customers: CustomerConnection;
  /**
   * [NOT ACCESSIBLE BY API] Get the disputes within the filter criteria.
   *       Key Information:
   *       first = the number of items from the start cursor
   *       last = no currently supported
   *       dateRange: when using a date range the start and end dates must be in this format - "2020-01-01T00:00:00Z"
   *       edges = contain the link between 2 nodes and will only work if the filter and ordering remain consistent
   *       orderBy = if missing will default to the created time descending
   */
  disputes: DisputeConnection;
  /** [NOT ACCESSIBLE BY API] Get the integration settings for the tenant. */
  integrationSettings: TenantIntegrationSettings;
  /** Get login context */
  loginContext: LoginContext;
  payerCreditMemos: PayerCreditMemoConnection;
  /** [NOT ACCESSIBLE BY API] Get the customer for the customerID */
  payerCustomer: Customer;
  /** [NOT ACCESSIBLE BY API] This transaction summary is not accessible by the API. */
  payerTransactionSummaryByMerchant: PayerTransactionSummary;
  /** [NOT ACCESSIBLE BY API] This transaction query is not accessible by the API. */
  payerTransactions: PayerTransactionConnection;
  /** Query to return payment method requests. */
  paymentMethodRequests: PaymentMethodRequestConnection;
  paymentMethods: PaymentMethodConnection;
  /** [NOT ACCESSIBLE BY API] Get the payment request from the url token included in the email or sms communication */
  paymentRequestFromUrlToken: PaymentRequestInfo;
  /**
   * Get the  payment requests within the filter criteria.
   *     Key Information:
   *     first = the number of items from the start cursor
   *     last = no currently supported
   *     dateRange: when using a date range the start and end dates must be in this format - "2020-01-01T00:00:00Z"
   *     edges = contain the link between 2 nodes and will only work if the filter and ordering remain consistent
   *     orderBy = if missing will default to the created time descending
   *
   *     An example for a query will look like:
   *
   *     query {
   *       paymentRequests (first:10, last: 0, orderBy: { direction: DESC, field:TIMESTAMP }) {
   *           pageInfo {
   *             endCursor
   *             hasNextPage
   *             hasPreviousPage
   *             startCursor
   *           }
   *           totalCount
   *           nodes {
   *             amount
   *             communications {
   *               communicationType
   *               email
   *               phoneNumber
   *               requestTimestamp
   *             }
   *             createdAt
   *             id
   *             invoiceLink
   *             referenceNumber
   *             status
   *             statusReason
   *           }
   *           edges {
   *             cursor
   *             node {
   *               id
   *             }
   *           }
   *         }
   *       }
   */
  paymentRequests: PaymentRequestConnection;
  payments: PaymentConnection;
  /** Get the payout report from the id */
  payoutReport: PayoutReport;
  /** [NOT ACCESSIBLE BY API] Get the person from their email address */
  person: Person;
  /** [NOT ACCESSIBLE BY API] Get a list of people */
  persons: Array<Person>;
  refunds: RefundConnection;
  /** Get the report from the id */
  report: Report;
  /** Get a connection to the transaction records within the filter criteria */
  transactionRecords: TransactionRecordConnection;
  viewer: Viewer;
};


export type QueryCalculateConvenienceFeesArgs = {
  amount: Scalars['Int'];
  cardType: CardType;
  customerId?: Maybe<Scalars['String']>;
};


export type QueryCheckoutSessionArgs = {
  input: CheckoutSessionInput;
};


export type QueryCreditMemoHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CreditMemoUsageHistoryOrder>;
  statusFilter?: Maybe<Array<CreditMemoStatus>>;
};


export type QueryCreditMemosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<CreditMemoDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CreditMemoOrder>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<CreditMemoStatus>>;
};


export type QueryCustomersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<CustomerDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CustomerOrder>;
  queryString?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryDisputesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<DisputeDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: DisputeOrder;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DisputeStatus>;
};


export type QueryPayerCreditMemosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: CreditMemoOrder;
  status?: Maybe<Array<CreditMemoStatus>>;
};


export type QueryPayerCustomerArgs = {
  customerId: Scalars['String'];
};


export type QueryPayerTransactionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<PaymentDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: PayerTransactionOrder;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<PaymentRequestStatus>>;
};


export type QueryPaymentMethodRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<DateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentMethodRequestOrder>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<PaymentMethodRequestStatus>>;
};


export type QueryPaymentMethodsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentMethodOrder>;
  paymentMethodType?: Maybe<PaymentMethodType>;
  queryString?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  status?: Maybe<Array<PaymentMethodStatus>>;
};


export type QueryPaymentRequestFromUrlTokenArgs = {
  urlToken: Scalars['String'];
};


export type QueryPaymentRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<PaymentDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentRequestOrder>;
  overdueReminderSent?: Maybe<Scalars['Boolean']>;
  queryString?: Maybe<Scalars['String']>;
  reminderSent?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PaymentRequestStatus>;
  statusFilter?: Maybe<Array<PaymentRequestStatus>>;
};


export type QueryPaymentsArgs = {
  after?: Maybe<Scalars['String']>;
  amountRange?: Maybe<AmountRange>;
  before?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<DateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  extendedPaymentStatus?: Maybe<Array<PaymentStatusExtended>>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PaymentOrder>;
  queryString?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<PaymentStatus>>;
};


export type QueryPayoutReportArgs = {
  format: FileFormat;
  payoutReportId: Scalars['ID'];
};


export type QueryPersonArgs = {
  email: Scalars['String'];
};


export type QueryRefundsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<RefundDateRangeType>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RefundOrder>;
  paymentId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<RefundStatus>>;
};


export type QueryReportArgs = {
  id: Scalars['String'];
};


export type QueryTransactionRecordsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: TransactionRecordOrder;
  startDate?: Maybe<Scalars['DateTime']>;
};

/** The reason details */
export type ReasonDetail = {
  __typename?: 'ReasonDetail';
  /** The reason detail code */
  code?: Maybe<Scalars['String']>;
  /** The reason detail message */
  message?: Maybe<Scalars['String']>;
};

export type ReasonInfo = {
  __typename?: 'ReasonInfo';
  /** The top level reason code */
  code?: Maybe<Scalars['String']>;
  /** additional details */
  details?: Maybe<Array<ReasonDetail>>;
  /** The top level reason */
  message?: Maybe<Scalars['String']>;
};

export type Refund = {
  __typename?: 'Refund';
  /** The amount to be refunded */
  amount?: Maybe<Scalars['Int']>;
  /** The time the refund was attempted */
  attemptTimestamp?: Maybe<Scalars['DateTime']>;
  /** The time the refund was completed */
  completedTimestamp?: Maybe<Scalars['DateTime']>;
  /** The time the refund was created */
  createTime?: Maybe<Scalars['DateTime']>;
  /** The currency used for the refund */
  currency?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Details of what failed */
  failureDetail?: Maybe<ReasonInfo>;
  /** The reason why the refund failed */
  failureReason?: Maybe<Scalars['String']>;
  /** The Id for the refund */
  id?: Maybe<Scalars['String']>;
  /** The owner */
  owner: Owner;
  /** The payment */
  payment: Payment;
  /** Returns the associated payment request for the refund */
  paymentRequest?: Maybe<PaymentRequest>;
  /** The reason why the refund is pending */
  pendingReason?: Maybe<Scalars['String']>;
  /** The reason the for the refund */
  refundReason?: Maybe<Scalars['String']>;
  /** The current state of the refund */
  status?: Maybe<RefundStatus>;
};

/** A list of refunds associated with the merchant. */
export type RefundConnection = {
  __typename?: 'RefundConnection';
  /** A list of edges. */
  edges: Array<Maybe<RefundEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<Refund>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** The refund field to query by. */
export enum RefundDateRangeType {
  RefundCompleted = 'REFUND_COMPLETED',
  RefundCreated = 'REFUND_CREATED'
}

/**
 * An edge in a connection. Think of the edge as the connection between two nodes.
 *   Its tightly coupled to the filter and order by applied to the connection, any changes to the filter or order by will invalidate the edge
 */
export type RefundEdge = {
  __typename?: 'RefundEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Refund;
};

/** Ways in which lists of payments can be ordered upon return */
export type RefundOrder = {
  /** The direction in which to order payments by the specified field */
  direction: OrderDirection;
  /** The field in which to order payments */
  field: RefundOrderField;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum RefundOrderField {
  Timestamp = 'TIMESTAMP'
}

/** Possible values for the refund status */
export enum RefundStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type RefundsFeatures = {
  __typename?: 'RefundsFeatures';
  /** Allow merchants to process refunds from payout account. */
  skipAccountBalanceCheck: Scalars['Boolean'];
};

/** Input data for removing a customer user */
export type RemoveCustomerUserInput = {
  /** the customer user id */
  customerId: Scalars['String'];
  /** the Id of the customer user */
  email: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  /** The report created date */
  createdAt: Scalars['DateTime'];
  /** The report end date */
  downloadUrl?: Maybe<Scalars['String']>;
  /** The report end date */
  endDate: Scalars['DateTime'];
  /** The report expiration date */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** The reason why the report creation has failed */
  failureReason?: Maybe<ReasonInfo>;
  /** The file format of the report */
  format: FileFormat;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** The owner of the person record. */
  owner: Owner;
  /** The file format of the report */
  reportType: ReportType;
  /** The report start date */
  startDate: Scalars['DateTime'];
  /** The report status */
  status: ReportStatus;
  /** The timestamp when the person was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The status of the report */
export enum ReportStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

/** The type of report being generated */
export enum ReportType {
  Payment = 'PAYMENT'
}

/** Input data for resending payment receipt */
export type ResendPaymentReceiptInput = {
  /** Id of the payment */
  paymentId: Scalars['String'];
};

/** Status response for resend payment receipt mutation. */
export type ResendPaymentReceiptStatus = {
  __typename?: 'ResendPaymentReceiptStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for Level 2/Level 3 data processing. */
export type RiskMetadataPaymentInput = {
  /** The address of the payer. */
  address: AddressInputType;
  /** The line items that were paid for, if any. */
  lineItems: Array<Maybe<RiskMetadataPaymentLineItemInput>>;
  /** The phone number of the payer. */
  phone: PhoneInputType;
  /** The sales order number or other id associated with this payment. (Constraints: Minimum Length: 1, Maximum Length: 255) */
  salesOrderNumber?: Maybe<Scalars['String']>;
};

/** Input data for Level 2/Level 3 data processing. */
export type RiskMetadataPaymentLineItemInput = {
  /** The currency of the line item price. */
  currency: CurrencyType;
  /** The description of the line item. (Constraints: Maximum Length: 2047) */
  description: Scalars['String'];
  /** The price of the line item in the currency lowest denomination. */
  price: Scalars['Int'];
  /** The quantity of the line item. */
  quantity: Scalars['Int'];
};

/** The security type of email settings for tenant account. */
export enum SecurityType {
  Ssl = 'SSL',
  Tls = 'TLS'
}

/** The Send Merchant Email Options */
export type SendMerchantEmailOptions = {
  __typename?: 'SendMerchantEmailOptions';
  email?: Maybe<Scalars['String']>;
  /** Indicates if an email needs to be sent on completion. */
  sendEmailOnCompletion?: Maybe<Scalars['Boolean']>;
};

/** Input for SendMerchantEmailOptions */
export type SendMerchantEmailOptionsInput = {
  email?: Maybe<Scalars['String']>;
  /** Indicates if an email needs to be sent on completion. */
  sendEmailOnCompletion?: Maybe<Scalars['Boolean']>;
};

/** Input data for sending test email. */
export type SendTestEmailInput = {
  /** Email Id on which user want to send test email. */
  email: Scalars['String'];
};

/** Status response for sending test email. */
export type SendTestEmailStatus = {
  __typename?: 'SendTestEmailStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Status response for mutation for upserting a statement URL. */
export type StatementAccessUrlRequestStatus = {
  __typename?: 'StatementAccessURLRequestStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  statementUrl?: Maybe<Scalars['String']>;
};

/** Payment request communication subtype */
export enum SubType {
  Forward = 'FORWARD',
  Original = 'ORIGINAL',
  Overdue = 'OVERDUE',
  Preview = 'PREVIEW',
  Reminder = 'REMINDER',
  Resend = 'RESEND',
  Unknown = 'UNKNOWN'
}

/** Input data for sending account verification document tokens. */
export type SubmitVerificationInput = {
  documentTokens: Array<DocumentTokenInput>;
};

/** Status response for mutation for upserting a tenant account. */
export type SubmitVerificationStatus = {
  __typename?: 'SubmitVerificationStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type TenantAccount = {
  __typename?: 'TenantAccount';
  /** Accepted payment methods for this tenant */
  acceptedPaymentMethods?: Maybe<Array<AcceptedPaymentMethod>>;
  /** the balance based on the currency */
  balances?: Maybe<AccountBalances>;
  /** the business profile for the tenant account */
  businessProfile?: Maybe<BusinessProfile>;
  /** the type of business */
  businessType?: Maybe<BusinessType>;
  /** The set of capabilities for this account and their associated states. Keys are names of capabilities. Values may be ACTIVE, INACTIVE, or PENDING */
  capabilities?: Maybe<TenantAccountCapabilities>;
  /** Information about the company or business. This field is null unless businessType is set to COMPANY, GOVT_ENTITY, or NON_PROFIT. */
  company?: Maybe<Company>;
  /** The account’s country */
  country?: Maybe<Scalars['String']>;
  /** The timestamp when the tenant account was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The name of the user that created the tenant account */
  createdBy?: Maybe<Scalars['String']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Three-letter ISO currency code representing the default currency for the account. */
  defaultCurrency?: Maybe<CurrencyType>;
  /** The primary account holder’s email address */
  email?: Maybe<Scalars['String']>;
  /** Any errors that associated with this tenant account */
  errors?: Maybe<Array<Scalars['String']>>;
  /** Url of the fee schedule */
  feeSchedule?: Maybe<Scalars['String']>;
  /** Unique identifier for the object */
  id: Scalars['ID'];
  /** Summary of the merchant payer. */
  merchantSummary?: Maybe<TenantMerchantSummary>;
  /** The owner of the tenant account. */
  owner: Owner;
  /** The Aptean ERP or service that owns the tenant */
  owningProduct?: Maybe<ApteanProduct>;
  /** The payment facilitator. */
  payFacType: PayFac;
  /**
   * The tenants payer account details.
   * @deprecated Deprecated, Use paymentMethods query instead.
   */
  payerProfile?: Maybe<PayerProfile>;
  /** The ids of the payment facilitator. */
  payfac?: Maybe<PayFacIds>;
  /** A list of the payout report items for the account */
  payoutReports?: Maybe<Array<PayoutReportItem>>;
  /** Information about the requirements for the account, including what information needs to be collected, and by when. */
  requirements?: Maybe<AccountRequirements>;
  /** Options for customizing how the account functions. */
  settings?: Maybe<AccountSettings>;
  /** A list of the transaction statements for the account  */
  statements?: Maybe<Array<TransactionStatement>>;
  /** Details on acceptance of the EZPay Services Agreement. */
  tosAcceptance?: Maybe<TosAcceptance>;
  /** The timestamp when the tenant account document was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user that last updated the tenant account document */
  updatedBy?: Maybe<Scalars['String']>;
};

export type TenantAccountCapabilities = {
  __typename?: 'TenantAccountCapabilities';
  accountPayouts: CapabilityStatus;
  achPayments: CapabilityStatus;
  cardPayments: CapabilityStatus;
};

export type TenantAccountEmailSettings = {
  __typename?: 'TenantAccountEmailSettings';
  /** Defines whether the email settings enabled or not for tenant account. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Custom text to be used within all payment requests. This can be overridden by setting the additional info on individual payment requests. */
  paymentRequestDefaultText?: Maybe<Scalars['String']>;
  /** The email settings profile for tenant account. */
  profile?: Maybe<TenantAccountEmailSettingsProfile>;
};

/** The email settings profile for tenant account. */
export type TenantAccountEmailSettingsProfile = {
  __typename?: 'TenantAccountEmailSettingsProfile';
  /** The from address of email settings for tenant account. */
  from: Scalars['String'];
  /** The host of email settings for tenant account. */
  host: Scalars['String'];
  /** The password of email settings for tenant account. */
  password: Scalars['String'];
  /** The port of email settings for tenant account. */
  port: Scalars['Int'];
  /** The security type of email settings for tenant account. */
  securityType: SecurityType;
  /** The username of email settings for tenant account. */
  username: Scalars['String'];
};

/** Input data for updating the SMTP email profile for tenant account. */
export type TenantAccountEmailSettingsProfileInput = {
  /** The default from address to send the emails. */
  from: Scalars['String'];
  /** The host to use for the SMTP service. */
  host: Scalars['String'];
  /** The host user password. */
  password: Scalars['String'];
  /** The port to use on the host. */
  port: Scalars['Int'];
  /** The security protocol of the host SMTP service. */
  securityType: SecurityType;
  /** The username for the host. */
  username: Scalars['String'];
};

export type TenantIntegrationSettings = {
  __typename?: 'TenantIntegrationSettings';
  /** List of integration settings. */
  data?: Maybe<Array<KeyValuePair>>;
  /** The owner of the tenant integration settings. */
  owner: Owner;
  /** The payment facilitator type. */
  payFacType: PayFac;
};

/** A tenants merchant summary */
export type TenantMerchantSummary = {
  __typename?: 'TenantMerchantSummary';
  /** Payments due today for the tenant */
  paymentsDueToday?: Maybe<Scalars['Int']>;
};

/** The capabilities supported by the tenant. */
export type TenantSupportedCapabilities = {
  __typename?: 'TenantSupportedCapabilities';
  /** Whether Account Balance is supported */
  accountBalance: Scalars['Boolean'];
  /** Whether Account Status is supported */
  accountStatus: Scalars['Boolean'];
  /** Whether Basic Account Status is supported */
  basicAccountStatus: Scalars['Boolean'];
  /** Whether Business Owners are supported */
  businessOwners: Scalars['Boolean'];
  /** Whether Account Description is supported */
  description: Scalars['Boolean'];
  /** Whether Disputes are supported */
  disputes: Scalars['Boolean'];
  /** Whether Entity Type is supported */
  entityType: Scalars['Boolean'];
  /** Whether Fee Schedule is supported */
  feeSchedule: Scalars['Boolean'];
  /** Whether Legal Entity Name is supported */
  legalEntityName: Scalars['Boolean'];
  /** Whether Payout Reports are supported */
  payoutReports: Scalars['Boolean'];
  /** Whether Payouts are supported */
  payouts: Scalars['Boolean'];
  /** Whether Refund Policy is supported */
  refundPolicy: Scalars['Boolean'];
  /** Whether Statement Description is supported */
  statementDescription: Scalars['Boolean'];
  /** Whether Statements are supported */
  statements: Scalars['Boolean'];
  /** Whether Website is supported */
  website: Scalars['Boolean'];
};

export type Token = {
  __typename?: 'Token';
  /** IP address of the client that generated the token. */
  clientIp?: Maybe<Scalars['String']>;
  /** Time at which the object was created. */
  createTime: Scalars['DateTime'];
  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   * @deprecated deprecated, use createTime instead.
   */
  created: Scalars['Float'];
  /** The credit card details. */
  creditCard?: Maybe<CreditCard>;
  /** The token id. */
  id: Scalars['String'];
  /** Indicates if the token is for the live environment. */
  livemode: Scalars['Boolean'];
  /** The status of payment method. */
  status?: Maybe<PaymentMethodStatus>;
  /** The payment method type */
  type: Scalars['String'];
  /** Indicates if the token has been used. */
  used: Scalars['Boolean'];
};

export type TosAcceptance = {
  __typename?: 'TosAcceptance';
  /** The timestamp marking when the EZPay Services Agreement was accepted by the primary account holder. */
  date?: Maybe<Scalars['DateTime']>;
  /** The IP address from which the EZPay Agreement was accepted by the account representative. */
  ip?: Maybe<Scalars['String']>;
  /** The user agent of the browser from which the EZPay Agreement was accepted by the account representative. */
  userAgent?: Maybe<Scalars['String']>;
};

export type TransactionRecord = {
  __typename?: 'TransactionRecord';
  createdAt: Scalars['DateTime'];
  /** The currency of the transaction */
  currency: CurrencyType;
  /** The fee amount applied to the transaction */
  feeAmount: Scalars['Int'];
  /** The gross amount associated with the transaction */
  grossAmount: Scalars['Int'];
  /** The id of the transaction */
  id: Scalars['String'];
  /** The net amount associated with the transaction (gross - fee = net) */
  netAmount: Scalars['Int'];
  /** The owner of the transaction. */
  owner: Owner;
  /** The type of transaction */
  transactionType: TransactionRecordType;
};

/** A list of transaction records. */
export type TransactionRecordConnection = {
  __typename?: 'TransactionRecordConnection';
  /** A list of edges. */
  edges: Array<Maybe<TransactionRecordEdge>>;
  /** The item at the end of each edge in the edges collection. */
  nodes: Array<Maybe<TransactionRecord>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TransactionRecordEdge = {
  __typename?: 'TransactionRecordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TransactionRecord;
};

/** Ways in which lists of transaction records can be ordered upon return */
export type TransactionRecordOrder = {
  /** The direction in which to order transaction records by the specified field */
  direction: OrderDirection;
  /** The field in which to order transaction records */
  field: TransactionRecordOrderField;
};

/** The field to use when sorting transaction records */
export enum TransactionRecordOrderField {
  Timestamp = 'TIMESTAMP'
}

/** The type of transaction record. */
export enum TransactionRecordType {
  Adjustment = 'ADJUSTMENT',
  AppFee = 'APP_FEE',
  AppFeeChargeback = 'APP_FEE_CHARGEBACK',
  AppFeeChargebackReversal = 'APP_FEE_CHARGEBACK_REVERSAL',
  AppFeeRefund = 'APP_FEE_REFUND',
  MerchantChargeback = 'MERCHANT_CHARGEBACK',
  MerchantChargebackFee = 'MERCHANT_CHARGEBACK_FEE',
  MerchantChargebackReversal = 'MERCHANT_CHARGEBACK_REVERSAL',
  MerchantPayment = 'MERCHANT_PAYMENT',
  MerchantPaymentRefund = 'MERCHANT_PAYMENT_REFUND',
  Payout = 'PAYOUT',
  PayoutReturn = 'PAYOUT_RETURN',
  Recovery = 'RECOVERY',
  RecoveryReturn = 'RECOVERY_RETURN',
  Unknown = 'UNKNOWN'
}

export type TransactionStatement = {
  __typename?: 'TransactionStatement';
  /** Available statement transaction formats */
  availableFormats?: Maybe<Array<TransactionStatementAvailableFormat>>;
  /** The date the statement was created */
  createdAt: Scalars['DateTime'];
  /** The end date for the statements transactions */
  endTime: Scalars['DateTime'];
  /** The start date for the statements transactions */
  startTime: Scalars['DateTime'];
  /**
   * The URL to download the statements
   * @deprecated Use the availableFormats property on this type instead.
   */
  url: Scalars['String'];
};

export type TransactionStatementAvailableFormat = {
  __typename?: 'TransactionStatementAvailableFormat';
  /** The file name of the statement */
  fileName: Scalars['String'];
  /** The file format of the statements */
  format: FileFormat;
  /** The URL to download the statements */
  url: Scalars['String'];
};

/** Values for the state of PayFac transition for a company */
export enum TransitionStatus {
  Completed = 'COMPLETED',
  None = 'NONE',
  Pending = 'PENDING',
  Requested = 'REQUESTED'
}

/** Input data for updating the convenience fees. */
export type UpdateConvenienceFeesInput = {
  /** The credit card rate in basis points. Ex: '200' for 2% */
  creditCardRateBps?: Maybe<Scalars['Int']>;
  /** The debit card rate in basis points. Ex: '200' for 2% */
  debitCardRateBps?: Maybe<Scalars['Int']>;
};

/** Status response for updating the Convenience fees. */
export type UpdateConvenienceFeesStatus = {
  __typename?: 'UpdateConvenienceFeesStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for conceding a dispute */
export type UpdateDisputeInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The document ids uploaded. (Constraints: Maximum Items: 5, Minimum Length: 1, Maximum Length: 255) */
  documents: Array<Scalars['String']>;
  /** Text explaining why they are challenging the dispute. (Constraints: Maximum Length: 65535) */
  explanation: Scalars['String'];
  /** The dispute id */
  id: Scalars['ID'];
};

/** Input data for updating the integration settings. */
export type UpdateIntegrationSettingsInput = {
  /** The integration mode */
  mode: IntegrationMode;
  /** The settings for the preview mode */
  previewSettings: PreviewSettingsInput;
  /** The flag indicates whether to process unsent payment request communications */
  processUnsentCommunications?: Maybe<Scalars['Boolean']>;
};

/** Status response for updating the integration settings. */
export type UpdateIntegrationSettingsStatus = {
  __typename?: 'UpdateIntegrationSettingsStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for updating a payment method */
export type UpdatePaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Indicates if this payment method is the default for the payer. */
  isDefault: Scalars['Boolean'];
  /** The payment method id */
  paymentMethodId: Scalars['ID'];
  /** The id of the person or tenant to which payment method is attached. */
  resourceId: Scalars['String'];
  /** Indicates if this payment method is shared with merchant. */
  shareWithMerchant?: Maybe<Scalars['Boolean']>;
};

/** Status response for mutation for updating a payment method. */
export type UpdatePaymentMethodStatus = {
  __typename?: 'UpdatePaymentMethodStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Input data for updating the payment reminder settings. */
export type UpdatePaymentReminderSettingsInput = {
  /** Number of days before due date for which reminder would be sent. */
  dueDateReminderDays?: Maybe<Scalars['Int']>;
  /** Number of days after due date for which reminder would be sent. */
  overdueReminderDays?: Maybe<Scalars['Int']>;
  /** Message for reminders after due date. */
  overdueReminderMessage?: Maybe<Scalars['String']>;
  /** Message for reminders before due date. */
  reminderMessage?: Maybe<Scalars['String']>;
  /** The flag indicates whether to send reminders before due date. */
  sendDueDateReminder: Scalars['Boolean'];
  /** The flag indicates whether to send reminders after due date. */
  sendOverdueReminder: Scalars['Boolean'];
};

/** Status response for updating the Payment Reminder Settings. */
export type UpdatePaymentReminderSettingsStatus = {
  __typename?: 'UpdatePaymentReminderSettingsStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Status response for mutation for updating payout settings. */
export type UpdatePayoutSettingsStatus = {
  __typename?: 'UpdatePayoutSettingsStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Input data for updating the SMTP email settings for tenant account. */
export type UpdateTenantAccountEmailSettingsInput = {
  /** Defines whether the email settings enabled or not for tenant account. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Custom text to be used within all payment requests. This can be overridden by setting the additional info on individual payment requests. */
  paymentRequestDefaultText?: Maybe<Scalars['String']>;
  /** Input data for updating the SMTP email profile for tenant account. */
  profile?: Maybe<TenantAccountEmailSettingsProfileInput>;
};

/** Status response for mutation for updating email settings for tenant account. */
export type UpdateTenantAccountEmailSettingsStatus = {
  __typename?: 'UpdateTenantAccountEmailSettingsStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Input data for updating transition status for tenant account. */
export type UpdateTenantAccountTransitionStatusInput = {
  /** The current status. */
  transitionStatus: TransitionStatus;
};


/** Input data for uploading images */
export type UploadImageInput = {
  file: Scalars['Upload'];
  imageType: ImageType;
};

/** Input data for uploading files. */
export type UploadInput = {
  file: Scalars['Upload'];
};

/** Input data for creating a credit memo */
export type UpsertCreditMemoInput = {
  /** The amount of the credit memo, the value should be lowest denomination i.e. cents. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** The currency used for the credit memo */
  currency?: Maybe<CurrencyType>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format */
  customData?: Maybe<Scalars['JSONObject']>;
  /** The list of associated payers */
  customerAllocation?: Maybe<Array<CustomerAllocationInput>>;
  /** The Id for the credit memo */
  id?: Maybe<Scalars['String']>;
  /** The reason for the credit memo */
  reason?: Maybe<Scalars['String']>;
  /** The reference number of the credit memo */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The status of the credit memo, can only be used to Close an existing credit memo. */
  status?: Maybe<CreditMemoStatus>;
  /** The current reason for the status */
  statusReason?: Maybe<Scalars['String']>;
};

/** Status response for mutation for upserting a credit memo. */
export type UpsertCreditMemoStatus = {
  __typename?: 'UpsertCreditMemoStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The credit memo */
  creditMemo?: Maybe<CreditMemo>;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for upserting a customer */
export type UpsertCustomerInput = {
  /** The customer’s primary email addresses. */
  adminEmailIds?: Maybe<Array<Scalars['String']>>;
  /** The customer conveninence fees. */
  convenienceFees?: Maybe<CustomerConvenienceFeesInput>;
  /** The customer number, must be unique for the tenant */
  customerNumber?: Maybe<Scalars['String']>;
  /** the customer id */
  id?: Maybe<Scalars['ID']>;
  /** the customer name */
  name?: Maybe<Scalars['String']>;
  /** The customer’s phone number. */
  phone?: Maybe<PhoneInputType>;
  /** Flag, whether we would like to notify new customer user with email. */
  sendEmail?: Maybe<Scalars['Boolean']>;
};

/** Status response mutation for for upserting a customer */
export type UpsertCustomerStatus = {
  __typename?: 'UpsertCustomerStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  customer?: Maybe<Customer>;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  id?: Maybe<Scalars['String']>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
};

/** Input data for upserting a customer user */
export type UpsertCustomerUserInput = {
  /** the Id of the customer */
  customerId: Scalars['String'];
  /** the users email address */
  email: Scalars['String'];
  /** the users role */
  role: CustomerRole;
  /** Flag, whether we would like to notify new customer user with email */
  sendEmail?: Maybe<Scalars['Boolean']>;
};

/** Input data for upserting a person / user for a payer account */
export type UpsertPayerInput = {
  /** the email address of the user being updated or created. Once set the email can not be changed. */
  email?: Maybe<Scalars['String']>;
  /** the first name of the user */
  firstName?: Maybe<Scalars['String']>;
  /** the users id */
  id?: Maybe<Scalars['ID']>;
  /** the last name of the user */
  lastName?: Maybe<Scalars['String']>;
  /** the phone country code */
  phoneCountryCode?: Maybe<Scalars['String']>;
  /** the phone number */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Input data for creating Payment Method Request */
export type UpsertPaymentMethodRequestInput = {
  /** ID of the customer. */
  customerId: Scalars['ID'];
  /** The Payment Method Request ID. */
  id?: Maybe<Scalars['ID']>;
  paymentAttempts?: Maybe<Array<PaymentAttemptInput>>;
  /** Textual reason the merchant is requesting new card. */
  reason?: Maybe<Scalars['String']>;
  /** Resend payment method request email */
  resendEmail?: Maybe<Scalars['Boolean']>;
  /** Restriction on request while completing. */
  restriction?: Maybe<PaymentMethodRequestRestriction>;
  sendMerchantEmailOptions?: Maybe<SendMerchantEmailOptionsInput>;
  /** Only support setting to "CLOSED" */
  status?: Maybe<PaymentMethodRequestStatus>;
  /** Only needed when closing request. */
  statusReason?: Maybe<Scalars['String']>;
  supportedPaymentMethods?: Maybe<Array<PaymentMethodType>>;
};

/** Status response for mutation for upserting a payment method request. */
export type UpsertPaymentMethodRequestStatus = {
  __typename?: 'UpsertPaymentMethodRequestStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  /** The payment method request */
  paymentMethodRequest?: Maybe<PaymentMethodRequest>;
};

/** Input data for upserting a payment request */
export type UpsertPaymentRequestInput = {
  /** Additional information for the payment request. (Constraints: Maximum Length: 400) */
  additionalInfo?: Maybe<Scalars['String']>;
  /** (Required) The amount of money to request, currency is defined in the tenant account. Required to create a payment request. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** Customer number */
  customerNumber?: Maybe<Scalars['String']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** (Required) The email address of the payer, destination for the email communication. Required to create a payment request. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The payment request id */
  id?: Maybe<Scalars['ID']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** The reference id of the uploaded invoice */
  invoiceRef?: Maybe<Scalars['String']>;
  /** List of reference id of the uploaded invoices (Max 100 invoices allowed) */
  invoiceRefs?: Maybe<Array<Scalars['String']>>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The phone number of the payer, destination for the sms communication. Format is E.164 format, for example +14155552671 */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Merchant-specific reference number. Required to create a payment request. (Constraints: Maximum Length: 255) */
  referenceNumber?: Maybe<Scalars['String']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendCommunication?: Maybe<Scalars['Boolean']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendManualCommunication?: Maybe<Scalars['Boolean']>;
  /** The status of the payment request, can only set to CLOSED or COMPLETED for existing payment requests */
  status?: Maybe<PaymentRequestStatus>;
  /** The reason the status is changing, required if updating the status to CLOSED or COMPLETED */
  statusReason?: Maybe<Scalars['String']>;
  /** Method of payment request. Either email and / or sms. */
  type?: Maybe<CommunicationType>;
};

/** Input data for upserting a payment request with no invoice */
export type UpsertPaymentRequestNoInvoiceInput = {
  /** Additional information for the payment request. (Constraints: Maximum Length: 400) */
  additionalInfo?: Maybe<Scalars['String']>;
  /** (Required) The amount of money to request, currency is defined in the tenant account. Required to create a payment request. (Constraints: >= 100) */
  amount?: Maybe<Scalars['Int']>;
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  /** Customer ID */
  customerId?: Maybe<Scalars['String']>;
  /** Customer number */
  customerNumber?: Maybe<Scalars['String']>;
  /** Customer PO number */
  customerPONumber?: Maybe<Scalars['String']>;
  /** The amount of money to deduct from the total amount due, if paid on or before the discount end date. */
  discountAmount?: Maybe<Scalars['Int']>;
  /** The date till the discount is applicable. */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** The payment request due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** (Required) The email address of the payer, destination for the email communication. Required to create a payment request. (Constraints: Maximum Length: 254) */
  email?: Maybe<Scalars['String']>;
  /** The payment request id */
  id?: Maybe<Scalars['ID']>;
  /** Invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Level 2/Level 3 data. */
  l2l3Data?: Maybe<L2L3DataInput>;
  /** Order line items */
  orderLineItems?: Maybe<Array<OrderLineItemInput>>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** The phone number of the payer, destination for the sms communication. Format is E.164 format, for example +14155552671 */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Merchant-specific reference number. Required to create a payment request. (Constraints: Maximum Length: 255) */
  referenceNumber?: Maybe<Scalars['String']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendCommunication?: Maybe<Scalars['Boolean']>;
  /** Whether to send an email or sms when updating the payment request. only applicable when updating an existing request */
  sendManualCommunication?: Maybe<Scalars['Boolean']>;
  /** The status of the payment request, can only set to CLOSED or COMPLETED for existing payment requests */
  status?: Maybe<PaymentRequestStatus>;
  /** The reason the status is changing, required if updating the status to CLOSED or COMPLETED */
  statusReason?: Maybe<Scalars['String']>;
  /** Method of payment request. Either email and / or sms. */
  type?: Maybe<CommunicationType>;
};

/** Status response for mutation for upserting a payment request. */
export type UpsertPaymentRequestStatus = {
  __typename?: 'UpsertPaymentRequestStatus';
  code: MutationStatusCode;
  customerOption?: Maybe<CustomerOption>;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentRequestId?: Maybe<Scalars['ID']>;
  paymentUrl?: Maybe<Scalars['String']>;
};

/** Input data for updating the payout schedule frequency. */
export type UpsertPayoutFrequencyInput = {
  interval: PayoutInterval;
};

/** Status response for mutation for upserting a payout frequency schedule. */
export type UpsertPayoutFrequencyStatus = {
  __typename?: 'UpsertPayoutFrequencyStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Input data for creating a payout settings using a token. */
export type UpsertPayoutSettingsInput = {
  /** Input data for updating the payout schedule frequency. */
  interval?: Maybe<PayoutInterval>;
  token: Scalars['String'];
};

/** Status response for mutation for upserting a payout setting. */
export type UpsertPayoutSettingsStatus = {
  __typename?: 'UpsertPayoutSettingsStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Input data for upserting a person */
export type UpsertPersonInput = {
  /** whether to flag the user as deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** the users email address */
  email: Scalars['String'];
  /** the users first name */
  firstName?: Maybe<Scalars['String']>;
  /** the users id */
  id?: Maybe<Scalars['ID']>;
  /** the users last name */
  lastName?: Maybe<Scalars['String']>;
  /** the users role */
  role?: Maybe<AppRole>;
};

/** Status response mutation for for upserting a person */
export type UpsertPersonStatus = {
  __typename?: 'UpsertPersonStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  person?: Maybe<Person>;
};

/** Input data for creating a tenant account. */
export type UpsertTenantAccountInput = {
  /** (Constraints: Maximum Length: 65535) */
  accountDescription?: Maybe<Scalars['String']>;
  /** (Constraints: Minimum Length: 1, Maximum Length: 255) */
  accountName?: Maybe<Scalars['String']>;
  businessUrl?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  /** High resolution logo image url */
  highResLogoUrl?: Maybe<Scalars['String']>;
  /** The url for the merchant logo */
  logoUrl?: Maybe<Scalars['String']>;
  primaryAccountHolderEmail?: Maybe<Scalars['String']>;
  refundPolicy?: Maybe<Scalars['String']>;
  /** Indicates whether payers are required to provide a reason for partial payments. Maximum length: 200 */
  requirePartialPaymentReason?: Maybe<Scalars['Boolean']>;
  statementDescription?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tosAcceptanceTime?: Maybe<Scalars['DateTime']>;
  tosAcceptanceUserAgent?: Maybe<Scalars['String']>;
};

/** Status response for mutation for upserting a tenant account. */
export type UpsertTenantAccountStatus = {
  __typename?: 'UpsertTenantAccountStatus';
  code: MutationStatusCode;
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Input data for verifying payment method microdeposits */
export type VerifyPaymentMethodInput = {
  /** Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  customData?: Maybe<Scalars['JSONObject']>;
  microDeposits: Array<Scalars['Int']>;
  paymentMethodId: Scalars['ID'];
};

/** Status response mutation for for verifying payment methods */
export type VerifyPaymentMethodStatus = {
  __typename?: 'VerifyPaymentMethodStatus';
  /** Whether the mutation was successful or failed */
  code: MutationStatusCode;
  /** The error message if it failed */
  error?: Maybe<Scalars['String']>;
  /** Additional details for errors */
  errorReason?: Maybe<ReasonInfo>;
  /** The message to explaing the status code */
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type Viewer = {
  __typename?: 'Viewer';
  currentUser?: Maybe<Person>;
};

/** The virtual terminal mode */
export enum VirtualTerminalMode {
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type UpdateDisputeMutationVariables = Exact<{
  input: UpdateDisputeInput;
}>;


export type UpdateDisputeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDispute'>
);

export type ConcedeDisputeMutationVariables = Exact<{
  input: ConcedeDisputeInput;
}>;


export type ConcedeDisputeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'concedeDispute'>
);

export type ConvertPayfacPaymentMethodTokenMutationVariables = Exact<{
  input: ConvertPayfacPaymentMethodTokenInput;
}>;


export type ConvertPayfacPaymentMethodTokenMutation = (
  { __typename?: 'Mutation' }
  & { convertPayfacPaymentMethodToken: (
    { __typename?: 'ConvertPayfacPaymentMethodTokenStatus' }
    & Pick<ConvertPayfacPaymentMethodTokenStatus, 'code' | 'message' | 'error'>
    & { token?: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'id' | 'clientIp' | 'created' | 'livemode' | 'type' | 'used'>
    )> }
  ) }
);

export type CreatePaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: (
    { __typename?: 'CreatePaymentStatus' }
    & Pick<CreatePaymentStatus, 'code' | 'message' | 'error'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'amount' | 'amountDisputed' | 'amountRefunded' | 'attemptTimestamp' | 'cancelReason' | 'completedTimestamp' | 'currency' | 'failureReason' | 'feeAmount' | 'id' | 'initiatedBy' | 'pendingReason' | 'pendingReasonCode' | 'status'>
    )> }
  ) }
);

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentMethod: (
    { __typename?: 'CreatePaymentMethodStatus' }
    & Pick<CreatePaymentMethodStatus, 'code' | 'message' | 'error'>
    & { paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'isLongLived' | 'createdAt' | 'createdBy'>
      & { creditCard?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'cardBrand' | 'lastFour' | 'expirationMonth' | 'expirationYear'>
        & { cardHolder?: Maybe<(
          { __typename?: 'PaymentMethodHolder' }
          & Pick<PaymentMethodHolder, 'holderName' | 'email'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'line1' | 'line2' | 'postalCode' | 'country'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type CreateReportMutationVariables = Exact<{
  input: CreateReportInput;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'CreateReportStatus' }
    & Pick<CreateReportStatus, 'code' | 'error' | 'message'>
    & { report?: Maybe<(
      { __typename?: 'Report' }
      & Pick<Report, 'id'>
    )> }
  ) }
);

export type DeletePersonMutationVariables = Exact<{
  input: DeletePersonInput;
}>;


export type DeletePersonMutation = (
  { __typename?: 'Mutation' }
  & { deletePerson: (
    { __typename?: 'DeletePersonStatus' }
    & Pick<DeletePersonStatus, 'code' | 'message' | 'error'>
  ) }
);

export type PaymentRequestForwardMutationVariables = Exact<{
  input: PaymentRequestForwardInput;
}>;


export type PaymentRequestForwardMutation = (
  { __typename?: 'Mutation' }
  & { paymentRequestForward: (
    { __typename?: 'PaymentRequestForwardStatus' }
    & Pick<PaymentRequestForwardStatus, 'code' | 'error' | 'message'>
  ) }
);

export type GenerateStatementAccessUrlMutationVariables = Exact<{
  input: FileName;
}>;


export type GenerateStatementAccessUrlMutation = (
  { __typename?: 'Mutation' }
  & { generateStatementAccessUrl: (
    { __typename?: 'StatementAccessURLRequestStatus' }
    & Pick<StatementAccessUrlRequestStatus, 'code' | 'error' | 'message' | 'statementUrl'>
  ) }
);

export type CreateRefundMutationVariables = Exact<{
  input: CreateRefundInput;
}>;


export type CreateRefundMutation = (
  { __typename?: 'Mutation' }
  & { createRefund: (
    { __typename?: 'CreateRefundStatus' }
    & Pick<CreateRefundStatus, 'code' | 'error' | 'message'>
  ) }
);

export type ResendPaymentReceiptMutationVariables = Exact<{
  input: ResendPaymentReceiptInput;
}>;


export type ResendPaymentReceiptMutation = (
  { __typename?: 'Mutation' }
  & { resendPaymentReceipt: (
    { __typename?: 'ResendPaymentReceiptStatus' }
    & Pick<ResendPaymentReceiptStatus, 'code' | 'message' | 'error'>
    & { errorReason?: Maybe<(
      { __typename?: 'ReasonInfo' }
      & Pick<ReasonInfo, 'code' | 'message'>
      & { details?: Maybe<Array<(
        { __typename?: 'ReasonDetail' }
        & Pick<ReasonDetail, 'code' | 'message'>
      )>> }
    )> }
  ) }
);

export type SendTestEmailMutationVariables = Exact<{
  input: SendTestEmailInput;
}>;


export type SendTestEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendTestEmail: (
    { __typename?: 'SendTestEmailStatus' }
    & Pick<SendTestEmailStatus, 'code' | 'message' | 'error'>
    & { errorReason?: Maybe<(
      { __typename?: 'ReasonInfo' }
      & Pick<ReasonInfo, 'code' | 'message'>
      & { details?: Maybe<Array<(
        { __typename?: 'ReasonDetail' }
        & Pick<ReasonDetail, 'code' | 'message'>
      )>> }
    )> }
  ) }
);

export type SubmitVerificationMutationVariables = Exact<{
  input: SubmitVerificationInput;
}>;


export type SubmitVerificationMutation = (
  { __typename?: 'Mutation' }
  & { submitVerification: (
    { __typename?: 'SubmitVerificationStatus' }
    & Pick<SubmitVerificationStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpdateTenantAccountEmailSettingsMutationVariables = Exact<{
  input: UpdateTenantAccountEmailSettingsInput;
}>;


export type UpdateTenantAccountEmailSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantAccountEmailSettings: (
    { __typename?: 'UpdateTenantAccountEmailSettingsStatus' }
    & Pick<UpdateTenantAccountEmailSettingsStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UploadDocumentMutationVariables = Exact<{
  input: UploadInput;
}>;


export type UploadDocumentMutation = (
  { __typename?: 'Mutation' }
  & { upload: (
    { __typename?: 'MediaStatus' }
    & Pick<MediaStatus, 'uniqueId'>
  ) }
);

export type UploadDocument2MutationVariables = Exact<{
  input: UploadInput;
}>;


export type UploadDocument2Mutation = (
  { __typename?: 'Mutation' }
  & { upload2: (
    { __typename?: 'MediaStatus2' }
    & Pick<MediaStatus2, 'uniqueId'>
  ) }
);

export type UploadImageMutationVariables = Exact<{
  input: UploadImageInput;
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage: (
    { __typename?: 'ImageStatus' }
    & Pick<ImageStatus, 'uniqueId' | 'imageUri'>
  ) }
);

export type UpsertBusinessNameMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertBusinessNameMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertBusinessUrlMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertBusinessUrlMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertCustomerMutationVariables = Exact<{
  input: UpsertCustomerInput;
}>;


export type UpsertCustomerMutation = (
  { __typename?: 'Mutation' }
  & { upsertCustomer: (
    { __typename?: 'UpsertCustomerStatus' }
    & Pick<UpsertCustomerStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpdateIntegrationSettingsMutationVariables = Exact<{
  input: UpdateIntegrationSettingsInput;
}>;


export type UpdateIntegrationSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateIntegrationSettings: (
    { __typename?: 'UpdateIntegrationSettingsStatus' }
    & Pick<UpdateIntegrationSettingsStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertLogoUrlMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertLogoUrlMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertPayerOptionsMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertPayerOptionsMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpdatePaymentReminderSettingsMutationVariables = Exact<{
  input: UpdatePaymentReminderSettingsInput;
}>;


export type UpdatePaymentReminderSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentReminderSettings: (
    { __typename?: 'UpdatePaymentReminderSettingsStatus' }
    & Pick<UpdatePaymentReminderSettingsStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertPaymentRequestMutationVariables = Exact<{
  input: UpsertPaymentRequestInput;
}>;


export type UpsertPaymentRequestMutation = (
  { __typename?: 'Mutation' }
  & { upsertPaymentRequest: (
    { __typename?: 'UpsertPaymentRequestStatus' }
    & Pick<UpsertPaymentRequestStatus, 'code' | 'message' | 'paymentRequestId' | 'error'>
    & { customerOption?: Maybe<(
      { __typename?: 'CustomerOption' }
      & Pick<CustomerOption, 'customerId' | 'customerName'>
    )> }
  ) }
);

export type UpsertPayoutFrequencyMutationVariables = Exact<{
  input: UpsertPayoutFrequencyInput;
}>;


export type UpsertPayoutFrequencyMutation = (
  { __typename?: 'Mutation' }
  & { upsertPayoutFrequency: (
    { __typename?: 'UpsertPayoutFrequencyStatus' }
    & Pick<UpsertPayoutFrequencyStatus, 'code' | 'message'>
  ) }
);

export type UpsertPayoutSettingsMutationVariables = Exact<{
  input: UpsertPayoutSettingsInput;
}>;


export type UpsertPayoutSettingsMutation = (
  { __typename?: 'Mutation' }
  & { upsertPayoutSettings: (
    { __typename?: 'UpsertPayoutSettingsStatus' }
    & Pick<UpsertPayoutSettingsStatus, 'code' | 'message'>
  ) }
);

export type UpsertPersonMutationVariables = Exact<{
  input: UpsertPersonInput;
}>;


export type UpsertPersonMutation = (
  { __typename?: 'Mutation' }
  & { upsertPerson: (
    { __typename?: 'UpsertPersonStatus' }
    & Pick<UpsertPersonStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertRefundPolicyMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertRefundPolicyMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertStatementDescriptionMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertStatementDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertSupportEmailMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertSupportEmailMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message' | 'error'>
  ) }
);

export type UpsertTenantAccountMutationVariables = Exact<{
  input: UpsertTenantAccountInput;
}>;


export type UpsertTenantAccountMutation = (
  { __typename?: 'Mutation' }
  & { upsertTenantAccount: (
    { __typename?: 'UpsertTenantAccountStatus' }
    & Pick<UpsertTenantAccountStatus, 'code' | 'message'>
  ) }
);

export type GetAdditionalVerifcationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdditionalVerifcationsQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'TenantAccount' }
    & { requirements?: Maybe<(
      { __typename?: 'AccountRequirements' }
      & Pick<AccountRequirements, 'currentDeadline' | 'currentlyDue' | 'disabledReason' | 'errors' | 'eventuallyDue' | 'pastDue' | 'pendingVerification'>
    )> }
  ) }
);

export type CustomersQueryVariables = Exact<{
  queryString?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  dateRangeType?: Maybe<CustomerDateRangeType>;
  orderBy: CustomerOrder;
}>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomerConnection' }
    & Pick<CustomerConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'createdAt' | 'createdBy' | 'customerNumber' | 'id' | 'name' | 'updatedAt' | 'updatedBy'>
      & { customerUsers?: Maybe<Array<(
        { __typename?: 'CustomerUser' }
        & Pick<CustomerUser, 'email' | 'role'>
      )>>, owner: (
        { __typename?: 'Owner' }
        & Pick<Owner, 'tenantId'>
      ), convenienceFees?: Maybe<(
        { __typename?: 'CustomerConvenienceFees' }
        & Pick<CustomerConvenienceFees, 'overrideConvenienceFees' | 'creditCardRateBps' | 'debitCardRateBps'>
      )> }
    )>>, edges: Array<Maybe<(
      { __typename?: 'CustomerEdge' }
      & Pick<CustomerEdge, 'cursor'>
      & { node: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id'>
      ) }
    )>> }
  ) }
);

export type DisputesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: DisputeOrder;
}>;


export type DisputesQuery = (
  { __typename?: 'Query' }
  & { disputes: (
    { __typename?: 'DisputeConnection' }
    & Pick<DisputeConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'Dispute' }
      & Pick<Dispute, 'amount' | 'status' | 'disputeType' | 'currency' | 'reason' | 'reasonMessage' | 'createdAt' | 'id' | 'resolution' | 'resolvedAt' | 'concedeMessage'>
      & { reasonDetails?: Maybe<Array<(
        { __typename?: 'DisputeReasonDetail' }
        & Pick<DisputeReasonDetail, 'code' | 'message'>
      )>>, owner?: Maybe<(
        { __typename?: 'Owner' }
        & Pick<Owner, 'paymentId' | 'tenantId'>
      )>, payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'attemptTimestamp' | 'createdAt' | 'immediateCapture' | 'description'>
        & { paymentRequestAllocation?: Maybe<Array<(
          { __typename?: 'PaymentRequestAllocation' }
          & Pick<PaymentRequestAllocation, 'amount'>
          & { paymentRequest?: Maybe<(
            { __typename?: 'PaymentRequest' }
            & Pick<PaymentRequest, 'id' | 'amount' | 'referenceNumber' | 'invoiceLink' | 'invoiceLinks'>
            & { communications: Array<Maybe<(
              { __typename?: 'PaymentRequestCommunication' }
              & Pick<PaymentRequestCommunication, 'email'>
            )>>, payments: Array<Maybe<(
              { __typename?: 'Payment' }
              & Pick<Payment, 'id' | 'amount' | 'creditAmount'>
            )>> }
          )> }
        )>>, paymentRequest?: Maybe<(
          { __typename?: 'PaymentRequest' }
          & Pick<PaymentRequest, 'id' | 'amount' | 'referenceNumber' | 'invoiceLink'>
          & { communications: Array<Maybe<(
            { __typename?: 'PaymentRequestCommunication' }
            & Pick<PaymentRequestCommunication, 'email'>
          )>>, payments: Array<Maybe<(
            { __typename?: 'Payment' }
            & Pick<Payment, 'id' | 'amount' | 'creditAmount'>
          )>> }
        )> }
      )> }
    )>>, edges: Array<Maybe<(
      { __typename?: 'DisputeEdge' }
      & Pick<DisputeEdge, 'cursor'>
      & { node: (
        { __typename?: 'Dispute' }
        & Pick<Dispute, 'id'>
      ) }
    )>> }
  ) }
);

export type LoginContextQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginContextQuery = (
  { __typename?: 'Query' }
  & { loginContext: (
    { __typename?: 'LoginContext' }
    & Pick<LoginContext, 'firstName' | 'lastName' | 'email'>
    & { accounts: Array<(
      { __typename?: 'TenantAccount' }
      & Pick<TenantAccount, 'id' | 'defaultCurrency'>
      & { owner: (
        { __typename?: 'Owner' }
        & Pick<Owner, 'tenantId'>
      ), balances?: Maybe<(
        { __typename?: 'AccountBalances' }
        & { currentBalance?: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )> }
      )>, businessProfile?: Maybe<(
        { __typename?: 'BusinessProfile' }
        & Pick<BusinessProfile, 'name' | 'logoUrl'>
      )>, company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, merchantSummary?: Maybe<(
        { __typename?: 'TenantMerchantSummary' }
        & Pick<TenantMerchantSummary, 'paymentsDueToday'>
      )>, settings?: Maybe<(
        { __typename?: 'AccountSettings' }
        & { supportedCapabilities: (
          { __typename?: 'TenantSupportedCapabilities' }
          & Pick<TenantSupportedCapabilities, 'accountBalance'>
        ) }
      )> }
    )> }
  ) }
);

export type GetOwningProductQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwningProductQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'TenantAccount' }
    & Pick<TenantAccount, 'owningProduct'>
  ) }
);

export type PaymentsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: PaymentOrder;
  queryString?: Maybe<Scalars['String']>;
  status?: Maybe<Array<PaymentStatus> | PaymentStatus>;
  extendedPaymentStatus?: Maybe<Array<PaymentStatusExtended> | PaymentStatusExtended>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  amountRange?: Maybe<AmountRange>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
}>;


export type PaymentsQuery = (
  { __typename?: 'Query' }
  & { payments: (
    { __typename?: 'PaymentConnection' }
    & Pick<PaymentConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'status' | 'pendingReason' | 'pendingReasonCode' | 'amount' | 'amountBeforeFees' | 'amountDisputed' | 'amountRefunded' | 'attemptTimestamp' | 'completedTimestamp' | 'createdAt' | 'cancelReason' | 'currency' | 'convenienceFee' | 'creditAmount' | 'description' | 'failureReason' | 'feeAmount' | 'immediateCapture' | 'initiatedBy' | 'authorizationCode' | 'customerPONumber' | 'orderNumber' | 'invoiceNumber'>
      & { failureDetail?: Maybe<(
        { __typename?: 'ReasonInfo' }
        & Pick<ReasonInfo, 'code'>
        & { details?: Maybe<Array<(
          { __typename?: 'ReasonDetail' }
          & Pick<ReasonDetail, 'code'>
        )>> }
      )>, paymentMethod?: Maybe<(
        { __typename?: 'PaymentMethod' }
        & { creditCard?: Maybe<(
          { __typename?: 'CreditCard' }
          & Pick<CreditCard, 'cardBrand' | 'lastFour'>
          & { cardHolder?: Maybe<(
            { __typename?: 'PaymentMethodHolder' }
            & Pick<PaymentMethodHolder, 'email'>
          )> }
        )>, paymentBank?: Maybe<(
          { __typename?: 'PaymentBank' }
          & Pick<PaymentBank, 'lastFour'>
          & { accountHolder?: Maybe<(
            { __typename?: 'PaymentMethodHolder' }
            & Pick<PaymentMethodHolder, 'email'>
          )> }
        )> }
      )>, owner: (
        { __typename?: 'Owner' }
        & Pick<Owner, 'tenantId' | 'paymentRequestId' | 'customerId'>
      ), refunds: Array<Maybe<(
        { __typename?: 'Refund' }
        & Pick<Refund, 'amount' | 'attemptTimestamp' | 'currency' | 'id' | 'refundReason' | 'status' | 'createTime'>
        & { owner: (
          { __typename?: 'Owner' }
          & Pick<Owner, 'tenantId'>
        ), payment: (
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'amount' | 'amountBeforeFees'>
        ) }
      )>>, paymentRequest?: Maybe<(
        { __typename?: 'PaymentRequest' }
        & Pick<PaymentRequest, 'id' | 'amount' | 'referenceNumber' | 'totalDue' | 'totalPaid' | 'status'>
        & { payments: Array<Maybe<(
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'amount' | 'amountRefunded' | 'creditAmount'>
        )>> }
      )>, paymentRequestAllocation?: Maybe<Array<(
        { __typename?: 'PaymentRequestAllocation' }
        & Pick<PaymentRequestAllocation, 'amount' | 'partialPaymentReason'>
        & { paymentRequest?: Maybe<(
          { __typename?: 'PaymentRequest' }
          & Pick<PaymentRequest, 'id' | 'amount' | 'referenceNumber' | 'totalDue' | 'totalPaid' | 'status'>
          & { payments: Array<Maybe<(
            { __typename?: 'Payment' }
            & Pick<Payment, 'id' | 'amount' | 'amountRefunded' | 'creditAmount'>
          )>> }
        )> }
      )>>, customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name' | 'customerNumber'>
      )> }
    )>>, edges: Array<Maybe<(
      { __typename?: 'PaymentEdge' }
      & Pick<PaymentEdge, 'cursor'>
      & { node: (
        { __typename?: 'Payment' }
        & Pick<Payment, 'id'>
      ) }
    )>> }
  ) }
);

export type PaymentMethodsByTokenQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PaymentMethodsByTokenQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: (
    { __typename?: 'PaymentMethodConnection' }
    & Pick<PaymentMethodConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'createdAt' | 'createdBy' | 'status' | 'type' | 'isLongLived' | 'updatedAt' | 'updatedBy'>
      & { creditCard?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'cardBrand' | 'lastFour' | 'expirationMonth' | 'expirationYear'>
        & { cardHolder?: Maybe<(
          { __typename?: 'PaymentMethodHolder' }
          & Pick<PaymentMethodHolder, 'email' | 'holderName'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'line1' | 'line2' | 'postalCode' | 'country'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type PaymentMethodsQueryVariables = Exact<{
  queryString?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  paymentMethodType?: Maybe<PaymentMethodType>;
  status?: Maybe<Array<PaymentMethodStatus> | PaymentMethodStatus>;
}>;


export type PaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: (
    { __typename?: 'PaymentMethodConnection' }
    & Pick<PaymentMethodConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'createdAt' | 'createdBy' | 'status' | 'type' | 'isLongLived' | 'updatedAt' | 'updatedBy'>
      & { creditCard?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'cardBrand' | 'lastFour' | 'expirationMonth' | 'expirationYear'>
        & { cardHolder?: Maybe<(
          { __typename?: 'PaymentMethodHolder' }
          & Pick<PaymentMethodHolder, 'holderName' | 'email'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'line1' | 'line2' | 'postalCode' | 'country'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type PaymentRequestsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: PaymentRequestOrder;
  queryString?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentRequestStatus>;
  statusFilter?: Maybe<Array<PaymentRequestStatus> | PaymentRequestStatus>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
}>;


export type PaymentRequestsQuery = (
  { __typename?: 'Query' }
  & { paymentRequests: (
    { __typename?: 'PaymentRequestConnection' }
    & Pick<PaymentRequestConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename?: 'PaymentRequest' }
      & Pick<PaymentRequest, 'amount' | 'createdAt' | 'createdBy' | 'id' | 'invoiceLink' | 'invoiceLinks' | 'invoiceId' | 'referenceNumber' | 'status' | 'statusReason' | 'customerPONumber' | 'orderNumber' | 'invoiceNumber' | 'discountEndDate' | 'discountAmount' | 'dueDate' | 'totalDue' | 'totalRefunded' | 'paymentUrl'>
      & { communications: Array<Maybe<(
        { __typename?: 'PaymentRequestCommunication' }
        & Pick<PaymentRequestCommunication, 'communicationType' | 'email' | 'phoneNumber' | 'requestTimestamp' | 'sentTimestamp' | 'status' | 'subType'>
      )>>, payments: Array<Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'status' | 'amount' | 'amountRefunded' | 'attemptTimestamp' | 'createdAt' | 'amountDisputed' | 'creditAmount'>
        & { paymentMethod?: Maybe<(
          { __typename?: 'PaymentMethod' }
          & { creditCard?: Maybe<(
            { __typename?: 'CreditCard' }
            & Pick<CreditCard, 'cardBrand' | 'lastFour'>
          )>, paymentBank?: Maybe<(
            { __typename?: 'PaymentBank' }
            & Pick<PaymentBank, 'lastFour'>
          )> }
        )>, creditMemoAllocation?: Maybe<Array<(
          { __typename?: 'CreditMemoAllocation' }
          & Pick<CreditMemoAllocation, 'referenceNumber' | 'amount'>
        )>>, refunds: Array<Maybe<(
          { __typename?: 'Refund' }
          & Pick<Refund, 'id' | 'status' | 'amount'>
        )>> }
      )>>, owner: (
        { __typename?: 'Owner' }
        & Pick<Owner, 'paymentId' | 'tenantId' | 'disputeId' | 'customerId'>
      ), customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'name' | 'customerNumber'>
      )> }
    )>>, edges: Array<Maybe<(
      { __typename?: 'PaymentRequestEdge' }
      & Pick<PaymentRequestEdge, 'cursor'>
      & { node: (
        { __typename?: 'PaymentRequest' }
        & Pick<PaymentRequest, 'id'>
      ) }
    )>> }
  ) }
);

export type PayoutReportQueryVariables = Exact<{
  payoutReportId: Scalars['ID'];
  format: FileFormat;
}>;


export type PayoutReportQuery = (
  { __typename?: 'Query' }
  & { payoutReport: (
    { __typename?: 'PayoutReport' }
    & Pick<PayoutReport, 'id' | 'uri' | 'status'>
  ) }
);

export type PayoutSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PayoutSettingsQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'TenantAccount' }
    & { settings?: Maybe<(
      { __typename?: 'AccountSettings' }
      & { accountPayouts?: Maybe<(
        { __typename?: 'PayoutSettings' }
        & Pick<PayoutSettings, 'accountType' | 'accountLastFour' | 'currency' | 'statementDescription' | 'status'>
        & { schedule?: Maybe<(
          { __typename?: 'PaymentSchedule' }
          & Pick<PaymentSchedule, 'interval'>
        )> }
      )> }
    )> }
  ) }
);

export type GetPersonByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetPersonByEmailQuery = (
  { __typename?: 'Query' }
  & { person: (
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email'>
    & { relationship?: Maybe<(
      { __typename?: 'AccountRelationship' }
      & Pick<AccountRelationship, 'role' | 'primaryAccountHolder'>
    )>, owner: (
      { __typename?: 'Owner' }
      & Pick<Owner, 'tenantId'>
    ) }
  ) }
);

export type PersonsListQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonsListQuery = (
  { __typename?: 'Query' }
  & { persons: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'firstName' | 'lastName' | 'email' | 'updatedAt' | 'id' | 'createdAt'>
    & { relationship?: Maybe<(
      { __typename?: 'AccountRelationship' }
      & Pick<AccountRelationship, 'owner' | 'primaryAccountHolder' | 'role' | 'involvement'>
    )>, verification?: Maybe<(
      { __typename?: 'AccountPersonalVerification' }
      & Pick<AccountPersonalVerification, 'status'>
    )> }
  )> }
);

export type ReportQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'downloadUrl' | 'status'>
  ) }
);

export type GetTenantAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantAccountQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'TenantAccount' }
    & Pick<TenantAccount, 'country' | 'createdAt' | 'id' | 'updatedAt' | 'email' | 'payFacType' | 'businessType' | 'defaultCurrency' | 'feeSchedule' | 'errors'>
    & { owner: (
      { __typename?: 'Owner' }
      & Pick<Owner, 'tenantId'>
    ), payfac?: Maybe<(
      { __typename?: 'PayFacIds' }
      & { ids?: Maybe<Array<(
        { __typename?: 'PayFacId' }
        & Pick<PayFacId, 'resourceId' | 'resourceType'>
      )>> }
    )>, capabilities?: Maybe<(
      { __typename?: 'TenantAccountCapabilities' }
      & Pick<TenantAccountCapabilities, 'cardPayments' | 'achPayments' | 'accountPayouts'>
    )>, businessProfile?: Maybe<(
      { __typename?: 'BusinessProfile' }
      & Pick<BusinessProfile, 'description' | 'logoUrl' | 'highResLogoUrl' | 'name' | 'url' | 'supportEmail' | 'mcc'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'description' | 'name' | 'structure'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'region'>
      )>, phone?: Maybe<(
        { __typename?: 'Phone' }
        & Pick<Phone, 'countryCode' | 'number' | 'phoneNumber'>
      )>, verification?: Maybe<(
        { __typename?: 'CompanyAccountVerification' }
        & Pick<CompanyAccountVerification, 'status' | 'kycIssue' | 'ssnIssue' | 'transitionStatus'>
        & { additionalDocuments: Array<Maybe<(
          { __typename?: 'AccountVerificationDocument' }
          & Pick<AccountVerificationDocument, 'documentId' | 'rejectReason' | 'status' | 'type'>
        )>>, documents: Array<Maybe<(
          { __typename?: 'AccountVerificationDocument' }
          & Pick<AccountVerificationDocument, 'documentId' | 'rejectReason' | 'status' | 'type'>
        )>> }
      )> }
    )>, settings?: Maybe<(
      { __typename?: 'AccountSettings' }
      & { customEmailSettings?: Maybe<(
        { __typename?: 'TenantAccountEmailSettings' }
        & Pick<TenantAccountEmailSettings, 'enabled' | 'paymentRequestDefaultText'>
        & { profile?: Maybe<(
          { __typename?: 'TenantAccountEmailSettingsProfile' }
          & Pick<TenantAccountEmailSettingsProfile, 'from' | 'host' | 'port' | 'username' | 'password' | 'securityType'>
        )> }
      )>, features?: Maybe<(
        { __typename?: 'Features' }
        & { creditMemos: (
          { __typename?: 'CreditMemoFeatures' }
          & Pick<CreditMemoFeatures, 'enabled'>
        ), customers: (
          { __typename?: 'CustomersFeatures' }
          & Pick<CustomersFeatures, 'enabled'>
        ), payments: (
          { __typename?: 'PaymentsFeatures' }
          & Pick<PaymentsFeatures, 'convenienceFees' | 'virtualTerminal'>
        ), paymentRequests: (
          { __typename?: 'PaymentRequestFeatures' }
          & Pick<PaymentRequestFeatures, 'consolidatedPayment' | 'partialPayment' | 'paymentReminderEmails'>
        ), customEmailSettings: (
          { __typename?: 'CustomEmailSettingsFeatures' }
          & Pick<CustomEmailSettingsFeatures, 'enabled'>
        ) }
      )>, options?: Maybe<(
        { __typename?: 'AccountOptions' }
        & { payments?: Maybe<(
          { __typename?: 'AccountPaymentOptions' }
          & Pick<AccountPaymentOptions, 'requirePartialPaymentReason'>
        )> }
      )>, cardPayments?: Maybe<(
        { __typename?: 'PaymentSettings' }
        & Pick<PaymentSettings, 'refundPolicy' | 'statementDescription'>
      )>, achPayments?: Maybe<(
        { __typename?: 'PaymentSettings' }
        & Pick<PaymentSettings, 'refundPolicy' | 'statementDescription'>
      )>, accountPayouts?: Maybe<(
        { __typename?: 'PayoutSettings' }
        & Pick<PayoutSettings, 'statementDescription' | 'status' | 'currency' | 'accountType' | 'accountLastFour'>
        & { schedule?: Maybe<(
          { __typename?: 'PaymentSchedule' }
          & Pick<PaymentSchedule, 'interval'>
        )> }
      )>, integrationSettings?: Maybe<(
        { __typename?: 'IntegrationSettings' }
        & Pick<IntegrationSettings, 'mode' | 'processUnsentCommunications'>
        & { previewSettings: (
          { __typename?: 'PreviewSettings' }
          & Pick<PreviewSettings, 'enabled' | 'email'>
        ) }
      )>, paymentReminders?: Maybe<(
        { __typename?: 'PaymentReminderSettings' }
        & Pick<PaymentReminderSettings, 'dueDateReminderDays' | 'overdueReminderDays' | 'overdueReminderMessage' | 'reminderMessage' | 'sendDueDateReminder' | 'sendOverdueReminder'>
      )>, convenienceFees?: Maybe<(
        { __typename?: 'ConvenienceFees' }
        & Pick<ConvenienceFees, 'maxCreditConvenienceFee' | 'maxDebitConvenienceFee' | 'creditCardRateBps' | 'debitCardRateBps'>
      )>, supportedCapabilities: (
        { __typename?: 'TenantSupportedCapabilities' }
        & Pick<TenantSupportedCapabilities, 'accountBalance' | 'accountStatus' | 'basicAccountStatus' | 'businessOwners' | 'description' | 'disputes' | 'entityType' | 'feeSchedule' | 'legalEntityName' | 'payoutReports' | 'payouts' | 'refundPolicy' | 'statementDescription' | 'statements' | 'website'>
      ) }
    )>, statements?: Maybe<Array<(
      { __typename?: 'TransactionStatement' }
      & Pick<TransactionStatement, 'createdAt' | 'startTime' | 'endTime'>
      & { availableFormats?: Maybe<Array<(
        { __typename?: 'TransactionStatementAvailableFormat' }
        & Pick<TransactionStatementAvailableFormat, 'format' | 'fileName'>
      )>> }
    )>>, payoutReports?: Maybe<Array<(
      { __typename?: 'PayoutReportItem' }
      & Pick<PayoutReportItem, 'payoutReportId' | 'startTime' | 'endTime' | 'payoutCreateTime' | 'payoutCompleteTime'>
    )>>, requirements?: Maybe<(
      { __typename?: 'AccountRequirements' }
      & Pick<AccountRequirements, 'pastDue' | 'currentlyDue' | 'pendingVerification' | 'eventuallyDue'>
    )>, tosAcceptance?: Maybe<(
      { __typename?: 'TosAcceptance' }
      & Pick<TosAcceptance, 'date'>
    )>, balances?: Maybe<(
      { __typename?: 'AccountBalances' }
      & Pick<AccountBalances, 'balance' | 'incomingPending' | 'outgoingPending' | 'reserve' | 'updatedTimestamp'>
    )> }
  ) }
);

export type TransactionRecordsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy: TransactionRecordOrder;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type TransactionRecordsQuery = (
  { __typename?: 'Query' }
  & { transactionRecords: (
    { __typename: 'TransactionRecordConnection' }
    & Pick<TransactionRecordConnection, 'totalCount'>
    & { pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<Maybe<(
      { __typename: 'TransactionRecord' }
      & Pick<TransactionRecord, 'createdAt' | 'id' | 'transactionType' | 'grossAmount' | 'feeAmount' | 'netAmount'>
    )>>, edges: Array<Maybe<(
      { __typename: 'TransactionRecordEdge' }
      & Pick<TransactionRecordEdge, 'cursor'>
      & { node: (
        { __typename: 'TransactionRecord' }
        & Pick<TransactionRecord, 'id'>
      ) }
    )>> }
  ) }
);

export type GetViewerUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetViewerUserQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { currentUser?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email'>
      & { relationship?: Maybe<(
        { __typename?: 'AccountRelationship' }
        & Pick<AccountRelationship, 'role'>
      )> }
    )> }
  ) }
);

export type UpdateTenantTransitionStatusMutationVariables = Exact<{
  input: UpdateTenantAccountTransitionStatusInput;
}>;


export type UpdateTenantTransitionStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantTransitionStatus: (
    { __typename?: 'UpdatePayoutSettingsStatus' }
    & Pick<UpdatePayoutSettingsStatus, 'code' | 'message' | 'error'>
  ) }
);


export const UpdateDisputeDocument = gql`
    mutation updateDispute($input: UpdateDisputeInput!) {
  updateDispute(input: $input)
}
    `;
export type UpdateDisputeMutationFn = Apollo.MutationFunction<UpdateDisputeMutation, UpdateDisputeMutationVariables>;

/**
 * __useUpdateDisputeMutation__
 *
 * To run a mutation, you first call `useUpdateDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisputeMutation, { data, loading, error }] = useUpdateDisputeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDisputeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisputeMutation, UpdateDisputeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisputeMutation, UpdateDisputeMutationVariables>(UpdateDisputeDocument, options);
      }
export type UpdateDisputeMutationHookResult = ReturnType<typeof useUpdateDisputeMutation>;
export type UpdateDisputeMutationResult = Apollo.MutationResult<UpdateDisputeMutation>;
export type UpdateDisputeMutationOptions = Apollo.BaseMutationOptions<UpdateDisputeMutation, UpdateDisputeMutationVariables>;
export const ConcedeDisputeDocument = gql`
    mutation concedeDispute($input: ConcedeDisputeInput!) {
  concedeDispute(input: $input)
}
    `;
export type ConcedeDisputeMutationFn = Apollo.MutationFunction<ConcedeDisputeMutation, ConcedeDisputeMutationVariables>;

/**
 * __useConcedeDisputeMutation__
 *
 * To run a mutation, you first call `useConcedeDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConcedeDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [concedeDisputeMutation, { data, loading, error }] = useConcedeDisputeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConcedeDisputeMutation(baseOptions?: Apollo.MutationHookOptions<ConcedeDisputeMutation, ConcedeDisputeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConcedeDisputeMutation, ConcedeDisputeMutationVariables>(ConcedeDisputeDocument, options);
      }
export type ConcedeDisputeMutationHookResult = ReturnType<typeof useConcedeDisputeMutation>;
export type ConcedeDisputeMutationResult = Apollo.MutationResult<ConcedeDisputeMutation>;
export type ConcedeDisputeMutationOptions = Apollo.BaseMutationOptions<ConcedeDisputeMutation, ConcedeDisputeMutationVariables>;
export const ConvertPayfacPaymentMethodTokenDocument = gql`
    mutation convertPayfacPaymentMethodToken($input: ConvertPayfacPaymentMethodTokenInput!) {
  convertPayfacPaymentMethodToken(input: $input) {
    code
    message
    error
    token {
      id
      clientIp
      created
      livemode
      type
      used
    }
  }
}
    `;
export type ConvertPayfacPaymentMethodTokenMutationFn = Apollo.MutationFunction<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>;

/**
 * __useConvertPayfacPaymentMethodTokenMutation__
 *
 * To run a mutation, you first call `useConvertPayfacPaymentMethodTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertPayfacPaymentMethodTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertPayfacPaymentMethodTokenMutation, { data, loading, error }] = useConvertPayfacPaymentMethodTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertPayfacPaymentMethodTokenMutation(baseOptions?: Apollo.MutationHookOptions<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>(ConvertPayfacPaymentMethodTokenDocument, options);
      }
export type ConvertPayfacPaymentMethodTokenMutationHookResult = ReturnType<typeof useConvertPayfacPaymentMethodTokenMutation>;
export type ConvertPayfacPaymentMethodTokenMutationResult = Apollo.MutationResult<ConvertPayfacPaymentMethodTokenMutation>;
export type ConvertPayfacPaymentMethodTokenMutationOptions = Apollo.BaseMutationOptions<ConvertPayfacPaymentMethodTokenMutation, ConvertPayfacPaymentMethodTokenMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation createPayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    code
    message
    error
    payment {
      amount
      amountDisputed
      amountRefunded
      attemptTimestamp
      cancelReason
      completedTimestamp
      currency
      failureReason
      feeAmount
      id
      initiatedBy
      pendingReason
      pendingReasonCode
      status
    }
  }
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
  createPaymentMethod(input: $input) {
    code
    message
    error
    paymentMethod {
      id
      creditCard {
        cardHolder {
          holderName
          email
          address {
            line1
            line2
            postalCode
            country
          }
        }
        cardBrand
        lastFour
        expirationMonth
        expirationYear
      }
      isLongLived
      createdAt
      createdBy
    }
  }
}
    `;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const CreateReportDocument = gql`
    mutation createReport($input: CreateReportInput!) {
  createReport(input: $input) {
    code
    error
    message
    report {
      id
    }
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const DeletePersonDocument = gql`
    mutation deletePerson($input: DeletePersonInput!) {
  deletePerson(input: $input) {
    code
    message
    error
  }
}
    `;
export type DeletePersonMutationFn = Apollo.MutationFunction<DeletePersonMutation, DeletePersonMutationVariables>;

/**
 * __useDeletePersonMutation__
 *
 * To run a mutation, you first call `useDeletePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonMutation, { data, loading, error }] = useDeletePersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePersonMutation(baseOptions?: Apollo.MutationHookOptions<DeletePersonMutation, DeletePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePersonMutation, DeletePersonMutationVariables>(DeletePersonDocument, options);
      }
export type DeletePersonMutationHookResult = ReturnType<typeof useDeletePersonMutation>;
export type DeletePersonMutationResult = Apollo.MutationResult<DeletePersonMutation>;
export type DeletePersonMutationOptions = Apollo.BaseMutationOptions<DeletePersonMutation, DeletePersonMutationVariables>;
export const PaymentRequestForwardDocument = gql`
    mutation paymentRequestForward($input: PaymentRequestForwardInput!) {
  paymentRequestForward(input: $input) {
    code
    error
    message
  }
}
    `;
export type PaymentRequestForwardMutationFn = Apollo.MutationFunction<PaymentRequestForwardMutation, PaymentRequestForwardMutationVariables>;

/**
 * __usePaymentRequestForwardMutation__
 *
 * To run a mutation, you first call `usePaymentRequestForwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentRequestForwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentRequestForwardMutation, { data, loading, error }] = usePaymentRequestForwardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentRequestForwardMutation(baseOptions?: Apollo.MutationHookOptions<PaymentRequestForwardMutation, PaymentRequestForwardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentRequestForwardMutation, PaymentRequestForwardMutationVariables>(PaymentRequestForwardDocument, options);
      }
export type PaymentRequestForwardMutationHookResult = ReturnType<typeof usePaymentRequestForwardMutation>;
export type PaymentRequestForwardMutationResult = Apollo.MutationResult<PaymentRequestForwardMutation>;
export type PaymentRequestForwardMutationOptions = Apollo.BaseMutationOptions<PaymentRequestForwardMutation, PaymentRequestForwardMutationVariables>;
export const GenerateStatementAccessUrlDocument = gql`
    mutation generateStatementAccessUrl($input: FileName!) {
  generateStatementAccessUrl(input: $input) {
    code
    error
    message
    statementUrl
  }
}
    `;
export type GenerateStatementAccessUrlMutationFn = Apollo.MutationFunction<GenerateStatementAccessUrlMutation, GenerateStatementAccessUrlMutationVariables>;

/**
 * __useGenerateStatementAccessUrlMutation__
 *
 * To run a mutation, you first call `useGenerateStatementAccessUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateStatementAccessUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateStatementAccessUrlMutation, { data, loading, error }] = useGenerateStatementAccessUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateStatementAccessUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateStatementAccessUrlMutation, GenerateStatementAccessUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateStatementAccessUrlMutation, GenerateStatementAccessUrlMutationVariables>(GenerateStatementAccessUrlDocument, options);
      }
export type GenerateStatementAccessUrlMutationHookResult = ReturnType<typeof useGenerateStatementAccessUrlMutation>;
export type GenerateStatementAccessUrlMutationResult = Apollo.MutationResult<GenerateStatementAccessUrlMutation>;
export type GenerateStatementAccessUrlMutationOptions = Apollo.BaseMutationOptions<GenerateStatementAccessUrlMutation, GenerateStatementAccessUrlMutationVariables>;
export const CreateRefundDocument = gql`
    mutation createRefund($input: CreateRefundInput!) {
  createRefund(input: $input) {
    code
    error
    message
  }
}
    `;
export type CreateRefundMutationFn = Apollo.MutationFunction<CreateRefundMutation, CreateRefundMutationVariables>;

/**
 * __useCreateRefundMutation__
 *
 * To run a mutation, you first call `useCreateRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefundMutation, { data, loading, error }] = useCreateRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRefundMutation(baseOptions?: Apollo.MutationHookOptions<CreateRefundMutation, CreateRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRefundMutation, CreateRefundMutationVariables>(CreateRefundDocument, options);
      }
export type CreateRefundMutationHookResult = ReturnType<typeof useCreateRefundMutation>;
export type CreateRefundMutationResult = Apollo.MutationResult<CreateRefundMutation>;
export type CreateRefundMutationOptions = Apollo.BaseMutationOptions<CreateRefundMutation, CreateRefundMutationVariables>;
export const ResendPaymentReceiptDocument = gql`
    mutation resendPaymentReceipt($input: ResendPaymentReceiptInput!) {
  resendPaymentReceipt(input: $input) {
    code
    message
    error
    errorReason {
      code
      message
      details {
        code
        message
      }
    }
  }
}
    `;
export type ResendPaymentReceiptMutationFn = Apollo.MutationFunction<ResendPaymentReceiptMutation, ResendPaymentReceiptMutationVariables>;

/**
 * __useResendPaymentReceiptMutation__
 *
 * To run a mutation, you first call `useResendPaymentReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPaymentReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPaymentReceiptMutation, { data, loading, error }] = useResendPaymentReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendPaymentReceiptMutation(baseOptions?: Apollo.MutationHookOptions<ResendPaymentReceiptMutation, ResendPaymentReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendPaymentReceiptMutation, ResendPaymentReceiptMutationVariables>(ResendPaymentReceiptDocument, options);
      }
export type ResendPaymentReceiptMutationHookResult = ReturnType<typeof useResendPaymentReceiptMutation>;
export type ResendPaymentReceiptMutationResult = Apollo.MutationResult<ResendPaymentReceiptMutation>;
export type ResendPaymentReceiptMutationOptions = Apollo.BaseMutationOptions<ResendPaymentReceiptMutation, ResendPaymentReceiptMutationVariables>;
export const SendTestEmailDocument = gql`
    mutation sendTestEmail($input: SendTestEmailInput!) {
  sendTestEmail(input: $input) {
    code
    message
    error
    errorReason {
      code
      message
      details {
        code
        message
      }
    }
  }
}
    `;
export type SendTestEmailMutationFn = Apollo.MutationFunction<SendTestEmailMutation, SendTestEmailMutationVariables>;

/**
 * __useSendTestEmailMutation__
 *
 * To run a mutation, you first call `useSendTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestEmailMutation, { data, loading, error }] = useSendTestEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTestEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendTestEmailMutation, SendTestEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTestEmailMutation, SendTestEmailMutationVariables>(SendTestEmailDocument, options);
      }
export type SendTestEmailMutationHookResult = ReturnType<typeof useSendTestEmailMutation>;
export type SendTestEmailMutationResult = Apollo.MutationResult<SendTestEmailMutation>;
export type SendTestEmailMutationOptions = Apollo.BaseMutationOptions<SendTestEmailMutation, SendTestEmailMutationVariables>;
export const SubmitVerificationDocument = gql`
    mutation submitVerification($input: SubmitVerificationInput!) {
  submitVerification(input: $input) {
    code
    message
    error
  }
}
    `;
export type SubmitVerificationMutationFn = Apollo.MutationFunction<SubmitVerificationMutation, SubmitVerificationMutationVariables>;

/**
 * __useSubmitVerificationMutation__
 *
 * To run a mutation, you first call `useSubmitVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitVerificationMutation, { data, loading, error }] = useSubmitVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitVerificationMutation, SubmitVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitVerificationMutation, SubmitVerificationMutationVariables>(SubmitVerificationDocument, options);
      }
export type SubmitVerificationMutationHookResult = ReturnType<typeof useSubmitVerificationMutation>;
export type SubmitVerificationMutationResult = Apollo.MutationResult<SubmitVerificationMutation>;
export type SubmitVerificationMutationOptions = Apollo.BaseMutationOptions<SubmitVerificationMutation, SubmitVerificationMutationVariables>;
export const UpdateTenantAccountEmailSettingsDocument = gql`
    mutation updateTenantAccountEmailSettings($input: UpdateTenantAccountEmailSettingsInput!) {
  updateTenantAccountEmailSettings(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpdateTenantAccountEmailSettingsMutationFn = Apollo.MutationFunction<UpdateTenantAccountEmailSettingsMutation, UpdateTenantAccountEmailSettingsMutationVariables>;

/**
 * __useUpdateTenantAccountEmailSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTenantAccountEmailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantAccountEmailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantAccountEmailSettingsMutation, { data, loading, error }] = useUpdateTenantAccountEmailSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantAccountEmailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantAccountEmailSettingsMutation, UpdateTenantAccountEmailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantAccountEmailSettingsMutation, UpdateTenantAccountEmailSettingsMutationVariables>(UpdateTenantAccountEmailSettingsDocument, options);
      }
export type UpdateTenantAccountEmailSettingsMutationHookResult = ReturnType<typeof useUpdateTenantAccountEmailSettingsMutation>;
export type UpdateTenantAccountEmailSettingsMutationResult = Apollo.MutationResult<UpdateTenantAccountEmailSettingsMutation>;
export type UpdateTenantAccountEmailSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateTenantAccountEmailSettingsMutation, UpdateTenantAccountEmailSettingsMutationVariables>;
export const UploadDocumentDocument = gql`
    mutation uploadDocument($input: UploadInput!) {
  upload(input: $input) {
    uniqueId
  }
}
    `;
export type UploadDocumentMutationFn = Apollo.MutationFunction<UploadDocumentMutation, UploadDocumentMutationVariables>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(UploadDocumentDocument, options);
      }
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>;
export const UploadDocument2Document = gql`
    mutation uploadDocument2($input: UploadInput!) {
  upload2(input: $input) {
    uniqueId
  }
}
    `;
export type UploadDocument2MutationFn = Apollo.MutationFunction<UploadDocument2Mutation, UploadDocument2MutationVariables>;

/**
 * __useUploadDocument2Mutation__
 *
 * To run a mutation, you first call `useUploadDocument2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocument2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocument2Mutation, { data, loading, error }] = useUploadDocument2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadDocument2Mutation(baseOptions?: Apollo.MutationHookOptions<UploadDocument2Mutation, UploadDocument2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocument2Mutation, UploadDocument2MutationVariables>(UploadDocument2Document, options);
      }
export type UploadDocument2MutationHookResult = ReturnType<typeof useUploadDocument2Mutation>;
export type UploadDocument2MutationResult = Apollo.MutationResult<UploadDocument2Mutation>;
export type UploadDocument2MutationOptions = Apollo.BaseMutationOptions<UploadDocument2Mutation, UploadDocument2MutationVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($input: UploadImageInput!) {
  uploadImage(input: $input) {
    uniqueId
    imageUri
  }
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UpsertBusinessNameDocument = gql`
    mutation upsertBusinessName($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertBusinessNameMutationFn = Apollo.MutationFunction<UpsertBusinessNameMutation, UpsertBusinessNameMutationVariables>;

/**
 * __useUpsertBusinessNameMutation__
 *
 * To run a mutation, you first call `useUpsertBusinessNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBusinessNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBusinessNameMutation, { data, loading, error }] = useUpsertBusinessNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBusinessNameMutation(baseOptions?: Apollo.MutationHookOptions<UpsertBusinessNameMutation, UpsertBusinessNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertBusinessNameMutation, UpsertBusinessNameMutationVariables>(UpsertBusinessNameDocument, options);
      }
export type UpsertBusinessNameMutationHookResult = ReturnType<typeof useUpsertBusinessNameMutation>;
export type UpsertBusinessNameMutationResult = Apollo.MutationResult<UpsertBusinessNameMutation>;
export type UpsertBusinessNameMutationOptions = Apollo.BaseMutationOptions<UpsertBusinessNameMutation, UpsertBusinessNameMutationVariables>;
export const UpsertBusinessUrlDocument = gql`
    mutation upsertBusinessUrl($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertBusinessUrlMutationFn = Apollo.MutationFunction<UpsertBusinessUrlMutation, UpsertBusinessUrlMutationVariables>;

/**
 * __useUpsertBusinessUrlMutation__
 *
 * To run a mutation, you first call `useUpsertBusinessUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBusinessUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBusinessUrlMutation, { data, loading, error }] = useUpsertBusinessUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBusinessUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpsertBusinessUrlMutation, UpsertBusinessUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertBusinessUrlMutation, UpsertBusinessUrlMutationVariables>(UpsertBusinessUrlDocument, options);
      }
export type UpsertBusinessUrlMutationHookResult = ReturnType<typeof useUpsertBusinessUrlMutation>;
export type UpsertBusinessUrlMutationResult = Apollo.MutationResult<UpsertBusinessUrlMutation>;
export type UpsertBusinessUrlMutationOptions = Apollo.BaseMutationOptions<UpsertBusinessUrlMutation, UpsertBusinessUrlMutationVariables>;
export const UpsertCustomerDocument = gql`
    mutation upsertCustomer($input: UpsertCustomerInput!) {
  upsertCustomer(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertCustomerMutationFn = Apollo.MutationFunction<UpsertCustomerMutation, UpsertCustomerMutationVariables>;

/**
 * __useUpsertCustomerMutation__
 *
 * To run a mutation, you first call `useUpsertCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCustomerMutation, { data, loading, error }] = useUpsertCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCustomerMutation, UpsertCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCustomerMutation, UpsertCustomerMutationVariables>(UpsertCustomerDocument, options);
      }
export type UpsertCustomerMutationHookResult = ReturnType<typeof useUpsertCustomerMutation>;
export type UpsertCustomerMutationResult = Apollo.MutationResult<UpsertCustomerMutation>;
export type UpsertCustomerMutationOptions = Apollo.BaseMutationOptions<UpsertCustomerMutation, UpsertCustomerMutationVariables>;
export const UpdateIntegrationSettingsDocument = gql`
    mutation updateIntegrationSettings($input: UpdateIntegrationSettingsInput!) {
  updateIntegrationSettings(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpdateIntegrationSettingsMutationFn = Apollo.MutationFunction<UpdateIntegrationSettingsMutation, UpdateIntegrationSettingsMutationVariables>;

/**
 * __useUpdateIntegrationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationSettingsMutation, { data, loading, error }] = useUpdateIntegrationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntegrationSettingsMutation, UpdateIntegrationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntegrationSettingsMutation, UpdateIntegrationSettingsMutationVariables>(UpdateIntegrationSettingsDocument, options);
      }
export type UpdateIntegrationSettingsMutationHookResult = ReturnType<typeof useUpdateIntegrationSettingsMutation>;
export type UpdateIntegrationSettingsMutationResult = Apollo.MutationResult<UpdateIntegrationSettingsMutation>;
export type UpdateIntegrationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateIntegrationSettingsMutation, UpdateIntegrationSettingsMutationVariables>;
export const UpsertLogoUrlDocument = gql`
    mutation upsertLogoUrl($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertLogoUrlMutationFn = Apollo.MutationFunction<UpsertLogoUrlMutation, UpsertLogoUrlMutationVariables>;

/**
 * __useUpsertLogoUrlMutation__
 *
 * To run a mutation, you first call `useUpsertLogoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLogoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLogoUrlMutation, { data, loading, error }] = useUpsertLogoUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLogoUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLogoUrlMutation, UpsertLogoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLogoUrlMutation, UpsertLogoUrlMutationVariables>(UpsertLogoUrlDocument, options);
      }
export type UpsertLogoUrlMutationHookResult = ReturnType<typeof useUpsertLogoUrlMutation>;
export type UpsertLogoUrlMutationResult = Apollo.MutationResult<UpsertLogoUrlMutation>;
export type UpsertLogoUrlMutationOptions = Apollo.BaseMutationOptions<UpsertLogoUrlMutation, UpsertLogoUrlMutationVariables>;
export const UpsertPayerOptionsDocument = gql`
    mutation upsertPayerOptions($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertPayerOptionsMutationFn = Apollo.MutationFunction<UpsertPayerOptionsMutation, UpsertPayerOptionsMutationVariables>;

/**
 * __useUpsertPayerOptionsMutation__
 *
 * To run a mutation, you first call `useUpsertPayerOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPayerOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPayerOptionsMutation, { data, loading, error }] = useUpsertPayerOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPayerOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPayerOptionsMutation, UpsertPayerOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPayerOptionsMutation, UpsertPayerOptionsMutationVariables>(UpsertPayerOptionsDocument, options);
      }
export type UpsertPayerOptionsMutationHookResult = ReturnType<typeof useUpsertPayerOptionsMutation>;
export type UpsertPayerOptionsMutationResult = Apollo.MutationResult<UpsertPayerOptionsMutation>;
export type UpsertPayerOptionsMutationOptions = Apollo.BaseMutationOptions<UpsertPayerOptionsMutation, UpsertPayerOptionsMutationVariables>;
export const UpdatePaymentReminderSettingsDocument = gql`
    mutation updatePaymentReminderSettings($input: UpdatePaymentReminderSettingsInput!) {
  updatePaymentReminderSettings(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpdatePaymentReminderSettingsMutationFn = Apollo.MutationFunction<UpdatePaymentReminderSettingsMutation, UpdatePaymentReminderSettingsMutationVariables>;

/**
 * __useUpdatePaymentReminderSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentReminderSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentReminderSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentReminderSettingsMutation, { data, loading, error }] = useUpdatePaymentReminderSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentReminderSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentReminderSettingsMutation, UpdatePaymentReminderSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentReminderSettingsMutation, UpdatePaymentReminderSettingsMutationVariables>(UpdatePaymentReminderSettingsDocument, options);
      }
export type UpdatePaymentReminderSettingsMutationHookResult = ReturnType<typeof useUpdatePaymentReminderSettingsMutation>;
export type UpdatePaymentReminderSettingsMutationResult = Apollo.MutationResult<UpdatePaymentReminderSettingsMutation>;
export type UpdatePaymentReminderSettingsMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentReminderSettingsMutation, UpdatePaymentReminderSettingsMutationVariables>;
export const UpsertPaymentRequestDocument = gql`
    mutation upsertPaymentRequest($input: UpsertPaymentRequestInput!) {
  upsertPaymentRequest(input: $input) {
    code
    message
    customerOption {
      customerId
      customerName
    }
    paymentRequestId
    error
  }
}
    `;
export type UpsertPaymentRequestMutationFn = Apollo.MutationFunction<UpsertPaymentRequestMutation, UpsertPaymentRequestMutationVariables>;

/**
 * __useUpsertPaymentRequestMutation__
 *
 * To run a mutation, you first call `useUpsertPaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPaymentRequestMutation, { data, loading, error }] = useUpsertPaymentRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPaymentRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPaymentRequestMutation, UpsertPaymentRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPaymentRequestMutation, UpsertPaymentRequestMutationVariables>(UpsertPaymentRequestDocument, options);
      }
export type UpsertPaymentRequestMutationHookResult = ReturnType<typeof useUpsertPaymentRequestMutation>;
export type UpsertPaymentRequestMutationResult = Apollo.MutationResult<UpsertPaymentRequestMutation>;
export type UpsertPaymentRequestMutationOptions = Apollo.BaseMutationOptions<UpsertPaymentRequestMutation, UpsertPaymentRequestMutationVariables>;
export const UpsertPayoutFrequencyDocument = gql`
    mutation upsertPayoutFrequency($input: UpsertPayoutFrequencyInput!) {
  upsertPayoutFrequency(input: $input) {
    code
    message
  }
}
    `;
export type UpsertPayoutFrequencyMutationFn = Apollo.MutationFunction<UpsertPayoutFrequencyMutation, UpsertPayoutFrequencyMutationVariables>;

/**
 * __useUpsertPayoutFrequencyMutation__
 *
 * To run a mutation, you first call `useUpsertPayoutFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPayoutFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPayoutFrequencyMutation, { data, loading, error }] = useUpsertPayoutFrequencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPayoutFrequencyMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPayoutFrequencyMutation, UpsertPayoutFrequencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPayoutFrequencyMutation, UpsertPayoutFrequencyMutationVariables>(UpsertPayoutFrequencyDocument, options);
      }
export type UpsertPayoutFrequencyMutationHookResult = ReturnType<typeof useUpsertPayoutFrequencyMutation>;
export type UpsertPayoutFrequencyMutationResult = Apollo.MutationResult<UpsertPayoutFrequencyMutation>;
export type UpsertPayoutFrequencyMutationOptions = Apollo.BaseMutationOptions<UpsertPayoutFrequencyMutation, UpsertPayoutFrequencyMutationVariables>;
export const UpsertPayoutSettingsDocument = gql`
    mutation upsertPayoutSettings($input: UpsertPayoutSettingsInput!) {
  upsertPayoutSettings(input: $input) {
    code
    message
  }
}
    `;
export type UpsertPayoutSettingsMutationFn = Apollo.MutationFunction<UpsertPayoutSettingsMutation, UpsertPayoutSettingsMutationVariables>;

/**
 * __useUpsertPayoutSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertPayoutSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPayoutSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPayoutSettingsMutation, { data, loading, error }] = useUpsertPayoutSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPayoutSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPayoutSettingsMutation, UpsertPayoutSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPayoutSettingsMutation, UpsertPayoutSettingsMutationVariables>(UpsertPayoutSettingsDocument, options);
      }
export type UpsertPayoutSettingsMutationHookResult = ReturnType<typeof useUpsertPayoutSettingsMutation>;
export type UpsertPayoutSettingsMutationResult = Apollo.MutationResult<UpsertPayoutSettingsMutation>;
export type UpsertPayoutSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertPayoutSettingsMutation, UpsertPayoutSettingsMutationVariables>;
export const UpsertPersonDocument = gql`
    mutation upsertPerson($input: UpsertPersonInput!) {
  upsertPerson(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertPersonMutationFn = Apollo.MutationFunction<UpsertPersonMutation, UpsertPersonMutationVariables>;

/**
 * __useUpsertPersonMutation__
 *
 * To run a mutation, you first call `useUpsertPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPersonMutation, { data, loading, error }] = useUpsertPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPersonMutation, UpsertPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPersonMutation, UpsertPersonMutationVariables>(UpsertPersonDocument, options);
      }
export type UpsertPersonMutationHookResult = ReturnType<typeof useUpsertPersonMutation>;
export type UpsertPersonMutationResult = Apollo.MutationResult<UpsertPersonMutation>;
export type UpsertPersonMutationOptions = Apollo.BaseMutationOptions<UpsertPersonMutation, UpsertPersonMutationVariables>;
export const UpsertRefundPolicyDocument = gql`
    mutation upsertRefundPolicy($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertRefundPolicyMutationFn = Apollo.MutationFunction<UpsertRefundPolicyMutation, UpsertRefundPolicyMutationVariables>;

/**
 * __useUpsertRefundPolicyMutation__
 *
 * To run a mutation, you first call `useUpsertRefundPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRefundPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRefundPolicyMutation, { data, loading, error }] = useUpsertRefundPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRefundPolicyMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRefundPolicyMutation, UpsertRefundPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRefundPolicyMutation, UpsertRefundPolicyMutationVariables>(UpsertRefundPolicyDocument, options);
      }
export type UpsertRefundPolicyMutationHookResult = ReturnType<typeof useUpsertRefundPolicyMutation>;
export type UpsertRefundPolicyMutationResult = Apollo.MutationResult<UpsertRefundPolicyMutation>;
export type UpsertRefundPolicyMutationOptions = Apollo.BaseMutationOptions<UpsertRefundPolicyMutation, UpsertRefundPolicyMutationVariables>;
export const UpsertStatementDescriptionDocument = gql`
    mutation upsertStatementDescription($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertStatementDescriptionMutationFn = Apollo.MutationFunction<UpsertStatementDescriptionMutation, UpsertStatementDescriptionMutationVariables>;

/**
 * __useUpsertStatementDescriptionMutation__
 *
 * To run a mutation, you first call `useUpsertStatementDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStatementDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStatementDescriptionMutation, { data, loading, error }] = useUpsertStatementDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertStatementDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertStatementDescriptionMutation, UpsertStatementDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertStatementDescriptionMutation, UpsertStatementDescriptionMutationVariables>(UpsertStatementDescriptionDocument, options);
      }
export type UpsertStatementDescriptionMutationHookResult = ReturnType<typeof useUpsertStatementDescriptionMutation>;
export type UpsertStatementDescriptionMutationResult = Apollo.MutationResult<UpsertStatementDescriptionMutation>;
export type UpsertStatementDescriptionMutationOptions = Apollo.BaseMutationOptions<UpsertStatementDescriptionMutation, UpsertStatementDescriptionMutationVariables>;
export const UpsertSupportEmailDocument = gql`
    mutation upsertSupportEmail($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpsertSupportEmailMutationFn = Apollo.MutationFunction<UpsertSupportEmailMutation, UpsertSupportEmailMutationVariables>;

/**
 * __useUpsertSupportEmailMutation__
 *
 * To run a mutation, you first call `useUpsertSupportEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSupportEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSupportEmailMutation, { data, loading, error }] = useUpsertSupportEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSupportEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSupportEmailMutation, UpsertSupportEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSupportEmailMutation, UpsertSupportEmailMutationVariables>(UpsertSupportEmailDocument, options);
      }
export type UpsertSupportEmailMutationHookResult = ReturnType<typeof useUpsertSupportEmailMutation>;
export type UpsertSupportEmailMutationResult = Apollo.MutationResult<UpsertSupportEmailMutation>;
export type UpsertSupportEmailMutationOptions = Apollo.BaseMutationOptions<UpsertSupportEmailMutation, UpsertSupportEmailMutationVariables>;
export const UpsertTenantAccountDocument = gql`
    mutation upsertTenantAccount($input: UpsertTenantAccountInput!) {
  upsertTenantAccount(input: $input) {
    code
    message
  }
}
    `;
export type UpsertTenantAccountMutationFn = Apollo.MutationFunction<UpsertTenantAccountMutation, UpsertTenantAccountMutationVariables>;

/**
 * __useUpsertTenantAccountMutation__
 *
 * To run a mutation, you first call `useUpsertTenantAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTenantAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTenantAccountMutation, { data, loading, error }] = useUpsertTenantAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTenantAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTenantAccountMutation, UpsertTenantAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTenantAccountMutation, UpsertTenantAccountMutationVariables>(UpsertTenantAccountDocument, options);
      }
export type UpsertTenantAccountMutationHookResult = ReturnType<typeof useUpsertTenantAccountMutation>;
export type UpsertTenantAccountMutationResult = Apollo.MutationResult<UpsertTenantAccountMutation>;
export type UpsertTenantAccountMutationOptions = Apollo.BaseMutationOptions<UpsertTenantAccountMutation, UpsertTenantAccountMutationVariables>;
export const GetAdditionalVerifcationsDocument = gql`
    query getAdditionalVerifcations {
  account {
    requirements {
      currentDeadline
      currentlyDue
      disabledReason
      errors
      eventuallyDue
      pastDue
      pendingVerification
    }
  }
}
    `;

/**
 * __useGetAdditionalVerifcationsQuery__
 *
 * To run a query within a React component, call `useGetAdditionalVerifcationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdditionalVerifcationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdditionalVerifcationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdditionalVerifcationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdditionalVerifcationsQuery, GetAdditionalVerifcationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdditionalVerifcationsQuery, GetAdditionalVerifcationsQueryVariables>(GetAdditionalVerifcationsDocument, options);
      }
export function useGetAdditionalVerifcationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdditionalVerifcationsQuery, GetAdditionalVerifcationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdditionalVerifcationsQuery, GetAdditionalVerifcationsQueryVariables>(GetAdditionalVerifcationsDocument, options);
        }
export type GetAdditionalVerifcationsQueryHookResult = ReturnType<typeof useGetAdditionalVerifcationsQuery>;
export type GetAdditionalVerifcationsLazyQueryHookResult = ReturnType<typeof useGetAdditionalVerifcationsLazyQuery>;
export type GetAdditionalVerifcationsQueryResult = Apollo.QueryResult<GetAdditionalVerifcationsQuery, GetAdditionalVerifcationsQueryVariables>;
export const CustomersDocument = gql`
    query customers($queryString: String, $startDate: DateTime, $endDate: DateTime, $after: String, $before: String, $first: Int, $last: Int, $id: String, $dateRangeType: CustomerDateRangeType, $orderBy: CustomerOrder!) {
  customers(
    queryString: $queryString
    startDate: $startDate
    endDate: $endDate
    after: $after
    before: $before
    first: $first
    last: $last
    id: $id
    dateRangeType: $dateRangeType
    orderBy: $orderBy
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      createdAt
      createdBy
      customerNumber
      customerUsers {
        email
        role
      }
      id
      name
      owner {
        tenantId
      }
      updatedAt
      updatedBy
      convenienceFees {
        overrideConvenienceFees
        creditCardRateBps
        debitCardRateBps
      }
    }
    edges {
      cursor
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      id: // value for 'id'
 *      dateRangeType: // value for 'dateRangeType'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const DisputesDocument = gql`
    query disputes($after: String, $before: String, $first: Int, $last: Int, $orderBy: DisputeOrder!) {
  disputes(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      amount
      status
      disputeType
      currency
      reason
      reasonMessage
      reasonDetails {
        code
        message
      }
      createdAt
      id
      resolution
      resolvedAt
      concedeMessage
      owner {
        paymentId
        tenantId
      }
      payment {
        id
        attemptTimestamp
        createdAt
        immediateCapture
        description
        paymentRequestAllocation {
          amount
          paymentRequest {
            id
            amount
            referenceNumber
            invoiceLink
            invoiceLinks
            communications {
              email
            }
            payments {
              id
              amount
              creditAmount
            }
          }
        }
        paymentRequest {
          id
          amount
          referenceNumber
          invoiceLink
          communications {
            email
          }
          payments {
            id
            amount
            creditAmount
          }
        }
      }
    }
    edges {
      cursor
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useDisputesQuery__
 *
 * To run a query within a React component, call `useDisputesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDisputesQuery(baseOptions: Apollo.QueryHookOptions<DisputesQuery, DisputesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisputesQuery, DisputesQueryVariables>(DisputesDocument, options);
      }
export function useDisputesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisputesQuery, DisputesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisputesQuery, DisputesQueryVariables>(DisputesDocument, options);
        }
export type DisputesQueryHookResult = ReturnType<typeof useDisputesQuery>;
export type DisputesLazyQueryHookResult = ReturnType<typeof useDisputesLazyQuery>;
export type DisputesQueryResult = Apollo.QueryResult<DisputesQuery, DisputesQueryVariables>;
export const LoginContextDocument = gql`
    query loginContext {
  loginContext {
    firstName
    lastName
    email
    accounts {
      id
      defaultCurrency
      owner {
        tenantId
      }
      balances {
        currentBalance {
          amount
          currency
        }
      }
      businessProfile {
        name
        logoUrl
      }
      company {
        name
      }
      merchantSummary {
        paymentsDueToday
      }
      settings {
        supportedCapabilities {
          accountBalance
        }
      }
    }
  }
}
    `;

/**
 * __useLoginContextQuery__
 *
 * To run a query within a React component, call `useLoginContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginContextQuery(baseOptions?: Apollo.QueryHookOptions<LoginContextQuery, LoginContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginContextQuery, LoginContextQueryVariables>(LoginContextDocument, options);
      }
export function useLoginContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginContextQuery, LoginContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginContextQuery, LoginContextQueryVariables>(LoginContextDocument, options);
        }
export type LoginContextQueryHookResult = ReturnType<typeof useLoginContextQuery>;
export type LoginContextLazyQueryHookResult = ReturnType<typeof useLoginContextLazyQuery>;
export type LoginContextQueryResult = Apollo.QueryResult<LoginContextQuery, LoginContextQueryVariables>;
export const GetOwningProductDocument = gql`
    query getOwningProduct {
  account {
    owningProduct
  }
}
    `;

/**
 * __useGetOwningProductQuery__
 *
 * To run a query within a React component, call `useGetOwningProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwningProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwningProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwningProductQuery(baseOptions?: Apollo.QueryHookOptions<GetOwningProductQuery, GetOwningProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwningProductQuery, GetOwningProductQueryVariables>(GetOwningProductDocument, options);
      }
export function useGetOwningProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwningProductQuery, GetOwningProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwningProductQuery, GetOwningProductQueryVariables>(GetOwningProductDocument, options);
        }
export type GetOwningProductQueryHookResult = ReturnType<typeof useGetOwningProductQuery>;
export type GetOwningProductLazyQueryHookResult = ReturnType<typeof useGetOwningProductLazyQuery>;
export type GetOwningProductQueryResult = Apollo.QueryResult<GetOwningProductQuery, GetOwningProductQueryVariables>;
export const PaymentsDocument = gql`
    query payments($after: String, $before: String, $first: Int, $last: Int, $orderBy: PaymentOrder!, $queryString: String, $status: [PaymentStatus!], $extendedPaymentStatus: [PaymentStatusExtended!], $startDate: DateTime, $endDate: DateTime, $amountRange: AmountRange, $customerId: String, $id: String) {
  payments(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    queryString: $queryString
    status: $status
    extendedPaymentStatus: $extendedPaymentStatus
    startDate: $startDate
    endDate: $endDate
    amountRange: $amountRange
    customerId: $customerId
    id: $id
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      id
      status
      pendingReason
      pendingReasonCode
      amount
      amountBeforeFees
      amountDisputed
      amountRefunded
      attemptTimestamp
      completedTimestamp
      createdAt
      cancelReason
      currency
      convenienceFee
      creditAmount
      description
      failureReason
      failureDetail {
        code
        details {
          code
        }
      }
      feeAmount
      immediateCapture
      initiatedBy
      authorizationCode
      customerPONumber
      orderNumber
      invoiceNumber
      paymentMethod {
        creditCard {
          cardBrand
          lastFour
          cardHolder {
            email
          }
        }
        paymentBank {
          lastFour
          accountHolder {
            email
          }
        }
      }
      owner {
        tenantId
        paymentRequestId
        customerId
      }
      refunds {
        amount
        attemptTimestamp
        currency
        id
        refundReason
        status
        createTime
        owner {
          tenantId
        }
        payment {
          id
          amount
          amountBeforeFees
        }
      }
      paymentRequest {
        id
        amount
        referenceNumber
        totalDue
        totalPaid
        status
        payments {
          id
          amount
          amountRefunded
          creditAmount
        }
      }
      paymentRequestAllocation {
        amount
        partialPaymentReason
        paymentRequest {
          id
          amount
          referenceNumber
          totalDue
          totalPaid
          status
          payments {
            id
            amount
            amountRefunded
            creditAmount
          }
        }
      }
      customer {
        id
        name
        customerNumber
      }
    }
    edges {
      cursor
      node {
        id
      }
    }
  }
}
    `;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      queryString: // value for 'queryString'
 *      status: // value for 'status'
 *      extendedPaymentStatus: // value for 'extendedPaymentStatus'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      amountRange: // value for 'amountRange'
 *      customerId: // value for 'customerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentsQuery(baseOptions: Apollo.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
      }
export function usePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
        }
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = Apollo.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const PaymentMethodsByTokenDocument = gql`
    query paymentMethodsByToken($id: String!) {
  paymentMethods(id: $id) {
    nodes {
      id
      createdAt
      createdBy
      creditCard {
        cardHolder {
          email
          holderName
          email
          address {
            line1
            line2
            postalCode
            country
          }
        }
        cardBrand
        lastFour
        expirationMonth
        expirationYear
      }
      status
      type
      isLongLived
      updatedAt
      updatedBy
    }
    totalCount
  }
}
    `;

/**
 * __usePaymentMethodsByTokenQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsByTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentMethodsByTokenQuery(baseOptions: Apollo.QueryHookOptions<PaymentMethodsByTokenQuery, PaymentMethodsByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodsByTokenQuery, PaymentMethodsByTokenQueryVariables>(PaymentMethodsByTokenDocument, options);
      }
export function usePaymentMethodsByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsByTokenQuery, PaymentMethodsByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodsByTokenQuery, PaymentMethodsByTokenQueryVariables>(PaymentMethodsByTokenDocument, options);
        }
export type PaymentMethodsByTokenQueryHookResult = ReturnType<typeof usePaymentMethodsByTokenQuery>;
export type PaymentMethodsByTokenLazyQueryHookResult = ReturnType<typeof usePaymentMethodsByTokenLazyQuery>;
export type PaymentMethodsByTokenQueryResult = Apollo.QueryResult<PaymentMethodsByTokenQuery, PaymentMethodsByTokenQueryVariables>;
export const PaymentMethodsDocument = gql`
    query paymentMethods($queryString: String, $customerId: String, $resourceId: String!, $paymentMethodType: PaymentMethodType, $status: [PaymentMethodStatus!]) {
  paymentMethods(
    queryString: $queryString
    customerId: $customerId
    resourceId: $resourceId
    paymentMethodType: $paymentMethodType
    status: $status
  ) {
    nodes {
      id
      createdAt
      createdBy
      creditCard {
        cardHolder {
          holderName
          email
          address {
            line1
            line2
            postalCode
            country
          }
        }
        cardBrand
        lastFour
        expirationMonth
        expirationYear
      }
      status
      type
      isLongLived
      updatedAt
      updatedBy
    }
    totalCount
  }
}
    `;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      customerId: // value for 'customerId'
 *      resourceId: // value for 'resourceId'
 *      paymentMethodType: // value for 'paymentMethodType'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const PaymentRequestsDocument = gql`
    query paymentRequests($after: String, $before: String, $first: Int, $last: Int, $orderBy: PaymentRequestOrder!, $queryString: String, $status: PaymentRequestStatus, $statusFilter: [PaymentRequestStatus!], $customerId: String, $id: String) {
  paymentRequests(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    queryString: $queryString
    status: $status
    statusFilter: $statusFilter
    customerId: $customerId
    id: $id
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      amount
      communications {
        communicationType
        email
        phoneNumber
        requestTimestamp
        sentTimestamp
        status
        subType
      }
      createdAt
      createdBy
      id
      invoiceLink
      invoiceLinks
      invoiceId
      referenceNumber
      status
      statusReason
      customerPONumber
      orderNumber
      invoiceNumber
      discountEndDate
      discountAmount
      dueDate
      totalDue
      totalRefunded
      payments {
        id
        status
        amount
        amountRefunded
        attemptTimestamp
        createdAt
        amountDisputed
        creditAmount
        paymentMethod {
          creditCard {
            cardBrand
            lastFour
          }
          paymentBank {
            lastFour
          }
        }
        creditMemoAllocation {
          referenceNumber
          amount
        }
        refunds {
          id
          status
          amount
        }
      }
      paymentUrl
      owner {
        paymentId
        tenantId
        disputeId
        customerId
      }
      customer {
        id
        name
        customerNumber
      }
    }
    edges {
      cursor
      node {
        id
      }
    }
  }
}
    `;

/**
 * __usePaymentRequestsQuery__
 *
 * To run a query within a React component, call `usePaymentRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRequestsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      queryString: // value for 'queryString'
 *      status: // value for 'status'
 *      statusFilter: // value for 'statusFilter'
 *      customerId: // value for 'customerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentRequestsQuery(baseOptions: Apollo.QueryHookOptions<PaymentRequestsQuery, PaymentRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentRequestsQuery, PaymentRequestsQueryVariables>(PaymentRequestsDocument, options);
      }
export function usePaymentRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentRequestsQuery, PaymentRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentRequestsQuery, PaymentRequestsQueryVariables>(PaymentRequestsDocument, options);
        }
export type PaymentRequestsQueryHookResult = ReturnType<typeof usePaymentRequestsQuery>;
export type PaymentRequestsLazyQueryHookResult = ReturnType<typeof usePaymentRequestsLazyQuery>;
export type PaymentRequestsQueryResult = Apollo.QueryResult<PaymentRequestsQuery, PaymentRequestsQueryVariables>;
export const PayoutReportDocument = gql`
    query payoutReport($payoutReportId: ID!, $format: FileFormat!) {
  payoutReport(payoutReportId: $payoutReportId, format: $format) {
    id
    uri
    status
  }
}
    `;

/**
 * __usePayoutReportQuery__
 *
 * To run a query within a React component, call `usePayoutReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutReportQuery({
 *   variables: {
 *      payoutReportId: // value for 'payoutReportId'
 *      format: // value for 'format'
 *   },
 * });
 */
export function usePayoutReportQuery(baseOptions: Apollo.QueryHookOptions<PayoutReportQuery, PayoutReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayoutReportQuery, PayoutReportQueryVariables>(PayoutReportDocument, options);
      }
export function usePayoutReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayoutReportQuery, PayoutReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayoutReportQuery, PayoutReportQueryVariables>(PayoutReportDocument, options);
        }
export type PayoutReportQueryHookResult = ReturnType<typeof usePayoutReportQuery>;
export type PayoutReportLazyQueryHookResult = ReturnType<typeof usePayoutReportLazyQuery>;
export type PayoutReportQueryResult = Apollo.QueryResult<PayoutReportQuery, PayoutReportQueryVariables>;
export const PayoutSettingsDocument = gql`
    query payoutSettings {
  account {
    settings {
      accountPayouts {
        accountType
        accountLastFour
        currency
        schedule {
          interval
        }
        statementDescription
        status
      }
    }
  }
}
    `;

/**
 * __usePayoutSettingsQuery__
 *
 * To run a query within a React component, call `usePayoutSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayoutSettingsQuery(baseOptions?: Apollo.QueryHookOptions<PayoutSettingsQuery, PayoutSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayoutSettingsQuery, PayoutSettingsQueryVariables>(PayoutSettingsDocument, options);
      }
export function usePayoutSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayoutSettingsQuery, PayoutSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayoutSettingsQuery, PayoutSettingsQueryVariables>(PayoutSettingsDocument, options);
        }
export type PayoutSettingsQueryHookResult = ReturnType<typeof usePayoutSettingsQuery>;
export type PayoutSettingsLazyQueryHookResult = ReturnType<typeof usePayoutSettingsLazyQuery>;
export type PayoutSettingsQueryResult = Apollo.QueryResult<PayoutSettingsQuery, PayoutSettingsQueryVariables>;
export const GetPersonByEmailDocument = gql`
    query getPersonByEmail($email: String!) {
  person(email: $email) {
    id
    firstName
    lastName
    email
    relationship {
      role
      primaryAccountHolder
    }
    owner {
      tenantId
    }
  }
}
    `;

/**
 * __useGetPersonByEmailQuery__
 *
 * To run a query within a React component, call `useGetPersonByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetPersonByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>(GetPersonByEmailDocument, options);
      }
export function useGetPersonByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>(GetPersonByEmailDocument, options);
        }
export type GetPersonByEmailQueryHookResult = ReturnType<typeof useGetPersonByEmailQuery>;
export type GetPersonByEmailLazyQueryHookResult = ReturnType<typeof useGetPersonByEmailLazyQuery>;
export type GetPersonByEmailQueryResult = Apollo.QueryResult<GetPersonByEmailQuery, GetPersonByEmailQueryVariables>;
export const PersonsListDocument = gql`
    query personsList {
  persons {
    firstName
    lastName
    email
    relationship {
      owner
      primaryAccountHolder
      role
      involvement
    }
    verification {
      status
    }
    updatedAt
    id
    createdAt
  }
}
    `;

/**
 * __usePersonsListQuery__
 *
 * To run a query within a React component, call `usePersonsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonsListQuery(baseOptions?: Apollo.QueryHookOptions<PersonsListQuery, PersonsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonsListQuery, PersonsListQueryVariables>(PersonsListDocument, options);
      }
export function usePersonsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonsListQuery, PersonsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonsListQuery, PersonsListQueryVariables>(PersonsListDocument, options);
        }
export type PersonsListQueryHookResult = ReturnType<typeof usePersonsListQuery>;
export type PersonsListLazyQueryHookResult = ReturnType<typeof usePersonsListLazyQuery>;
export type PersonsListQueryResult = Apollo.QueryResult<PersonsListQuery, PersonsListQueryVariables>;
export const ReportDocument = gql`
    query report($id: String!) {
  report(id: $id) {
    id
    downloadUrl
    status
  }
}
    `;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const GetTenantAccountDocument = gql`
    query getTenantAccount {
  account {
    country
    createdAt
    id
    owner {
      tenantId
    }
    updatedAt
    email
    payFacType
    payfac {
      ids {
        resourceId
        resourceType
      }
    }
    capabilities {
      cardPayments
      achPayments
      accountPayouts
    }
    businessProfile {
      description
      logoUrl
      highResLogoUrl
      name
      url
      supportEmail
      mcc
    }
    businessType
    company {
      address {
        city
        country
        line1
        line2
        postalCode
        region
      }
      description
      name
      phone {
        countryCode
        number
        phoneNumber
      }
      structure
      verification {
        status
        kycIssue
        ssnIssue
        additionalDocuments {
          documentId
          rejectReason
          status
          type
        }
        documents {
          documentId
          rejectReason
          status
          type
        }
        transitionStatus
      }
    }
    settings {
      customEmailSettings {
        enabled
        profile {
          from
          host
          port
          username
          password
          securityType
        }
        paymentRequestDefaultText
      }
      features {
        creditMemos {
          enabled
        }
        customers {
          enabled
        }
        payments {
          convenienceFees
          virtualTerminal
        }
        paymentRequests {
          consolidatedPayment
          partialPayment
          paymentReminderEmails
        }
        customEmailSettings {
          enabled
        }
      }
      options {
        payments {
          requirePartialPaymentReason
        }
      }
      cardPayments {
        refundPolicy
        statementDescription
      }
      achPayments {
        refundPolicy
        statementDescription
      }
      accountPayouts {
        statementDescription
        status
        schedule {
          interval
        }
        currency
        accountType
        accountLastFour
      }
      integrationSettings {
        mode
        processUnsentCommunications
        previewSettings {
          enabled
          email
        }
      }
      paymentReminders {
        dueDateReminderDays
        overdueReminderDays
        overdueReminderMessage
        reminderMessage
        sendDueDateReminder
        sendOverdueReminder
      }
      convenienceFees {
        maxCreditConvenienceFee
        maxDebitConvenienceFee
        creditCardRateBps
        debitCardRateBps
      }
      supportedCapabilities {
        accountBalance
        accountStatus
        basicAccountStatus
        businessOwners
        description
        disputes
        entityType
        feeSchedule
        legalEntityName
        payoutReports
        payouts
        refundPolicy
        statementDescription
        statements
        website
      }
    }
    statements {
      createdAt
      startTime
      endTime
      availableFormats {
        format
        fileName
      }
    }
    payoutReports {
      payoutReportId
      startTime
      endTime
      payoutCreateTime
      payoutCompleteTime
    }
    requirements {
      pastDue
      currentlyDue
      pendingVerification
      eventuallyDue
    }
    tosAcceptance {
      date
    }
    defaultCurrency
    balances {
      balance
      incomingPending
      outgoingPending
      reserve
      updatedTimestamp
    }
    feeSchedule
    errors
  }
}
    `;

/**
 * __useGetTenantAccountQuery__
 *
 * To run a query within a React component, call `useGetTenantAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantAccountQuery, GetTenantAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantAccountQuery, GetTenantAccountQueryVariables>(GetTenantAccountDocument, options);
      }
export function useGetTenantAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantAccountQuery, GetTenantAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantAccountQuery, GetTenantAccountQueryVariables>(GetTenantAccountDocument, options);
        }
export type GetTenantAccountQueryHookResult = ReturnType<typeof useGetTenantAccountQuery>;
export type GetTenantAccountLazyQueryHookResult = ReturnType<typeof useGetTenantAccountLazyQuery>;
export type GetTenantAccountQueryResult = Apollo.QueryResult<GetTenantAccountQuery, GetTenantAccountQueryVariables>;
export const TransactionRecordsDocument = gql`
    query transactionRecords($after: String, $before: String, $first: Int, $last: Int, $orderBy: TransactionRecordOrder!, $startDate: DateTime, $endDate: DateTime) {
  transactionRecords(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    startDate: $startDate
    endDate: $endDate
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      __typename
    }
    nodes {
      createdAt
      id
      transactionType
      grossAmount
      feeAmount
      netAmount
      __typename
    }
    edges {
      cursor
      node {
        id
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useTransactionRecordsQuery__
 *
 * To run a query within a React component, call `useTransactionRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionRecordsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTransactionRecordsQuery(baseOptions: Apollo.QueryHookOptions<TransactionRecordsQuery, TransactionRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionRecordsQuery, TransactionRecordsQueryVariables>(TransactionRecordsDocument, options);
      }
export function useTransactionRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionRecordsQuery, TransactionRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionRecordsQuery, TransactionRecordsQueryVariables>(TransactionRecordsDocument, options);
        }
export type TransactionRecordsQueryHookResult = ReturnType<typeof useTransactionRecordsQuery>;
export type TransactionRecordsLazyQueryHookResult = ReturnType<typeof useTransactionRecordsLazyQuery>;
export type TransactionRecordsQueryResult = Apollo.QueryResult<TransactionRecordsQuery, TransactionRecordsQueryVariables>;
export const GetViewerUserDocument = gql`
    query getViewerUser {
  viewer {
    currentUser {
      id
      firstName
      lastName
      email
      relationship {
        role
      }
    }
  }
}
    `;

/**
 * __useGetViewerUserQuery__
 *
 * To run a query within a React component, call `useGetViewerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewerUserQuery(baseOptions?: Apollo.QueryHookOptions<GetViewerUserQuery, GetViewerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViewerUserQuery, GetViewerUserQueryVariables>(GetViewerUserDocument, options);
      }
export function useGetViewerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViewerUserQuery, GetViewerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViewerUserQuery, GetViewerUserQueryVariables>(GetViewerUserDocument, options);
        }
export type GetViewerUserQueryHookResult = ReturnType<typeof useGetViewerUserQuery>;
export type GetViewerUserLazyQueryHookResult = ReturnType<typeof useGetViewerUserLazyQuery>;
export type GetViewerUserQueryResult = Apollo.QueryResult<GetViewerUserQuery, GetViewerUserQueryVariables>;
export const UpdateTenantTransitionStatusDocument = gql`
    mutation updateTenantTransitionStatus($input: UpdateTenantAccountTransitionStatusInput!) {
  updateTenantTransitionStatus(input: $input) {
    code
    message
    error
  }
}
    `;
export type UpdateTenantTransitionStatusMutationFn = Apollo.MutationFunction<UpdateTenantTransitionStatusMutation, UpdateTenantTransitionStatusMutationVariables>;

/**
 * __useUpdateTenantTransitionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTenantTransitionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantTransitionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantTransitionStatusMutation, { data, loading, error }] = useUpdateTenantTransitionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantTransitionStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantTransitionStatusMutation, UpdateTenantTransitionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantTransitionStatusMutation, UpdateTenantTransitionStatusMutationVariables>(UpdateTenantTransitionStatusDocument, options);
      }
export type UpdateTenantTransitionStatusMutationHookResult = ReturnType<typeof useUpdateTenantTransitionStatusMutation>;
export type UpdateTenantTransitionStatusMutationResult = Apollo.MutationResult<UpdateTenantTransitionStatusMutation>;
export type UpdateTenantTransitionStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTenantTransitionStatusMutation, UpdateTenantTransitionStatusMutationVariables>;