/** Display of route in crumbs */
export enum RouteDisplay {
  Settings = 'Settings',
  Payment = 'Payment',
  CustomerManagement = 'Customer Management',
  UserManagement = 'User Management',
  AccountManagement = 'Account Settings',
  AccountPreferences = 'Account Preferences',
  ChallengeDispute = 'Challenge Dispute',
  MerchantReonboard = 'Merchant Re-Onboard',
  Help = 'Help',
  Home = 'Home',
  Payments = 'Payments',
  Requests = 'Payment Requests',
  Disputes = 'Disputes',
  PaymentRequest = 'Payment Request',
  Onboarding = 'Onboarding',
}

/** root text of the path */
export enum RoutePath {
  Settings = '/settings',
  Payment = '/payment',
  CustomerManagement = '/customer-management',
  UserManagement = '/user-management',
  AccountManagement = '/account-management',
  AccountPreferences = '/account-preferences',
  ChallengeDispute = '/challenge-dispute',
  MerchantReonboard = '/merchant-reonboard',
  Help = '/help',
  Home = '/home',
  Payments = '/all-payments',
  Requests = '/payment-requests',
  Disputes = '/disputes',
  PaymentRequest = '/payment-request',
  Onboarding = '/onboarding',
}
