import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { getStandardHeaders } from '../util/StandardRequestHeaders';
import { UpdateDisputeMutationVariables, MutationStatusCode } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPDATE_DISPUTE = gql`
  mutation updateDispute($input: UpdateDisputeInput!) {
    updateDispute(input: $input)
  }
`;

export const mutationChallengeDispute = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  explanation: string,
  documents: string[],
): Promise<MutationStatusCode | undefined> => {
  const headers = await getStandardHeaders();
  const { data, errors } = await client.mutate<{ updateDispute: MutationStatusCode }, UpdateDisputeMutationVariables>({
    variables: { input: { id, explanation, documents } },
    mutation: MUTATION_UPDATE_DISPUTE,
    context: {
      headers,
    },
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      //Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the update dispute mutation');
  }
  if (data && data.updateDispute) {
    return data.updateDispute;
  }

  return undefined;
};
