import React, { useEffect, useState } from 'react';
import { Theme, Link, Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DisputeConnection, Dispute, DisputeStatus, OrderDirection, Maybe, DisputeOrderField } from '../gql-types.generated';
import { disputeStatusDisplay, disputeReasonDisplay } from '../features/challenge-dispute/ChallengeDispute';
import {
  GridRowModel,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridSortDirection,
  DataGridProProps,
  GridRowParams,
  GridSelectionModel,
  GridApiRef,
} from '@mui/x-data-grid-pro';
import { DataList } from './DataList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disputed: {
      '& .MuiDataGrid-cell': {
        color: theme.palette.error.main,
        fontWeight: 500,
      },
    },
    actionButton: {
      backgroundColor: 'white',
    },
  }),
);

// Table for the payment requests
interface DisputeListProps {
  defaultCurrency?: string | null;
  disputeConnection: DisputeConnection;
  disputes: Dispute[] | undefined;
  direction: OrderDirection;
  field: string;
  handleSort: (arrowDirection: OrderDirection, fieldName: string) => void;
  loadPage: (endEdge: string) => void;
  selectedRecord?: Dispute;
  setSelectedRecord: (record: Dispute | undefined) => void;
  handleDisputeViewDetails: (record: Dispute) => void;
  isLoadingDisputes: boolean;
  gridApiRef: GridApiRef;
}

const DisputeList: React.FC<DisputeListProps> = props => {
  const classes = useStyles();
  const {
    defaultCurrency,
    direction,
    disputeConnection,
    disputes,
    handleDisputeViewDetails,
    handleSort,
    loadPage,
    selectedRecord,
    setSelectedRecord,
    isLoadingDisputes,
    gridApiRef,
  } = props;

  const calculateDaysLeft = (createdAt: string) => {
    const dueDate = new Date(createdAt);
    const today = new Date();
    const difference = today.valueOf() - dueDate.valueOf();
    if (difference < 0) {
      return '0 days';
    }
    const days = Math.floor(difference / 86400000);
    if (days > 7) {
      return '0 days';
    }
    const remaining = 7 - days;
    return remaining === 1 ? '1 day' : `${remaining} days`;
  };

  const getDisputeRows = () => {
    return disputes?.map((row: Maybe<Dispute>) => {
      const node = row;
      if (!node) {
        return {} as GridRowModel;
      }
      const alertMerchant = node.status === DisputeStatus.AwaitingMerchantResponse;
      const settledDate = node.resolvedAt ? new Date(node.resolvedAt).toLocaleDateString() : 'N/A';
      const isSelected = node.id === selectedRecord?.id;
      return {
        _raw: node,
        id: node.id,
        isSelected,
        disputeId: node.id?.slice(-9),
        status: node.status,
        statusRender: disputeStatusDisplay.find(item => {
          return item.name === node.status;
        })?.display,
        reasonRender: disputeReasonDisplay.find(item => {
          return item.name === node.reason;
        })?.display,
        /* TODO: Shouldn't there be a currency on the dispute? */
        amount:
          typeof node.amount === 'number'
            ? new Intl.NumberFormat('en', {
                style: 'currency',
                currency: defaultCurrency || 'USD',
                currencyDisplay: 'symbol',
              }).format(node.amount / 100)
            : null,
        dateSettled: alertMerchant ? `${calculateDaysLeft(node.createdAt)} left` : settledDate,
      } as GridRowModel;
    }) as GridRowModel[];
  };

  const getDisputeById = (id: string) => {
    const foundDispute = disputes?.find(dispute => dispute?.id === id);
    // eslint-disable-next-line no-underscore-dangle
    return foundDispute;
  };
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'dateSettled', sort: direction.toLowerCase() as GridSortDirection },
  ]);
  const [disputeRows, setDisputeRows] = useState<GridRowModel[]>(getDisputeRows());
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    setLoadingPage(false);
    setDisputeRows(getDisputeRows());
  }, [disputes]);

  const handlePageLoad = () => {
    if (!disputeConnection) {
      // There is not connection to use for retrieving the next page
      return;
    }
    // No more pages to load, no need to send the load request
    if (!disputeConnection.pageInfo.hasNextPage) {
      return;
    }
    if (!disputeConnection.pageInfo.endCursor) {
      return;
    }
    setLoadingPage(true);
    loadPage(disputeConnection.pageInfo.endCursor);
  };

  // Column titles for mapping and sorting
  const disputeColumns: GridColDef[] = [
    {
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            underline={'hover'}
            href="#"
            onClick={(event: React.MouseEvent) => {
              // stopPropagation to stop click propagation through to the grid - so it does not toggle checkbox.
              event.stopPropagation();
              // eslint-disable-next-line no-underscore-dangle
              handleDisputeViewDetails(params?.row?._raw as Dispute);
            }}
          >
            ***{params.value}
          </Link>
        );
      },
      resizable: false,
      disableReorder: true,
      filterable: true,
      disableColumnMenu: true,
      field: 'disputeId',
      sortable: false,
      headerName: 'Dispute ID',
      flex: 0.22,
      minWidth: 130,
    },
    {
      resizable: false,
      disableReorder: true,
      filterable: true,
      disableColumnMenu: true,
      field: 'statusRender',
      sortable: false,
      headerName: 'Status',
      flex: 0.2,
      minWidth: 160,
    },
    {
      resizable: false,
      disableReorder: true,
      filterable: true,
      disableColumnMenu: true,
      field: 'reasonRender',
      sortable: false,
      headerName: 'Reason',
      flex: 0.14,
      minWidth: 130,
    },
    {
      resizable: false,
      disableReorder: true,
      filterable: true,
      disableColumnMenu: true,
      field: 'dateSettled',
      sortable: true,
      headerName: 'Date Settled',
      flex: 0.14,
      minWidth: 130,
    },
    {
      resizable: false,
      disableReorder: true,
      filterable: true,
      disableColumnMenu: true,
      field: 'amount',
      sortable: false,
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      flex: 0.14,
      minWidth: 130,
    },
    {
      resizable: false,
      disableReorder: true,
      filterable: true,
      disableColumnMenu: true,
      sortable: false,
      field: 'action',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      flex: 0.16,
      minWidth: 80,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        return params?.row?.status === DisputeStatus.AwaitingMerchantResponse ? (
          <Button
            variant="outlined"
            color="primary"
            className={classes.actionButton}
            // eslint-disable-next-line no-underscore-dangle
            onClick={() => handleDisputeViewDetails(params.row._raw as Dispute)}
            data-cy="view-dispute"
          >
            Review
          </Button>
        ) : (
          <></>
        );
      },
    },
  ];
  const onSortModelChange = (model: GridSortModel) => {
    if (model.length === 1 && model[0]?.sort) {
      // Only column allowed to sort is dateSettled which goes by the Timestamp field.
      handleSort(model[0].sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc, DisputeOrderField.Timestamp);
    }
    setSortModel(model);
  };

  const getRowClassName = (params: GridRowParams) => {
    if (params.row && params.row.status === DisputeStatus.AwaitingMerchantResponse) {
      return classes.disputed;
    }
    return '';
  };

  const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
    // Enforce single selection.
    if (selectionModel.length > 2) {
      // eslint-disable-next-line no-param-reassign
      selectionModel.length = 0;
    } else if (selectionModel.length > 1) {
      // Remove previous selection.
      selectionModel.shift();
    }
    if (selectionModel.length === 1) {
      setSelectedRecord(getDisputeById(selectionModel[0] as string));
    } else {
      setSelectedRecord(undefined);
    }
  };

  // eslint-disable-next-line no-underscore-dangle
  const onRowDoubleClick = (params: GridRowParams) => handleDisputeViewDetails(params?.row?._raw as Dispute);

  const gridOptions: DataGridProProps = {
    apiRef: gridApiRef,
    columns: disputeColumns,
    rows: disputeRows,
    loading: loadingPage,
    checkboxSelection: true,
    onSortModelChange,
    'aria-label': 'Disputes Table',
    sortModel,
    sortingOrder: ['desc', 'asc'],
    getRowClassName,
    onSelectionModelChange,
    onRowDoubleClick,
    onRowsScrollEnd: handlePageLoad,
  };

  return (
    <DataList
      gridOptions={gridOptions}
      hasCheckbox
      isSkeletonHidden={!isLoadingDisputes}
      noDataText="No dispute data to show."
      cypressTag="dispute-table-body"
    />
  );
};

export default DisputeList;
