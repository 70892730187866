import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Tooltip,
  Menu,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { DropzoneArea } from 'material-ui-dropzone';
import LoadingMask from './LoadingMask';
import React, { useEffect, useRef, useState } from 'react';
import { createWePayDocument } from '../util/WePay';
import { getAdditionalVerificationFileOptions } from './AdditionalVerificationsFileOptions';
import { AccountRequirements, DocumentTokenInput, DocumentType, DocumentTokenSubject, Person } from '../gql-types.generated';
import { useSelector } from 'react-redux';
import { selectPayFacIds } from '../features/app/AppSlice';
import theme from '../Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .ezpay-dropzone-body': {
        padding: '2.5em',
        margin: theme.spacing(3),
        marginTop: 0,
        width: 'auto',
        backgroundColor: 'white',
      },
    },
    tooltip: {
      fontSize: '10pt',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    contentText: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    dialogActions: {
      margin: 20,
      borderTop: `1px solid ${theme.palette.uxGrey.border}`,
      padding: 10,
    },
    dialogTitle: {
      padding: '0px 10px 10px 0px',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    primaryIcon: {
      marginRight: theme.spacing(1),
    },
    secondaryIcon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    closeIcon: {
      marginLeft: theme.spacing(1),
      fontSize: '30px',
      color: theme.palette.uxGrey.main,
    },
    formControl: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      minWidth: 300,
    },
    grow: {
      flexGrow: 1,
    },
    selectEmpty: {
      marginBottom: theme.spacing(3),
      '&.selectEmptyMissing': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    primarySelectionBox: {
      flexGrow: 1,
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
      padding: '10px',
      borderWidth: 1,
      borderColor: theme.palette.uxGrey.border,
      borderStyle: 'solid',
      display: 'inline-flex',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    secondarySelectionBox: {
      flexGrow: 1,
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
      padding: '10px',
      borderWidth: 1,
      borderColor: theme.palette.uxGrey.border,
      backgroundColor: theme.palette.uxGrey.hover,
      borderStyle: 'solid',
      display: 'inline-flex',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      alignItems: 'center',
    },
    uploadButton: {
      backgroundColor: 'white',
      paddingLeft: 10,
      paddingRight: 10,
      border: `1px solid ${theme.palette.uxGrey.border}`,
    },
    removeFileButton: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.uxGrey.border}`,
      textTransform: 'none',
      padding: theme.spacing(1),
      textAlign: 'left',
      justifyContent: 'space-between',
      color: '#00000099',
    },
    removeFileIcon: {
      marginRight: -theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    fileBox: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
      padding: theme.spacing(0, 1.5),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      border: `1px solid ${theme.palette.uxGrey.border}`,
      borderRadius: theme.spacing(0.5),
    },
    fileActionBox: {
      width: '20%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    grayDropText: {
      color: '#00000099',
      fontSize: '17px',
      marginBottom: '5px',
      marginTop: '5px',
    },
    dropText: {
      marginTop: theme.spacing(1),
    },
    cloudIcon: {
      opacity: 0.5,
      height: '80px',
    },
  }),
);

interface FileSubmission {
  fileName: string;
  token: string;
  persona: string | undefined;
}

interface StateObj {
  screen: string | undefined;
  dialogTitle: string;
  needingDocuments: string[];
  disableSubmit: boolean;
  disableAttach: boolean;
  selectedEntry: string | undefined;
  files: FileSubmission[];
  documentTokens: DocumentTokenInput[];
}

interface Props {
  legalEntityCountry: string | undefined;
  accountRequirements?: AccountRequirements;
  involvedUsers?: Person[];
  openDialog: boolean;
  onClose: () => void;
  onSubmit: (documentTokens: DocumentTokenInput[]) => void;
  entityName: string;
}

const AdditionalVerifications: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [docType, setDocType] = useState('not set');
  const [open, setOpen] = useState(props.openDialog);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showLoading, setShowLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [screenState, setScreenState] = useState<StateObj>({
    screen: '',
    dialogTitle: '',
    needingDocuments: [],
    disableSubmit: true,
    disableAttach: true,
    selectedEntry: undefined,
    files: [],
    documentTokens: [],
  });
  let filesIndex = 0;
  if (open !== props.openDialog) {
    setOpen(props.openDialog);
  }
  const dialog = useRef<HTMLDivElement>(null);
  const selectField = useRef<HTMLDivElement>(null);
  const payFac = useSelector(selectPayFacIds);
  const accountId = payFac?.find(id => {
    return id.resourceType === 'legal_entities';
  })?.resourceId;

  const destructAndSetScreenState = (newStateProperties: Record<string, unknown>) => {
    const oldState = screenState;
    const final = {
      ...oldState,
      ...newStateProperties,
    };
    setScreenState(final);
  };

  const checkAdditionalVerificationsForState = (accountRequirements: AccountRequirements): StateObj => {
    const { currentlyDue, pastDue, pendingVerification } = accountRequirements;
    const needsDocuments = [];
    if (pastDue && pastDue?.length > 0) {
      if (pastDue?.some(item => item.includes('entity') && !pendingVerification?.includes(item))) {
        needsDocuments.push('entity');
      }
      if (pastDue?.some(item => item.includes('controller') && !pendingVerification?.includes(item))) {
        needsDocuments.push('controller');
      }
      if (pastDue?.some(item => item.includes('representative_0') && !pendingVerification?.includes(item))) {
        needsDocuments.push('representative-0');
      }
      if (pastDue?.some(item => item.includes('representative_1') && !pendingVerification?.includes(item))) {
        needsDocuments.push('representative-1');
      }
      if (pastDue?.some(item => item.includes('representative_2') && !pendingVerification?.includes(item))) {
        needsDocuments.push('representative-2');
      }
      if (pastDue?.some(item => item.includes('representative_3') && !pendingVerification?.includes(item))) {
        needsDocuments.push('representative-3');
      }
    }
    if (currentlyDue && currentlyDue?.length > 0) {
      if (
        !needsDocuments.includes('entity') &&
        currentlyDue?.some(item => item.includes('entity') && !pendingVerification?.includes(item))
      ) {
        needsDocuments.push('entity');
      }
      if (
        !needsDocuments.includes('controller') &&
        currentlyDue?.some(item => item.includes('controller') && !pendingVerification?.includes(item))
      ) {
        needsDocuments.push('controller');
      }
      if (
        !needsDocuments.includes('representative-0') &&
        currentlyDue?.some(item => item.includes('representative_0') && !pendingVerification?.includes(item))
      ) {
        needsDocuments.push('representative-0');
      }
      if (
        !needsDocuments.includes('representative-1') &&
        currentlyDue?.some(item => item.includes('representative_1') && !pendingVerification?.includes(item))
      ) {
        needsDocuments.push('representative-1');
      }
      if (
        !needsDocuments.includes('representative-2') &&
        currentlyDue?.some(item => item.includes('representative_2') && !pendingVerification?.includes(item))
      ) {
        needsDocuments.push('representative-2');
      }
      if (
        !needsDocuments.includes('representative-3') &&
        currentlyDue?.some(item => item.includes('representative_3') && !pendingVerification?.includes(item))
      ) {
        needsDocuments.push('representative-3');
      }
    }
    return {
      screen: 'selection',
      dialogTitle: 'Upload additional verifications',
      needingDocuments: needsDocuments,
      disableSubmit: true,
      disableAttach: true,
      selectedEntry: undefined,
      files: screenState.files,
      documentTokens: screenState.documentTokens,
    };
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (event !== null) {
      const target = event.target as HTMLInputElement;
      if (selectField && selectField.current) {
        selectField.current.classList.remove('selectEmptyMissing');
      }
      setDocType(target.value);
    }
  };
  const handleUpload = (event: Event) => {
    setShowLoading(true);
    if (event && event.target) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[input.files.length - 1]) {
        const newFiles = [] as File[];
        for (let inputCount = 0; inputCount < input.files.length; inputCount += 1) {
          const currFile = input.files[inputCount];
          newFiles.push(currFile);
        }
        setFiles(newFiles);
        // enable attach button
        destructAndSetScreenState({ disableAttach: false });
      }
    }
    setShowLoading(false);
  };
  const handleDrop = (files: File[], event: unknown) => {
    setShowLoading(true);
    const e = event as DragEvent;
    if (files && files.length > 0) {
      if (e && e.dataTransfer) {
        if (e.dataTransfer.files.length > 0 && files.length === e.dataTransfer.files.length) {
          const newFiles = [] as File[];
          for (let fileCount = 0; fileCount < files.length; fileCount += 1) {
            const currFile = files[fileCount];
            newFiles.push(currFile);
          }
          setFiles(newFiles);
          // enable attach button
          destructAndSetScreenState({ disableAttach: false });
        }
      }
    }
    setShowLoading(false);
  };
  const handleFileUpload = (files: File[]) => {
    setShowLoading(true);
    if (files && files.length > 0) {
      const newFiles = [] as File[];
      for (let fileCount = 0; fileCount < files.length; fileCount += 1) {
        const currFile = files[fileCount];
        newFiles.push(currFile);
      }
      setFiles(newFiles);
      // enable attach button
      destructAndSetScreenState({ disableAttach: false });
    }
    setShowLoading(false);
  };
  const handleClose = () => {
    props.onClose();
    setScreenState({
      screen: 'selection',
      dialogTitle: 'Upload Additional Verifications',
      needingDocuments: screenState.needingDocuments,
      disableSubmit: true,
      disableAttach: true,
      selectedEntry: undefined,
      files: [],
      documentTokens: [],
    });
  };
  const handleBack = () => {
    setScreenState({
      screen: 'selection',
      dialogTitle: 'Upload Additional Verifications',
      needingDocuments: screenState.needingDocuments,
      disableSubmit: screenState.documentTokens.length === 0,
      disableAttach: true,
      selectedEntry: undefined,
      files: screenState.files,
      documentTokens: screenState.documentTokens,
    });
    setFiles([]);
    setDocType('not set');
  };
  const handleNext = (label: string, name: string) => {
    const title = name === 'Entity' ? `your ${label}` : name;
    setScreenState({
      screen: 'upload',
      dialogTitle: `To verify ${title}, please upload acceptable documention`,
      needingDocuments: screenState.needingDocuments,
      disableSubmit: true,
      disableAttach: true,
      selectedEntry: label,
      files: screenState.files,
      documentTokens: screenState.documentTokens,
    });
    setFiles([]);
  };
  const handleRemoveFile = (label: string, fileName: string) => {
    const attachedFiles = screenState.files;
    const attachedTokens = screenState.documentTokens;
    const persona = label.toLowerCase();
    const index = attachedFiles.findIndex(item => item.persona?.toLowerCase() === persona && item.fileName === fileName);
    if (index !== -1) {
      attachedFiles.splice(index, 1);
      attachedTokens.splice(index, 1);
      destructAndSetScreenState({ files: attachedFiles, documentTokens: attachedTokens });
      setAnchorEl(null);
    }
  };
  const handleRemoveLoadedFile = (index: number, fileName: string) => {
    if (files && files[0]) {
      const filesCopy = [...files];
      if (filesCopy[index].name === fileName) {
        filesCopy.splice(index, 1);
        setFiles(filesCopy);
      }
    }
  };
  const previousScreen = () => {
    setScreenState({
      screen: 'selection',
      dialogTitle: 'Upload Additional Verifications',
      needingDocuments: screenState.needingDocuments,
      disableSubmit: false,
      disableAttach: true,
      selectedEntry: undefined,
      files: screenState.files,
      documentTokens: screenState.documentTokens,
    });
  };
  const openButtonDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };
  const closeButtonDialog = () => {
    setAnchorEl(null);
  };
  const buildRemovalButtons = (addedFiles: FileSubmission[], persona: string) => {
    if (addedFiles.length > 1) {
      return [
        <Button
          id={`modal-control-${persona}`}
          key={`modal-control-${persona}`}
          aria-controls="simple-menu"
          aria-haspopup="true"
          variant="outlined"
          onClick={openButtonDialog}
          className={classes.removeFileButton}
        >{`${addedFiles[0].fileName} and ${addedFiles.length - 1} more`}</Button>,
        <Menu
          open={!!anchorEl && anchorEl.id.includes(persona)}
          onClose={closeButtonDialog}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: 'left', vertical: -55 }}
          keepMounted
          key={`button-modal-${persona}`}
          MenuListProps={{ variant: 'menu' }}
        >
          {addedFiles.map((file, index) => {
            return (
              <MenuItem key={`option-${index}`}>
                <Button
                  variant="outlined"
                  onClick={() => handleRemoveFile(persona, file.fileName)}
                  className={classes.removeFileButton}
                  key={`${file.fileName}-${index}`}
                  fullWidth
                >
                  {file.fileName}
                  <CloseIcon fontSize={'small'} className={classes.removeFileIcon} />
                </Button>
              </MenuItem>
            );
          })}
        </Menu>,
      ];
    }
    return (
      <Button
        variant="outlined"
        onClick={() => handleRemoveFile(persona, addedFiles[0].fileName)}
        color="primary"
        className={classes.removeFileButton}
      >
        {addedFiles[0].fileName}
        <CloseIcon fontSize={'small'} className={classes.removeFileIcon} />
      </Button>
    );
  };
  const buildSelectionButton = (element: string, primary: boolean, counter: number, name: string) => {
    const boxClass = primary ? classes.primarySelectionBox : classes.secondarySelectionBox;
    const iconClass = primary ? classes.primaryIcon : classes.secondaryIcon;
    const borderFix = counter > 188 ? { borderTop: 'none' } : {};
    const personaFiles = screenState.files.filter(item => item.persona === element) as FileSubmission[];
    return (
      <Box key={counter} className={boxClass} style={borderFix}>
        {personaFiles.length > 0 ? (
          <Tooltip
            title={<span className={classes.tooltip}>Attached</span>}
            PopperProps={{
              popperOptions: {
                placement: 'right-end',
              },
            }}
          >
            <CheckCircleIcon className={iconClass} style={{ color: theme.palette.success.dark }} />
          </Tooltip>
        ) : (
          <Tooltip
            title={<span className={classes.tooltip}>Missing</span>}
            PopperProps={{
              popperOptions: {
                placement: 'right-end',
              },
            }}
          >
            <ErrorIcon className={iconClass} style={{ color: theme.palette.error.main }} />
          </Tooltip>
        )}
        {name}
        <div className={classes.grow}></div>
        {personaFiles.length > 0 ? (
          buildRemovalButtons(personaFiles, element)
        ) : (
          <Button variant="outlined" onClick={() => handleNext(element, name)} color="primary" className={classes.uploadButton}>
            Upload
          </Button>
        )}
      </Box>
    );
  };
  const makeSelectionButtons = (needing: string[]) => {
    const ret: JSX.Element[] = [];
    let counter = 188;
    needing.forEach(element => {
      const person = props.involvedUsers?.find(person => person.relationship?.involvement?.includes(element));
      const preName = props.entityName.length > 0 && element === 'entity' ? props.entityName : element;
      const name = person ? `${person.firstName} ${person.lastName}` : preName;
      ret.push(buildSelectionButton(element, true, counter, name));
      counter += 1;
    });
    return ret;
  };
  const bytesConverter = (bytes: number) => {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const decimals = 2;
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
  };
  const getCenterContent = () => {
    // check state of screen
    // if selection,  then show the options
    if (screenState.screen === 'selection') {
      const buttons = makeSelectionButtons(screenState.needingDocuments);
      return [
        <React.Fragment key={46}>
          <DialogContentText className={classes.contentText}>
            To verify account, please provide acceptable supporting documentation
          </DialogContentText>
          {buttons}
        </React.Fragment>,
      ];
    }
    // if upload, then show the docuement upload
    if (screenState.screen === 'upload') {
      const isEntity = screenState.selectedEntry === 'entity';
      const dropList = getAdditionalVerificationFileOptions(props.legalEntityCountry, isEntity);
      if (files.length === 0) {
        return [
          <DialogContentText key={1} className={classes.contentText}>
            Which document are you uploading?
          </DialogContentText>,
          <FormControl variant="outlined" className={classes.formControl} key={2} ref={dialog}>
            <Select value={docType} onChange={handleChange} displayEmpty className={classes.selectEmpty}>
              <MenuItem value={'not set'} disabled>
                Select a document type
              </MenuItem>
              {dropList}
            </Select>
          </FormControl>,
          <span style={{ position: 'relative' }} key={3}>
            <LoadingMask loading={showLoading} />
            <DropzoneArea
              filesLimit={5}
              maxFileSize={10000000}
              acceptedFiles={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
              dropzoneText={'Drop your file here or browse'}
              showPreviewsInDropzone={false}
              showFileNamesInPreview={true}
              dropzoneParagraphClass={'ezpay-dropzone-paragraph'}
              dropzoneClass={'ezpay-dropzone-body'}
              onDrop={handleDrop}
              onChange={handleFileUpload}
            />
          </span>,
        ];
      }
      const firstHalf = [
        <DialogContentText key={1} className={classes.contentText}>
          Which document are you uploading?
        </DialogContentText>,
        <FormControl variant="outlined" className={classes.formControl} key={2}>
          <Select value={docType} onChange={handleChange} displayEmpty className={classes.selectEmpty} ref={selectField}>
            <MenuItem value={'not set'} disabled>
              Select a document type
            </MenuItem>
            {dropList}
          </Select>
        </FormControl>,
      ];
      const secondHalf = (
        <span style={{ position: 'relative' }} key="loading-mask-holder">
          <LoadingMask loading={showLoading} />
          {files.map((file, index) => {
            return (
              <Box key={99 + index} className={classes.fileBox}>
                <Typography>{file.name}</Typography>
                <Box className={classes.fileActionBox}>
                  <Typography>{bytesConverter(file.size)}</Typography>
                  <IconButton
                    aria-label="remove attachment"
                    component="span"
                    onClick={() => {
                      handleRemoveLoadedFile(index, file.name);
                    }}
                    size="large"
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </span>
      );
      return firstHalf.concat(secondHalf);
    }
    return [];
  };
  const checkDocType = () => {
    if (docType && docType !== 'not set') {
      // Some of our values were duplicates, but needed unique value Ids.  Trim off any duplicate identifier numbers
      if (docType.indexOf('evidence_of_corporate_registration') === 0) {
        return 'evidence_of_corporate_registration';
      }
      if (docType.indexOf('other_government_issued_photo_id') === 0) {
        return 'other_government_issued_photo_id';
      }
      if (docType.indexOf('passport') === 0) {
        return 'passport';
      }
      return docType;
    }
    return null;
  };
  const checkSubject = (selectedEntry: string | undefined) => {
    let subject;
    switch (selectedEntry) {
      case 'controller':
        subject = DocumentTokenSubject.ControllerPersonalVerification;
        break;
      case 'representative-0':
        subject = DocumentTokenSubject.Representative_0;
        break;
      case 'representative-1':
        subject = DocumentTokenSubject.Representative_1;
        break;
      case 'representative-2':
        subject = DocumentTokenSubject.Representative_2;
        break;
      case 'representative-3':
        subject = DocumentTokenSubject.Representative_3;
        break;
      default:
        subject = DocumentTokenSubject.EntityVerification;
        break;
    }
    return subject;
  };

  const convertDocType = () => {
    const docType = checkDocType();
    const match = Object.values(DocumentType).find(type => {
      return type === docType?.toUpperCase();
    });
    if (!match) {
      return DocumentType.Unspecified;
    }
    return match;
  };

  const centerContent = getCenterContent();

  const handleSubmit = () => {
    console.log('submit files');
    props.onSubmit(screenState.documentTokens);
  };
  interface WePayTokenResponse {
    id: string;
    resource: string;
    path?: string;
    type: string;
  }
  const wePayTokenDocumentCallback = (response: WePayTokenResponse) => {
    console.log(response.id);
    // Attached file so add it to our state object
    if (response && response.id) {
      const attachedFile = files[filesIndex];
      const myFiles = screenState.files;
      const newTokens = screenState.documentTokens;
      const storeFile = {
        fileName: attachedFile.name,
        token: response.id,
        persona: screenState.selectedEntry,
      };
      const storeToken = {
        subject: checkSubject(screenState.selectedEntry),
        documentType: convertDocType(),
        token: response.id,
      };
      myFiles.push(storeFile);
      newTokens.push(storeToken as DocumentTokenInput);
      filesIndex += 1;
      setShowLoading(false);
      destructAndSetScreenState({ files: myFiles, documentTokens: newTokens });
      previousScreen();
      setFiles([]);
      setDocType('not set');
    }
  };
  const handleAttach = () => {
    //send document and docType to Wepay
    if (files.length >= 1) {
      if (!createWePayDocument) {
        console.log('WePay not initialized.  Can not submit');
        return;
      }
      const typeToSubmit = checkDocType();
      if (typeToSubmit) {
        setShowLoading(true);
        destructAndSetScreenState({ disableAttach: true });
        for (let i = 0; i < files.length; i += 1) {
          console.log(`submitting file: ${files[i]} of Type: ${typeToSubmit} to WePay`);
          const body = {
            type: typeToSubmit,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            legal_entity_id: accountId,
            file: files[i],
          };
          const headers = {};
          createWePayDocument(body, headers, wePayTokenDocumentCallback);
        }
      } else if (selectField && selectField.current) {
        selectField.current.classList.add('selectEmptyMissing');
      }
    } else {
      console.log('Error no file submitted');
    }
  };
  const alterDropZone = (dropZone: Element) => {
    if (dropZone) {
      // We have to hack the display of the third party element
      const inputEl = document.getElementsByTagName('input')[7];
      if (inputEl) {
        inputEl.onchange = handleUpload;
      }
      const displayCont = dropZone.lastElementChild;
      const p = displayCont?.firstElementChild;
      if (displayCont && p) {
        const icon = dropZone.lastElementChild?.lastElementChild;
        if (icon) {
          icon.setAttribute('class', classes.cloudIcon);
        }
        const line2 = document.createElement('p');
        const line3 = document.createElement('p');
        // set to the correct text
        p.innerHTML = `Drop your file here or <span style="color:${theme.palette.primary.main}">browse</span>`;
        line2.textContent = 'Supports JPG, JPEG, PNG, and PDF';
        line3.textContent = 'Max file size: 10MB';
        line2.setAttribute('class', classes.grayDropText);
        line3.setAttribute('class', classes.grayDropText);
        // add the new lines
        p.appendChild(line2);
        p.appendChild(line3);
        p.setAttribute('class', `${p.className} ${classes.dropText}`);
        displayCont.appendChild(p);
      }
    }
  };
  const createDialogAction = () => {
    if (screenState.screen === 'upload') {
      if (screenState.selectedEntry) {
        //Attach
        return [
          <Button key={47} onClick={handleAttach} variant="contained" color="primary" disabled={screenState.disableAttach}>
            Attach
          </Button>,
        ];
      }
    }
    if (screenState.screen === 'selection') {
      // Submit
      return [
        <Button key={48} onClick={handleSubmit} variant="contained" color="primary" disabled={screenState.disableSubmit}>
          Submit
        </Button>,
      ];
    }
    return [];
  };
  const dialogAction = createDialogAction();
  useEffect(() => {
    if (props.accountRequirements) {
      setScreenState(checkAdditionalVerificationsForState(props.accountRequirements));
    }
  }, [props.accountRequirements]);
  React.useEffect(() => {
    if (dialog && dialog.current) {
      // Can't change the order of the dropzone children in typical way
      // Also can't attach a ref directly to the dropzone, so we attached one to it's previous sybling.
      const dropZone = dialog.current.nextElementSibling?.lastElementChild;
      // Make sure we have the right element
      if (dropZone && dropZone.className.includes('ezpay-dropzone-body')) {
        // Only need to do something if we haven't altered it.  textContent will not be consistent if we altered it already.
        if (dropZone.textContent === 'Drop your file here or browse') {
          alterDropZone(dropZone);
        }
      }
    }
  });
  return (
    <Dialog
      aria-label={'additional verifications dialog'}
      disableEscapeKeyDown
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      className={classes.dialog}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
        {screenState.dialogTitle}
      </DialogTitle>
      {centerContent}
      <DialogActions className={classes.dialogActions}>
        <Button onClick={screenState.screen === 'upload' ? handleBack : handleClose} color="primary">
          Cancel
        </Button>
        {dialogAction}
      </DialogActions>
    </Dialog>
  );
};

export default AdditionalVerifications;
