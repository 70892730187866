/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
  reonboardingMerchant: boolean;
  merchantReonboardingError?: Error;
  merchantReonboardingSuccess: boolean;
}

const initialState: SliceState = {
  reonboardingMerchant: false,
  merchantReonboardingSuccess: false,
};

export const slice = createSlice({
  name: 'merchantReonboarding',
  initialState,
  reducers: {
    fetchReonboardingMerchant: (state, action: PayloadAction<boolean>) => {
      state.reonboardingMerchant = action.payload;
    },
    fetchMerchantReonboardingError: (state, action: PayloadAction<Error | undefined>) => {
      state.merchantReonboardingError = action.payload;
    },
    fetchMerchantReonboardingSuccess: (state, action: PayloadAction<boolean>) => {
      state.merchantReonboardingSuccess = action.payload;
    },
  },
});

export const selectReonboardingMerchant = (state: RootState): boolean => state.merchantReonboarding.reonboardingMerchant;
export const selectMerchantReonboardingError = (state: RootState): Error | undefined =>
  state.merchantReonboarding.merchantReonboardingError;
export const selectMerchantReonboardingSuccess = (state: RootState): boolean => state.merchantReonboarding.merchantReonboardingSuccess;

export const { fetchReonboardingMerchant, fetchMerchantReonboardingError, fetchMerchantReonboardingSuccess } = slice.actions;

export default slice.reducer;
