// appToken = 'stage_MzA5XzhkNDRlNzFmLTQ5NWEtNGJlZS05YjhiLTExMzliZTY3NDZjYw',
// url = 'https://stage-api.wepay.com/';

import { WePayTokenResponse } from '../custom-types/WePayTokenResponse';

export interface KycIframeLib {
  tokenize: () => Promise<{ id: string }>;
}

export interface WePayErrorDetail {
  target: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  target_type: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reason_code: string;
  message: string;
}

export interface WePayInvalidParamsError {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  error_code: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  error_message: string;
  details: WePayErrorDetail[];
}

export interface WePayLib {
  configure: (environment: string, appId: string, apiVersion: string) => WePayInvalidParamsError | void | undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  createKycIframe: (id: string, options: { country_code: string; ssn4_enabled: boolean }) => WePayInvalidParamsError | KycIframeLib;
  documents: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    create: (body: object, headers: object, callback: Function) => WePayInvalidParamsError | undefined;
  };
  tokens: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    create: (body: object, headers: object, callback: Function) => WePayInvalidParamsError | undefined;
  };
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    WePay?: WePayLib;
  }
}

let configure: (environment: string, appId: string, apiVersion: string) => WePayInvalidParamsError | void | undefined = (
  _environment,
  _appId,
  _apiVersion,
) => {
  console.log('Linter wants implementation so this console.log is here to satisfy it.');
};

let createKycIframe;

let createDocument;

let createToken;

if ((window as Window).WePay) {
  const wepay = (window as Window).WePay as WePayLib;
  configure = wepay.configure;
  createKycIframe = wepay.createKycIframe;
  createDocument = wepay.documents.create;
  createToken = wepay.tokens.create;
}

export const createOnboardingIframe = createKycIframe;
export const configureWePay = configure;
export const createWePayDocument = createDocument;
export const createWePayToken = createToken;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const tokenizeCCInfo = (
  tokenize: () => Promise<WePayTokenResponse>,
  successCallback: (token: string) => void,
  failureCallback: (error: Error) => void,
) => {
  if (tokenize) {
    tokenize()
      .then((response: WePayTokenResponse) => {
        const { id: creditToken } = response;
        if (successCallback && creditToken) {
          successCallback(creditToken);
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error: WePayTokenResponse) {
        // Handle error.
        if (error) {
          failureCallback(error as Error);
        }
      });
  }
};
