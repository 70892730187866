import { Button, Grid, Theme, IconButton, InputAdornment, FormHelperText, TextField, SelectChangeEvent, Select } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, useState, MouseEvent, ChangeEvent, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { PayoutMethodData } from '../features/account-management/AccountManagement';
import { CurrencyType, PayoutInterval } from '../gql-types.generated';
import PayoutFrequency from './PayoutFrequency';
import GridItem from './GridItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payoutMethodAction: {
      margin: theme.spacing(1),
    },
  }),
);
interface PayoutMethodProps {
  data: PayoutMethodData;
  defaultCurrency?: string | null;
  close: () => void;
  submit: (interval?: PayoutInterval) => void;
  isPayoutMethodValid: () => boolean;
  onAccountTypeChange: (event: SelectChangeEvent<string>) => void;
  onAccountNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onInstitutionNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRoutingTransitNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isAddingPayoutMethod: boolean;
}
const PayoutMethod: FC<PayoutMethodProps> = props => {
  const classes = useStyles();
  const {
    close,
    submit,
    data,
    defaultCurrency,
    isPayoutMethodValid,
    onAccountTypeChange,
    onAccountNumberChange,
    onInstitutionNumberChange,
    onRoutingTransitNumberChange,
    isAddingPayoutMethod,
  } = props;
  const frequencyLabel = 'Frequency';
  const [payoutFrequency, setPayoutFrequency] = useState(isAddingPayoutMethod ? PayoutInterval.Monthly : undefined);
  const [showAccount, setShowAccount] = useState(false);
  const [showRouter, setShowRouter] = useState(false);
  const [showInstitution, setShowInstitution] = useState(false);
  const [tokenErrorMessage, setTokenErrorMessage] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (data.error?.message) {
      setErrorMessage(data.error?.message);
    } else {
      setErrorMessage(undefined);
    }

    if (data.tokenError?.message) {
      let message = data.tokenError?.message.trim();
      if (message && message.toLowerCase() === 'invalid parameter(s).') {
        let errorCount = 0;
        message = 'Invalid';
        const allFieldsInvalid = data.invalidAccountNumber && data.invalidInstitutionNumber && data.invalidRoutingTransitNumber;

        if (data.invalidRoutingTransitNumber) {
          message += defaultCurrency === CurrencyType.Cad ? ' Transit Number' : ' Routing Number';
          errorCount += 1;
        }
        if (data.invalidInstitutionNumber) {
          message += errorCount > 0 ? `${allFieldsInvalid ? ', ' : ' and '}` : ' ';
          message += 'Institution Number';
          errorCount += 1;
        }
        if (data.invalidAccountNumber) {
          message += errorCount > 0 ? ' and ' : ' ';
          message += 'Account Number';
        }

        message += '.';
      }
      setTokenErrorMessage(message);
    } else {
      setTokenErrorMessage(undefined);
    }
  }, [data.error?.message, data.tokenError?.message]);

  const handleClickShowAccount = () => {
    setShowAccount(!showAccount);
  };
  const handleClickShowRouter = () => {
    setShowRouter(!showRouter);
  };
  const handleClickShowInstitution = () => {
    setShowInstitution(!showInstitution);
  };
  const handleMouseDownInput = (event: MouseEvent) => {
    event.preventDefault();
  };
  const handleSetPayoutFrequency = (interval: PayoutInterval) => {
    setPayoutFrequency(interval);
  };
  const clearErrorMessageOnChange = () => {
    if (errorMessage) setErrorMessage(undefined);
    if (tokenErrorMessage) setTokenErrorMessage(undefined);
  };
  const handleAccountNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrorMessageOnChange();
    onAccountNumberChange(event);
  };
  const handleInstitutionNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrorMessageOnChange();
    onInstitutionNumberChange(event);
  };
  const handleRoutingTransitNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrorMessageOnChange();
    onRoutingTransitNumberChange(event);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Select
          variant="outlined"
          native
          fullWidth
          onChange={onAccountTypeChange}
          label={'Acccount Type'}
          value={data.accountType}
          inputProps={{ 'aria-label': 'account type' }}
          data-cy="account-type"
        >
          <option key={'checking'} value={'checking'}>
            Checking
          </option>
          <option key={'savings'} value={'savings'}>
            Savings
          </option>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          value={data.routingTransitNumber}
          label={defaultCurrency === CurrencyType.Cad ? 'Transit Number' : 'Routing Number'}
          id="payout-routing"
          onChange={handleRoutingTransitNumberChange}
          type={showRouter ? 'text' : 'password'}
          error={data.invalidRoutingTransitNumber}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle routing number visibility"
                  onClick={handleClickShowRouter}
                  onMouseDown={handleMouseDownInput}
                  size="large"
                >
                  {showRouter ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          data-cy="routing-number"
        />
      </Grid>
      {defaultCurrency === CurrencyType.Cad && (
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={data.institutionNumber}
            label={'Institution Number'}
            id="payout-institution"
            onChange={handleInstitutionNumberChange}
            type={showInstitution ? 'text' : 'password'}
            error={data.invalidInstitutionNumber}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle institution number visibility"
                    onClick={handleClickShowInstitution}
                    onMouseDown={handleMouseDownInput}
                    size="large"
                  >
                    {showInstitution ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            data-cy="institution-number"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          value={data.accountNumber}
          label={'Account Number'}
          id="payout-account"
          type={showAccount ? 'text' : 'password'}
          error={data.invalidAccountNumber}
          onChange={handleAccountNumberChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle account number visibility"
                  onClick={handleClickShowAccount}
                  onMouseDown={handleMouseDownInput}
                  size="large"
                >
                  {showAccount ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          data-cy="account-number"
        />
      </Grid>
      {isAddingPayoutMethod && (
        <Grid item xs={12}>
          <GridItem
            name={frequencyLabel}
            value={<PayoutFrequency handleChangeFrequency={handleSetPayoutFrequency} interval={payoutFrequency} />}
          />
        </Grid>
      )}
      <Grid container justifyContent="flex-end">
        <Button className={classes.payoutMethodAction} color="primary" onClick={close} data-cy="cancel">
          CANCEL
        </Button>
        <Button
          className={classes.payoutMethodAction}
          variant="contained"
          color="primary"
          onClick={() => submit(payoutFrequency)}
          disabled={!isPayoutMethodValid()}
          aria-describedby={`${tokenErrorMessage ? 'error-text-one' : undefined} ${errorMessage ? 'error-text-two' : undefined}`}
          data-cy="save"
        >
          SAVE
        </Button>
      </Grid>
      {tokenErrorMessage && (
        <FormHelperText error id="error-text-one">
          {tokenErrorMessage}
        </FormHelperText>
      )}
      {errorMessage && (
        <FormHelperText error id="error-text-two">
          {errorMessage}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default PayoutMethod;
