import { Button, Grid, Theme, FormHelperText, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { FC, useState } from 'react';
import { PayoutInterval } from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payoutMethodAction: {
      margin: theme.spacing(1),
    },
  }),
);
interface PayoutFrequencyProps {
  interval?: PayoutInterval;
  close?: () => void;
  submit?: (internval: PayoutInterval) => void;
  submitDaily?: () => void;
  handleChangeFrequency?: (internval: PayoutInterval) => void;
  frequencyError?: Error;
}
const PayoutFrequency: FC<PayoutFrequencyProps> = props => {
  const classes = useStyles();
  const { close, frequencyError, handleChangeFrequency, interval, submit, submitDaily } = props;
  const [newInterval, setNewInterval] = useState<PayoutInterval | undefined>(interval);
  const canSaveFrequency = () => {
    return interval !== newInterval || !interval || interval === PayoutInterval.Daily;
  };
  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewInterval(event.target.value as PayoutInterval);
    if (handleChangeFrequency) {
      handleChangeFrequency(event.target.value as PayoutInterval);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup aria-label="frequency" name="frequency1" value={newInterval || null} onChange={handleIntervalChange}>
          <FormControlLabel value={PayoutInterval.Daily} control={<Radio color={'primary'} />} label="Daily" />
          <FormControlLabel value={PayoutInterval.Weekly} control={<Radio color={'primary'} />} label="Weekly" />
          <FormControlLabel value={PayoutInterval.Monthly} control={<Radio color={'primary'} />} label="Monthly" />
        </RadioGroup>
      </Grid>
      <Grid container justifyContent="flex-end">
        {!!close && (
          <Button className={classes.payoutMethodAction} color="primary" onClick={close}>
            CANCEL
          </Button>
        )}
        {!!submit && (
          <Button
            className={classes.payoutMethodAction}
            variant="contained"
            color="primary"
            onClick={() => {
              if (newInterval) {
                if (newInterval === PayoutInterval.Daily && !!submitDaily) {
                  // Show confirmation modal
                  submitDaily();
                } else {
                  submit(newInterval);
                }
              }
            }}
            data-cy="frequency-save-button"
            disabled={!canSaveFrequency()}
          >
            SAVE
          </Button>
        )}
      </Grid>
      {frequencyError?.message && <FormHelperText error>{frequencyError.message}</FormHelperText>}
    </Grid>
  );
};

export default PayoutFrequency;
